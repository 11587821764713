import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import Footer from "../../core/layout/footer/footer";
import {redirectTo} from "../../utils/helper";
import "../../assets/css/contentApp.css";
import "../../assets/css/appTheme.css"

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function Segmentation(props) {
    const {isMobileView, isLGView, isSMView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isSMView || isXSView) {
            return (
                <div>
                    <section id={'next-level-segmentation-mobile'}>
                        <div className='padding-section-vertical'>
                            <div className='d-flex flex-column justify-content-center block-container'>
                                <div className='text-center'>
                                    <h2 className="section-title padding-section-header-bottom">
                                        Next-level segmentation
                                    </h2>
                                </div>
                                <h4 className="theme-font-content theme-text-justify theme-font-normal padding-section-header-bottom">
                                    Divide your market into relevant groups of consumers who share similar needs,
                                    characteristics, or behaviours to develop tailored marketing and outreach campaigns
                                    that effectively meet each segment's demands and expectations.
                                </h4>
                            </div>
                            <div className='text-end'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/segment/customerSegment.jpg'}
                                     width={'500px'}
                                     height={'300px'}
                                     className="img-fluid"
                                     alt={'An illustration showing customer segmentation'}/>
                            </div>
                        </div>
                    </section>

                    <section className='block-container' id={'key-data-task-mobile'}>
                        <div className='theme-border'/>
                        <div style={{paddingTop: 90}}>
                            <div className='text-center px-2'>
                                <h2 className="theme-font-section-title padding-section-header-bottom">
                                    Centralized and simplified access to key data and tasks
                                </h2>
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='d-flex padding-section-header-bottom'>
                                    <h4 className="theme-font-content theme-text-justify theme-font-normal mb-0">
                                        SCuiDO's segmentation workbench provides you with a quick view of your total
                                        audience, and as you begin to add filters to your data, it updates in realtime
                                        to offer you an overview of your target audience.
                                    </h4>
                                </div>
                                <div style={{flex: 1}}>
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/segment/allCustomer_Mobile.png'}
                                         style={{height: '100%', width: '100%'}}
                                         className="img-fluid"
                                         alt={'An image showing the total number of audience targeted in segmentation'}/>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingTop: 35, paddingBottom: 35}}>
                            <div className='theme-border'/>
                        </div>
                        <h4 className="theme-font-content theme-text-justify mb-4 theme-font-normal">
                            The workbench makes it easy for you to navigate and interact with your dataset. It
                            streamlines your segmentation via a data directory that organizes your linked content
                            files (customer, master, geography, product, etc.) and lists the specific data fields
                            (filter parameters) under each file.
                        </h4>
                        <img loading={'lazy'}
                             src={s3BaseURL + '/images/segment/segmentFilterOption_Mobile.png'}
                             style={{height: '100%', width: '100%'}}
                             className="img-fluid"
                             alt={'An image showing directory that organizes your linked content files and fields in it.'}/>
                        <div style={{paddingTop: 35, paddingBottom: 35}}>
                            <div className='theme-border'/>
                        </div>
                        <div style={{paddingBottom: 90}}>
                            <h4 className="theme-font-content theme-text-justify mb-4 theme-font-normal">
                                With a single click, you can drill down to get a detailed view of each data field
                                and its related metrics.
                            </h4>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/filteredSegment_Mobile.png'}
                                 style={{height: '100%', width: '100%'}}
                                 className="img-fluid"
                                 alt={'An image showing drilldown by fields and selected audienece to target campaigns.'}/>
                        </div>
                    </section>

                    <section className='padding-section-vertical block-container theme-bg-secondary'
                             id={'analyse-data-mobile'}>
                        <div className='padding-section-header-bottom'>
                            <h2 className="theme-font-section-title text-center padding-section-header-bottom">
                                Fast and easy exploration and analysis of your data
                            </h2>
                            <h4 className="theme-font-content theme-text-justify theme-font-normal">
                                SCuiDO simplifies and interprets complex data in clear, concise, easy-to-
                                understand graphs, tables, and scale formats.
                            </h4>
                            <div style={{paddingTop: 35, paddingBottom: 35}}>
                                <div className='theme-border'/>
                            </div>
                            <h4 className="theme-text-justify theme-font-content theme-font-normal">
                                With SCuiDO's user-friendly advanced view feature, you can delve deeper into
                                your data to access additional metrics for numeric fields such as total,
                                maximum, minimum, and average values.
                            </h4>
                            <div style={{paddingTop: 35, paddingBottom: 35}}>
                                <div className='theme-border'/>
                            </div>
                            <h4 className="theme-font-content theme-text-justify theme-font-normal">
                                While viewing a chart, you can add filters within a graph or table to quickly
                                create segments based on your selected parameters.
                            </h4>
                        </div>
                        <div className="mt-4">
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/dataAnalysis_Mobile.png'}
                                 style={{maxHeight: '984px', width: '100%'}}
                                 className="img-fluid"
                                 alt={'An image showing customers targeted by region and brands purchased in those region.'}/>
                        </div>
                    </section>

                    <section className='padding-section-vertical block-container' id={'hyper-target-precision-mobile'}>
                        <div className='d-flex flex-column justify-content-center padding-section-header-bottom'>
                            <h2 className="theme-font-section-title text-center padding-section-header-bottom">
                                Hyper-target with precision
                            </h2>
                            <h4 className="theme-font-content theme-text-justify theme-font-normal">
                                With powerful segmentation and data visualization capabilities, you can analyze a
                                variety of demographic, psychographic, behavioral, or geographic data points to
                                identify commonalities, correlations, or patterns within your larger audience group.
                                These data points could include age, gender, income, location, interests, purchase
                                history, or other relevant factors.
                            </h4>
                        </div>
                        <div className='pt-2 text-center'>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/hyper-target.jpg'}
                                 width={'400px'}
                                 height={'400px'}
                                 className="img-fluid"
                                 alt={'A pie chart image displaying targeted audience.'}/>
                        </div>
                    </section>

                    <section id={'home-services-secondary-footer-mobile'}>
                        <div className='block-container padding-section-vertical secondary-banner-bg'>
                            <div className='text-center px-2'>
                                <h4 className='section-title theme-text-white theme-font-bold pb-60'>
                                    Want to take Your Customer Outreach to the Next Level?
                                </h4>
                            </div>
                            <div className='d-flex justify-content-center '>
                                <button onClick={() => redirectTo('/app/user/register')}
                                        className="footer-sign-up-button">
                                        Sign Up
                                </button>
                                <div style={{marginLeft: 20}}>
                                    <button className="footer-free-demo-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                            Get A Free Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }
        return (
            <div>
                <section id={'next-level-segmentation-desktop'}
                         style={{paddingBottom: 150}} >
                    <div className='d-flex align-items-center'>
                        <div className='block-container'
                             style={{flex: 1}}>
                            <h2 className="theme-font-section-title padding-section-header-bottom"
                                style={{marginBottom: 0}}>
                                Next-level segmentation
                            </h2>
                            <h4 className='theme-font-content-lg theme-font-normal'>
                                Divide your market into relevant groups of consumers who share similar needs,
                                characteristics, or behaviours to develop tailored marketing and outreach campaigns
                                that effectively meet each segment's demands and expectations.
                            </h4>
                        </div>
                        <div style={{flex: 1}}>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/customerSegment.jpg'}
                                 style={{maxHeight: 543, width: '100%'}}
                                 className="img-fluid"
                                 alt={'An illustration showing customer segmentation'}/>
                        </div>
                    </div>
                </section>
                <section id={'key-data-task-desktop'}
                         className='block-container padding-section-bottom'>
                    <div className='padding-section-header-bottom'>
                        <div className='text-center'>
                            <h2 className="theme-font-section-title padding-section-header-bottom">
                                Centralized and simplified access to key data and tasks
                            </h2>
                        </div>
                        <div className='d-flex flex-md-row flex-column'>
                            <div style={{flex: 1}}>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/segment/allcustomer.jpg'}
                                     width={'444px'}
                                     height={'213px'}
                                     className="img-fluid"
                                     alt={'An image showing the total number of audience targeted in segmentation'}/>
                            </div>
                            <div className='d-flex align-items-center'
                                 style={{flex: 2, marginTop: 28}}>
                                <div style={{paddingLeft: isMobileView ? 0 : 75, paddingRight: 40}}>
                                    <h4 className='theme-font-content-lg theme-font-normal'>
                                        SCuiDO's segmentation workbench provides you with a quick view of your total
                                        audience, and as you begin to add filters to your data, it updates in realtime
                                        to offer you an overview of your target audience.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex padding-section-header-bottom'>
                        <div style={{flex: (isLGView || isMobileView) ? 1 : 5, paddingTop: 50}}
                             className='d-flex flex-column'>
                                <h4 className="theme-font-content-lg theme-font-normal">
                                    The workbench makes it easy for you to navigate and interact with your dataset. It
                                    streamlines your segmentation via a data directory that organizes your linked
                                    content files (customer, master, geography, product, etc.) and lists the specific
                                    data fields (filter parameters) under each file.
                                </h4>
                        </div>
                        <div className='text-end'
                             style={{flex: (isLGView || isMobileView) ? 1 : 5,
                                 paddingLeft: (isLGView || isMobileView) ? 0 : 100,}}>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/segmentFilterOption.jpg'}
                                 width={'500px'}
                                 height={'440px'}
                                 className="img-fluid"
                                 alt={'An image showing directory that organizes your linked content files and fields in it.'}/>
                        </div>
                    </div>
                    <div>
                        <div className='d-flex flex-md-row flex-column'>
                            <div style={{flex: 5.5}}>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/segment/filterSegment.png'}
                                     style={{height: '100%', width: '100%'}}
                                     className="img-fluid"
                                     alt={'An image showing drilldown by fields and selected audienece to target campaigns.'}/>
                            </div>
                            <div className='d-flex'
                                 style={{flex: 4.5, marginTop: 50}}>
                                <div className='ps-2'>
                                    <h4 className="theme-font-content-lg theme-font-normal">
                                        With a single click, you can drill down to get a detailed view of each data
                                        field and its related metrics.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'analyse-data-desktop'}>
                    <div className='block-container theme-bg-secondary'>
                        {(isLGView || isMobileView)
                            ?
                            <div className='padding-section-vertical'>
                                <h2 className="theme-font-section-title mb-5 text-center">
                                    Fast and easy exploration and analysis of your data
                                </h2>
                                <div className='mb-4'>
                                    <h4 className="theme-font-content theme-font-normal">
                                        SCuiDO simplifies and interprets complex data in clear, concise, easy-to-
                                        understand graphs, tables, and scale formats.
                                    </h4>
                                </div>
                                <div style={{border: '1px solid var(--primary)'}}/>
                                <div className='mb-4 mt-4'>
                                    <h4 className="theme-text-justify theme-font-content theme-font-normal">
                                        With SCuiDO's user-friendly advanced view feature, you can delve deeper into
                                        your data to access additional metrics for numeric fields such as total,
                                        maximum, minimum, and average values.
                                    </h4>
                                </div>
                                <div style={{border: '1px solid var(--primary)'}}/>
                                <div className='mt-4' style={{marginBottom: 50}}>
                                    <h4 className="theme-font-content theme-text-justify theme-font-normal ">
                                        While viewing a chart, you can add filters within a graph or table to
                                        quickly create segments based on your selected parameters.
                                    </h4>
                                </div>
                                <div className="mt-4 text-center">
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/segment/dataAnalysis_Mobile.png'}
                                         width={'600'}
                                         height={'600'}
                                         className="img-fluid"
                                         alt={'An image showing customers targeted by region and brands purchased in those region.'}/>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className='col-lg-7 col-md-6 py-5'>
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/segment/dataAnalysis_Desktop.png'}
                                         className="img-fluid"
                                         width={"500px"}
                                         height={'600px'}
                                         alt={'An image showing customers targeted by region and brands purchased in those region.'}/>
                                </div>
                                <div className='col-lg-5 col-md-6 d-flex align-items-center padding-section-vertical'>
                                    <div>
                                        <h2 className="theme-font-section-title text-center padding-section-header-bottom"
                                            style={{marginBottom: 0}}>
                                            Fast and easy exploration and analysis of your data
                                        </h2>
                                        <div className='py-2'>
                                            <h4 className="theme-font-content-lg theme-font-normal"
                                                style={{marginBottom: 0}}>
                                                SCuiDO simplifies and interprets complex data in clear, concise,
                                                easy-to- understand graphs, tables, and scale formats.
                                            </h4>
                                        </div>
                                        <div className='footer-border-light'
                                             style={{marginTop: 35, marginBottom: 35}}/>
                                        <div className='py-2'>
                                            <h4 className="theme-font-content-lg theme-font-normal"
                                                style={{marginBottom: 0}}>
                                                With SCuiDO's user-friendly advanced view feature, you can delve deeper
                                                into your data to access additional metrics for numeric fields such as total,
                                                maximum, minimum, and average values.
                                            </h4>
                                        </div>
                                        <div className='footer-border-light'
                                             style={{marginTop: 35, marginBottom: 35}}/>
                                        <div className='py-2'>
                                            <h4 className="theme-font-content-lg theme-font-normal"
                                                style={{marginBottom: 0}}>
                                                While viewing a chart, you can add filters within a graph or table to quickly
                                                create segments based on your selected parameters.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                <section className='block-container padding-section-vertical' id={'hyper-target-precision-desktop'}>
                    <div className='row d-flex flex-md-row flex-column'>
                        <div className="col-md-6 col-lg-4 col-xl-6 col-12 text-center">
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/segment/hyper-target.jpg'}
                                 width={(isLGView || isMobileView) ? '300px' : '500px'}
                                 height={(isLGView || isMobileView) ? '300px' : '500px'}
                                 className="img-fluid"
                                 alt={'A pie chart image displaying targeted audience.'}/>
                        </div>
                        <div className="col-md-6 col-lg-8 col-xl-6 col-12">
                            <div className="ps-2 ps-md-5 ">
                                <h2 className="padding-section-header-bottom theme-font-section-title">
                                    Hyper-target with precision
                                </h2>
                                <h4 className="theme-font-content-lg theme-font-normal">
                                    With powerful segmentation and data visualization capabilities, you can analyze
                                    a variety of demographic, psychographic, behavioral, or geographic data points
                                    to identify commonalities, correlations, or patterns within your larger audience
                                    group. These data points could include age, gender, income, location, interests,
                                    purchase history, or other relevant factors.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"education-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                          className="footer-sign-up-button">
                            <div style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '16px'}}>
                                Sign Up
                            </div>
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                <div style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '16px'}}>
                                    Get A Free Demo
                                </div>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };

    return (
        <div className="w-100">
            <section id={'segmentation-banner-section'}
                     className='banner-background d-flex align-items-center justify-content-center'>
                <div className="">
                    <div className="text-center">
                        <h1 className="theme-font-page-title theme-text-primary"
                            style={{marginBottom: '48px'}}>
                            Segmentation
                        </h1>
                        <h4 className="theme-font-section-sub-header theme-font-normal">
                            Find your perfect customers, every time
                        </h4>
                    </div>
                </div>
            </section>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}

Segmentation.propTypes = {};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,

});

export default connect(mapStateToProps)(withTranslation('translations')(withRouter(Segmentation)))
