import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import {withTranslation} from "react-i18next";
import "./campaign.css"

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {redirectTo} from "../../utils/helper";

function Management(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderDesignRunUi = () => {
        return (
            <div>
                <div className="d-md-block d-none">
                    <section id={'campaign-email-content-desktop'}>
                        <div className="block-container padding-section-vertical">
                            <div className="row">
                                <div className="col-md-5 col-12 d-flex justify-content-center align-items-center">
                                    <div>
                                        <h2 className="theme-font-semiBold theme-font-section-title theme-text-primary m-0 padding-section-header-bottom">
                                            Design, run and measure campaigns
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-md-7 col-12 text-end">
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/campaign/campaignFeature.png'}
                                         className="img-fluid"
                                         width={"520"}
                                         height={"390"}
                                         alt={'An image displaying different design templates to run campaign.'}/>
                                </div>
                            </div>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>

                <div className="d-md-none d-block">
                    <section id={'campaign-email-content-mobile'}>
                        <div className="block-container padding-section-vertical">
                            <h2 className="theme-text-primary text-center theme-font-section-title padding-section-header-bottom m-0">
                                Design, run and measure campaigns
                            </h2>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/campaign/campaignFeature.png'}
                                 className="img-fluid"
                                 width={"544"}
                                 height={"412"}
                                 alt={'An image displaying different design templates to run campaign.'}/>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    const renderCampaignEmailContentUI = () => {
        return (
            <div>
                <div className="d-md-block d-none">
                    <section id={'campaign-email-content-desktop'}>
                        <div className="block-container padding-section-vertical">
                            <div className="row">
                                <div className="col-md-5 col-12 d-flex justify-content-center align-items-center">
                                    <div>
                                        <h2 className="theme-font-semiBold theme-font-section-title theme-text-primary m-0 padding-section-header-bottom">
                                            Personalize email content
                                        </h2>
                                        <h4 className="theme-font-content-lg theme-font-normal">
                                            Connect with your targeted
                                            audience
                                            and make a positive first impression with
                                            personalized messages.
                                        </h4>
                                    </div>
                                </div>
                                <div className="col-md-7 col-12 text-end">
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/campaign/mailContent.jpg'}
                                         className="img-fluid"
                                         width={"520"}
                                         height={"390"}
                                         alt={'An illustration showing personalization in email campaigns.'}/>
                                </div>
                            </div>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>

                <div className="d-md-none d-block">
                    <section id={'campaign-email-content-mobile'}>
                        <div className="block-container padding-section-vertical">
                            <h2 className="theme-text-primary text-center theme-font-section-title padding-section-header-bottom m-0">
                                Personalize email content
                            </h2>
                            <h4 className="theme-font-content-lg theme-font-normal theme-text-justify padding-section-header-bottom m-0">
                                Connect with your
                                targeted
                                audience
                                and make a positive first impression with
                                personalized messages.
                            </h4>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/campaign/mailContent.jpg'}
                                 className="img-fluid"
                                 width={"544"}
                                 height={"412"}
                                 alt={'An illustration showing personalization in email campaigns.'}/>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    const renderCampaignMsgDelivery = () => {
        return (
            <div>
                <div className="d-lg-block d-none">
                    <section id={'campaign-shedule-msg-desktop '}>
                        <div className="block-container padding-section-vertical">
                            <h2 className="theme-text-primary m-0 padding-section-header-bottom text-center theme-font-section-title">
                                Schedule and automate message delivery
                            </h2>
                            <h4 className="theme-font-normal theme-font-content-lg text-center m-0 padding-section-header-bottom">
                                Schedule your emails and push messages when your audience is most likely to read and
                                engage
                                with them to improve your campaigns' effectiveness.
                            </h4>
                            <div className="d-flex justify-content-center">
                                <img src={s3BaseURL + '/images/campaign/campaignScehduleDesktop.jpg'}
                                     width={"826px"}
                                     height={"419px"}
                                     loading={'lazy'}
                                     className="img-fluid"
                                     alt={'An illustration showcasing scheduling a campaign by entering start date and end date and customizing time at which the campaign will be executed.'}/>
                            </div>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>
                <div className="d-lg-none d-block">
                    <section id={'campaign-shedule-msg-mobile'}>
                        <div className="block-container padding-section-vertical">
                            <h2 className="theme-font-section-title-lg text-center theme-text-primary padding-section-header-bottom m-0">
                                Schedule and automate message delivery
                            </h2>
                            <h4 className="theme-font-normal theme-font-content-lg theme-text-justify  padding-section-header-bottom m-0">
                                Schedule your emails and push Messages when your audience is most likely to read and
                                engage
                                with them to improve your campaigns' effectiveness.
                            </h4>
                            <div className="d-flex justify-content-center">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/campaign/campaignScehduleMobile.png'}
                                     className="img-fluid"
                                     width={"328px"}
                                     height={"714"}
                                     alt={'An illustration showcasing scheduling a campaign by entering start date and end date and customizing time at which the campaign will be executed.'}/>
                            </div>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    const renderCampaignTrack = () => {
        return (
            <div>
                <div className="d-lg-block d-none">
                    <section id={'track-campaign-desktop'}>
                        <div className="block-container padding-section-vertical ">
                            <h2 className="m-0 padding-section-header-bottom theme-font-section-title text-center theme-text-primary">
                                Track campaigns
                            </h2>
                            <h4 className="m-0 padding-section-header-bottom theme-font-normal theme-font-content-lg text-center">
                                Evaluate results to inform your campaign strategy with real-time metrics on total emails
                                and
                                messages (change from only emails to emails and messages) sent, delivered, viewed,
                                clicked,
                                bounced,
                                and the other relevant parameters you need.
                            </h4>
                            <div className="d-flex justify-content-center">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/campaign/campaignStatsDesktop.jpg'}
                                     className="img-fluid"
                                     width={"812px"}
                                     height={"574"}
                                     alt={'An image showing statistics of the campaign and drill down which shows users who recieved the email and the time at which the email was viewed by the user.'}/>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="d-lg-none d-block">
                    <section id={'track-campaign-mobile'}>
                        <div className="block-container padding-section-vertical">
                            <h2 className="theme-font-section-title theme-text-primary text-center padding-section-header-bottom m-0">
                                Track campaigns
                            </h2>
                            <h4 className="theme-font-normal theme-font-content-lg theme-text-justify  padding-section-header-bottom m-0">
                                Evaluate results to inform your campaign strategy with real-time metrics on total emails
                                and
                                messages (change from only emails to emails and messages) sent, delivered, viewed,
                                clicked,
                                bounced,
                                and the other relevant parameters you need.
                            </h4>
                            <div className="d-flex justify-content-center">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/campaign/campaignStatsMobile.png'}
                                     className="img-fluid"
                                     width={"342px"}
                                     height={"890px"}
                                     alt={'An image showing statistics of the campaign and drill down which shows users who recieved the email and the time at which the email was viewed by the user.'}/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    return (
        <div className="w-100">
            <section id={'campaign-banner-section'}>
                <div className="banner-background d-flex align-items-center justify-content-center px-5 ">
                    <div className="text-center ">
                        <div className="d-md-block d-none">
                            <h1 className="theme-font-page-title theme-text-primary"
                                style={{marginBottom: '48px'}}>
                                Campaign Management
                            </h1>
                        </div>
                        <div className="d-md-none d-block">
                            <h1 className="theme-font-page-title theme-text-primary"
                                style={{marginBottom: '48px'}}>
                                Campaign Management
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="d-md-block d-none ">
                    {renderDesignRunUi()}
                </div>

                <div className="d-md-none d-block ">
                    {renderDesignRunUi}
                </div>
            </section>

            {renderCampaignEmailContentUI()}

            {renderCampaignMsgDelivery()}

            {renderCampaignTrack()}

            <div className="d-lg-block d-none ">
                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"education-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                            className="footer-sign-up-button">
                                Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                    Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>

            <div className="d-lg-none d-block">
                <section id={'home-services-secondary-footer-mobile'}>
                    <div className='block-container padding-section-vertical secondary-banner-bg'>
                        <div className='text-center px-2'>
                            <h4 className='section-title theme-text-white theme-font-bold pb-60'>
                                Want to take Your Customer Outreach to the Next Level?
                            </h4>
                        </div>
                        <div className='d-flex justify-content-center '>
                            <button onClick={() => redirectTo('/app/user/register')}
                               className="footer-sign-up-button">
                                <div style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '16px'}}>
                                    Sign Up
                                </div>
                            </button>
                            <div style={{marginLeft: 20}}>
                                <button className="footer-free-demo-button"
                                        onClick={() => redirectTo('/contact-us')}>
                                        Get A Free Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer {...props}/>
        </div>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(withRouter(Management)))
