import React, {useEffect, useState} from 'react';
import BetaRequestView from "./betaRequestView";
import {BetaRequestQuestions, preLoginBetaRequest} from "./betaRequestAction";
import {isEmpty} from "../../utils/validations";
import {APP_CONST} from "../../core/AppConstants";
import {isValidEmail} from "../../utils/helper";

function BetaRequest(props) {
    const [betaRequestState, setBetaRequestState] = useState({
        firstName: '',
        firstNameError: '',
        lastName: '',
        linkedIn: '',
        linkedInError: '',
        website: '',
        websiteError: '',
        email: '',
        emailError: '',
        mobile: '',
        mobileError: '',
        reason: '',
        questions: [],
        usedEmail: false,
        questionAnswers: [],
        scrollToElement: null
    });

    useEffect(() => {
        window.scrollTo(0,0);
        BetaRequestQuestions()
            .then((res) => {
                if (res.success) {
                    if (!isEmpty(res.data)) {
                        let data = res.data?.questionAnswers;
                        let temp = '';
                        for (let i = 0; i < data.length; i++) {
                            for (let j = i + 1; j < data.length; j++) {
                                if (data[i].sequence > data[j].sequence) {
                                    temp = data[i];
                                    data[i] = data[j];
                                    data[j] = temp;
                                }
                            }
                        }
                        setBetaRequestState((prev) => ({
                            ...prev,
                            questions: res.data?.questionAnswers
                        }));
                    }
                }
            })


    }, []);

    useEffect(() => {
        const {firstNameError, emailError, websiteError, mobileError} = betaRequestState;


        if (firstNameError !== '') {
            scrollToErrorElement('firstName');
            return;
        }

        if (emailError !== '') {
            scrollToErrorElement('email');
            return;
        }

        if (websiteError !== '') {
            scrollToErrorElement('website');
            return;
        }

        if (mobileError !== '') {
            scrollToErrorElement('mobile');
            return;
        }

        scrollToErrorElement('RESET')
    }, [betaRequestState.firstNameError, betaRequestState.emailError,
        betaRequestState.websiteError, betaRequestState.mobileError]);

    const initializeErrorStateVariable = () => {
        setBetaRequestState((prevState) => ({
            ...prevState,
            firstNameError: '',
            emailError: '',
            mobileError: '',
            websiteError: ''
        }))
    };

    const scrollToErrorElement = (name) => {

        if (name === 'RESET') {
            setBetaRequestState(prevState => ({
                ...prevState,
                scrollToElement: null
            }));
            return;
        }
        setBetaRequestState(prevState => ({
            ...prevState,
            scrollToElement: name
        }));
    };

    const handleChange = (inputName, value) => {
        setBetaRequestState((prevState) => ({
            ...prevState,
            [inputName]: value,
            [inputName + 'Error']: ''
        }));
    };

    const validateChanges = (field) => {
        const {firstName, lastName, linkedIn, email, mobile, website} = betaRequestState;

        if (field === 'firstName' || field === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(firstName)) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    firstNameError: 'please enter first name.'
                }));
                return false
            }
            if (firstName.length > 30) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    firstNameError: 'Maximum 30 characters are allowed.'
                }));
                return false
            }
        }

        if (field === 'lastName' || field === APP_CONST.FORM_SUBMISSION) {
            if (lastName.length > 30) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    lastNameError: 'Maximum 30 characters are allowed.'
                }));
                return false
            }
        }

        if (field === 'email' || field === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(email)) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    emailError: 'please enter email.'
                }));
                return false
            }

            if (!isValidEmail(email, true)) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    emailError: 'Enter valid email or remove special characters other than @, -, _ or period.'
                }));
                return false;
            }

        }

        if (field === 'mobile' || field === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(mobile)){
                setBetaRequestState((prev) => ({
                    ...prev,
                    mobileError: 'please enter mobile.'
                }));
                return false
            }

            if (mobile.length > 20) {
                setBetaRequestState((prev) => ({
                    ...prev,
                    mobileError: 'Maximum 20 characters are allowed.'
                }));
                return false
            }
        }

        if (field === 'website' || field === APP_CONST.FORM_SUBMISSION) {
            if (!isEmpty(website) && (!website.startsWith('https://'))) {
                setBetaRequestState((prevState) => ({
                    ...prevState,
                    websiteError: "Enter https://"
                }));

                return false;
            }
        }

        if (field === 'linkedIn' || field === APP_CONST.FORM_SUBMISSION) {
            if (!isEmpty(linkedIn) && (!linkedIn.startsWith('https://'))) {
                setBetaRequestState((prevState) => ({
                    ...prevState,
                    linkedInError: "Enter https://"
                }));

                return false;
            }
        }

        return true;
    };

    const getAnswersValue = (answers) => {
        let answerArr = [];
        answers.forEach((item) => {
            answerArr.push(item.value)
        });
        return answerArr
    };

    const getQuestionAnswer = (questionAnswers) => {
        let questionArray = [];
         questionAnswers.forEach((item) => {
             questionArray.push({
                questionId: item.questionId,
                answers: getAnswersValue(item.answers)
            })
        });
        return questionArray
    };

    const handleSubmit = () => {
        const {firstName, lastName, reason, questionAnswers, email, mobile, website, linkedIn} = betaRequestState;
        initializeErrorStateVariable();  // This will trigger the useEffect for scrolling to error element.

        setTimeout(() => {
            if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
                return;
            }

            let personalData = {
                firstName: firstName,
                lastName: lastName,
                email: email.trim().toLowerCase(),
                phoneNumber: mobile,
                website: website,
                linkedInUrl: linkedIn,
                reason: reason,
                questionAnswers: getQuestionAnswer(questionAnswers)
            };

            preLoginBetaRequest(personalData).then(
                res => {
                    if (res.success) {
                        return props.history.push('/beta-request/submit');
                    }

                    if (res.statusCode === 409) {
                        setBetaRequestState((prevState) => ({
                            ...prevState,
                            usedEmail: true
                        }))
                    }
                })
        }, 200)
    };

    const handleCheckBoxClick = (value, questionId) => {
        const {questionAnswers} = betaRequestState;

        if (isEmpty(questionAnswers)) {

            setBetaRequestState((prev) => ({
                ...prev,
                questionAnswers: [{
                    questionId: questionId,
                    answers: [{
                        label: value,
                        value: value,
                        isChecked: true
                    }]
                }]
            }));

        } else {

            let checkQuestionIDExist = questionAnswers.find((item) => {
                return item.questionId === questionId
            });

            if (checkQuestionIDExist) {
                if (questionId === 1) {
                    let index = questionAnswers.findIndex(function (person) {
                        return person.questionId === questionId
                    });

                    let isAnswerExist = false;

                    questionAnswers[index].answers.forEach((item) => {
                        if (item.value === value) {
                            isAnswerExist = true
                        }
                    });

                    if (isAnswerExist) {
                        let answerIndex = questionAnswers[index].answers.findIndex(function (answer) {
                            return answer.value === value
                        });

                        questionAnswers[index].answers.splice(answerIndex,1);

                    }else {
                        questionAnswers[index].answers.push({
                            label: value,
                            value: value,
                            isChecked: true
                        });
                    }

                    setBetaRequestState((prev) => ({
                        ...prev,
                        questionAnswers: questionAnswers
                    }));

                } else {
                    let isAnswerExist = false;
                    let index = questionAnswers.findIndex(function (person) {
                        return person.questionId === questionId
                    });

                    questionAnswers[index].answers.forEach((item) => {
                        if (item.value === value) {
                            isAnswerExist = true
                        }
                    });

                    if (isAnswerExist) {
                        questionAnswers.splice(index, 1);

                        setBetaRequestState((prev) => ({
                            ...prev,
                            questionAnswers: questionAnswers
                        }));
                    } else {

                        questionAnswers[index].answers[0] = {
                            label: value,
                            value: value,
                            isChecked: true
                        };

                        setBetaRequestState((prev) => ({
                            ...prev,
                            questionAnswers: questionAnswers
                        }));
                    }

                }
            } else {

                setBetaRequestState((prev) => ({
                    ...prev,
                    questionAnswers: [...questionAnswers, {
                        questionId: questionId,
                        answers: [{
                            label: value,
                            value: value,
                            isChecked: true
                        }]
                    }]
                }));

            }
        }
    };

    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    return (
        <BetaRequestView
            {...props}
            {...betaRequestState}
            validateChanges={validateChanges}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCheckBoxClick={handleCheckBoxClick}
            onBlurInputText={onBlurInputText}
        />
    );
}


export default BetaRequest;
