import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {isEmpty} from "../../utils/validations";

const NewsLetter = (props) => {
    const {handleChange, homePageState, onBlurInputText, onSubmit, isMobileView} = props;

    return (
        isMobileView ?
            <div className={'d-flex flex-grow-1 flex-column'}>
                <div className="theme-font-semiBold theme-font-content-lg"
                     style={{paddingTop: isMobileView ? '24px' : ''}}>
                    Subscribe To Our Newsletter
                </div>
                <div className="pt-3 newsletter-input">
                    <input onChange={(value) => {
                        handleChange('emailId', value.target.value)
                    }}
                           type="text"
                           style={{
                               border: '1px solid',
                               borderRadius: '8px',
                               padding: '8px 14px',
                               width: '100%',
                               borderColor: 'grey',
                               backgroundColor: '#F5F5F5',
                               fontWeight: 'semi-bold'
                           }}
                           placeholder={"Enter your email address"}
                           value={homePageState.emailId}
                           onBlur={() => onBlurInputText('emailId')}
                    />
                    <span className='d-flex justify-content-between theme-text-error'
                          style={{paddingLeft: '5px', minHeight: '19px'}}>
                    <span>{homePageState.emailIdError}</span>
                </span>
                </div>
                {/*Button*/}
                    <div className="d-grid w-100 pb-4">
                        <button onClick={() => onSubmit()}
                                style={{
                                    border: '1px solid rgb(11, 64, 125)',
                                    color: 'var(--white-text)',
                                    backgroundColor: "var(--primary)",
                                    lineHeight: "24px",
                                    height: '44px',
                                    borderRadius: "10px",
                                    fontSize: '16px',
                                    font: 'normal normal medium 18px/22px Barlow',
                                    fontWeight: 500
                                }}>
                            Subscribe Now
                        </button>
                    </div>

            </div> :
            <div>
                <div className="d-flex align-items-center "
                     style={{border: '1px solid var(--gray)', borderRadius: '7px'}}>
                    <div className="d-flex align-items-center" style={{height: 44, borderRight: '1px solid var(--gray)'}}>
                        <span id="inputGroup-sizing-default"
                              className="px-2 theme-font-semiBold theme-font-content">Newsletter</span>
                    </div>
                    <div className="w-100 px-2 newsletter-input">
                        <input onChange={(value) => {
                            handleChange('emailId', value.target.value)
                        }}
                               type="text"
                               style={{
                                   border: 'none',
                                   padding: '8px 0',
                                   width: '100%',
                                   height: '44px',
                                   fontWeight: 'semi-bold'
                               }}
                               placeholder={"Enter your email address"}
                               value={homePageState.emailId}
                               onBlur={() => onBlurInputText('emailId')}
                        />

                    </div>
                    <div className="">
                        <div className="d-flex justify-content-end">
                            <button onClick={() => onSubmit()}
                                    style={{
                                        border: 'none',
                                        borderTopRightRadius: 6,
                                        borderBottomRightRadius: 6,
                                        color: 'var(--white-text)',
                                        backgroundColor: "var(--primary)",
                                        lineHeight: "24px",
                                        width: '168px',
                                        height: '44px',
                                        fontSize: '16px',
                                        font: 'normal normal medium 18px/22px Barlow',
                                        fontWeight: 500
                                    }}>
                                Subscribe Now
                            </button>
                        </div>
                    </div>
                </div>
                <div hidden={!isEmpty(homePageState.emailId)}
                     className='d-flex justify-content-between theme-text-error'
                     style={{paddingLeft: '5px', minHeight: !isEmpty(homePageState.emailId) ? '0px' : 0}}>
                    <span>{homePageState.emailIdError}</span>
                </div>
            </div>

    );
};

NewsLetter.propTypes = {};

const mapStateToProps = state => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(NewsLetter));
