import React, {useEffect} from 'react';
import "../useCases.css"
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from "react-router-dom";
import Footer from "../../core/layout/footer/footer";
import {redirectTo} from "../../utils/helper";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";


function ECommerce(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'ecommerce-banner-mobile'} className="section-padding-bottom ">
                        <div className={isProdEnvironment ? "d-flex ecommerce-banner-bg-prod banner-bg-height":"d-flex ecommerce-banner-bg banner-bg-height"}>
                            <div className="d-flex align-items-center block-container py-5">
                                <div>
                                    <h1 className='page-title theme-font-semiBold '>
                                        Customer outreach for
                                    <br/>
                                    <span className="theme-font-bolder px-1"
                                                         style={{color: 'var(--tertiary)'}}> DTC Products / E-Commerce</span>
                                    </h1>
                                    <h3 className="py-4 section-header theme-font-normal"
                                        style={{width: (isXSView ? "" : "60%")}}>
                                        Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales Teams
                                    </h3>
                                    <button className="sign-up-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                        Contact Us
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-launch-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Successfully Launch New Products
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                        Pick your ideal product launch audience.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Hyper-Targeting
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Utilize demographic, location-based and activity-based filters for pinpoint
                                        accuracy
                                        in identifying and reaching your potential customer.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Efficient Publicity
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Promote your new products to your chosen audience with laser-focused
                                        precision.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        See your new product soar in sales!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/successfullyLaunch.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-boost-sales-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center w-100" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Boost Repeat Sales
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        Profiting from existing customers outweighs acquiring new ones.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Past Buyers
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Concentrate marketing efforts on past buyers of specific or similar products.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Precision Targeting
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Use purchase amount, date, and other filters to identify past customers
                                        effectively.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Repeat Purchase Nudge
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Encourage previous buyers to make another purchase with tailored
                                        marketing.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Elevate your sales using strategies employed by industry leaders!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/boostRepeat.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-cross-sell-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className=' section-title theme-font-bold'>
                                        Cross-Sell Mastery
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                        Use targeted nudge marketing to drive cross-sell opportunities.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Customer Segmentation
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Identify customers who purchased a specific product.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Companion Product Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Identify the most relevant companion products for these customers.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Tailored Offers
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Reach out with personalized offers for these companion products.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Brand Perception
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Shape a seller identity that resonates with buyers through thoughtful
                                        recommendations.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Elevate your cross-selling reputation!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/crossSell.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-up-sell-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Up-Sell Boost
                                    </h2>
                                    <h4 className="sub-section-title pt-2 pb-40 theme-font-normal">
                                        Motivate customers who have already purchased by offering tailored incentives
                                        for premium
                                        products.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Precise Targeting
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Utilize a range of filters, including product category, sale date, and price
                                        range, to
                                        precisely target eligible buyers.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Irresistible Upgrade Offers
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Provide exchange coupons and discounts to encourage customers to
                                        upgrade to a higher-end product.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Amplify high-end sales with up-sell brilliance!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/upSell.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-cart-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Cart Abandonment Recovery
                                    </h2>
                                    <h4 className="sub-section-title pt-2 pb-40 theme-font-normal">
                                        Regain lost revenue by re-engaging customers who abandoned items in their
                                        shopping carts.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Abandonment Analysis
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Identify recurring trends and patterns in cart abandonment.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Persuasive Reminders
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Utilize reminder emails, notifications, and persuasive messages to entice
                                        customers back to complete their purchases.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Turn abandoned carts into cash!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/cartAbandoned.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-Nature-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Nurture High-Value customers
                                    </h2>
                                    <h4 className="sub-section-title pb-40 pt-2 theme-font-normal">
                                        Optimize your marketing efforts for your most lucrative customer base.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Identify Big Spenders
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Identify high-value customers based on average order value (AOV).
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Segmentation Strategy
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Plan and execute targeted campaigns to engage, upsell, cross-sell, reward,
                                        and retain these valuable customer segments.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Indulge your high-value customers – it pays off!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/nurtureHigh.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-bring-back-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold  pb-40'>
                                        Bring Back Inactive Customers
                                    </h2>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Time-Based Segmentation
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Target inactive customers based on their purchase history, whether it's
                                        been three months, six months, a year, or more.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Win-Back Campaigns
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Craft compelling promotional offers to entice these customers back to your
                                        online store.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Rekindle lost revenue opportunities!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/bringBack.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-amplify-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Amplify What Works
                                    </h2>
                                    <h4 className="sub-section-title pt-2 pb-40 theme-font-normal">
                                        Reward your top performing regions/branches.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Sales Volume Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Utilize region-wise insights to identify high-sales locations.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Targeted Promotions
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Send targeted gift vouchers and exclusive offers to solidify your presence.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Strengthen your hold on your top-performing market!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/amplifyWhat.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'ecommerce-target-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Target Seasonal Shoppers
                                    </h2>
                                    <h4 className="sub-section-title pt-2 pb-40 theme-font-normal">
                                        Some items have seasonal peaks; seize your customers' interest at the perfect
                                        moment.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Seasonal Flavors
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Promote products aligned with specific seasonal trends.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Historical Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Target previous buyers with a history of seasonal purchases, whether it's for
                                        the
                                        four seasons, back-to-school, festivals, holidays, or special occasions.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Strategic Timing
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Leverage the seasonality of demand to boost sales and engagement.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Seize your customers' seasonal shopping focus!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/ecommerce/targetSeasonal.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' padding-section-vertical secondary-banner-bg'
                             id={"ecommerce-secondary-footer-mobile"}>
                        <h4 className='theme-text-white mb-0 text-center theme-font-bold section-title'
                            style={{paddingBottom: 60}}>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                    className="footer-sign-up-button">
                                        Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                    <button onClick={() => redirectTo('/contact-us')}
                                       className="footer-free-demo-button">
                                            Get A Free Demo
                                    </button>
                            </div>
                        </div>
                    </section>

                </>
            )
        }

        return (
            <div>

                <section id={'ecommerce-banner-desktop'} className="section-padding-bottom">
                    <div className={isProdEnvironment ? "d-flex ecommerce-banner-bg-prod":"d-flex ecommerce-banner-bg"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container " style={{width: "75%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer outreach for
                                    <span className="theme-font-bolder px-1"
                                          style={{color: 'var(--tertiary)', whiteSpace:'nowrap'}}>
                                        DTC Products / E-Commerce
                                    </span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales
                                    Teams
                                </h3>
                                <button className="sign-up-button"
                                        onClick={() => redirectTo('/contact-us')}>
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-new-product-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/successfullyLaunch.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Successfully Launch New Products
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    Pick your ideal product launch audience.
                                </h3>
                                <h3 className="section-sub-header ">
                                    Hyper-Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Utilize demographic, location-based and activity-based filters for pinpoint accuracy
                                    in identifying and reaching your potential customer.
                                </h4>
                                <h3 className="section-sub-header">
                                    Efficient Publicity
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Promote your new products to your chosen audience with laser-focused
                                    precision.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    See your new product soar in sales!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-boost-sales-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Boost Repeat Sales
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Profiting from existing customers outweighs acquiring new ones.
                                </h3>
                                <h3 className="section-sub-header">
                                    Past Buyers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Concentrate marketing efforts on past buyers of specific or similar products.
                                </h4>
                                <h3 className="section-sub-header">
                                    Precision Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Use purchase amount, date, and other filters to identify past customers
                                    effectively.
                                </h4>
                                <h3 className="section-sub-header">
                                    Repeat Purchase Nudge
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Encourage previous buyers to make another purchase with tailored
                                    marketing.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Elevate your sales using strategies employed by industry leaders!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/boostRepeat.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-cross-sell-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/crossSell.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className=' section-title '>
                                    Cross-Sell Mastery
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                    Use targeted nudge marketing to drive cross-sell opportunities.
                                </h4>
                                <h3 className="section-sub-header">
                                    Customer Segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Identify customers who purchased a specific product.
                                </h4>
                                <h3 className="section-sub-header">
                                    Companion Product Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Identify the most relevant companion products for these customers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Tailored Offers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Reach out with personalized offers for these companion products.
                                </h4>
                                <h3 className="section-sub-header">
                                    Brand Perception
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Shape a seller identity that resonates with buyers through thoughtful
                                    recommendations.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Elevate your cross-selling reputation!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-up-sell-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    Up-Sell Boost
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Motivate customers who have already purchased by offering tailored incentives for
                                    premium
                                    products.
                                </h4>
                                <h3 className="section-sub-header">
                                    Precise Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Utilize a range of filters, including product category, sale date, and price range,
                                    to
                                    precisely target eligible buyers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Irresistible Upgrade Offers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 paragraph-padding">
                                    Provide exchange coupons and discounts to encourage customers to
                                    upgrade to a higher-end product.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Amplify high-end sales with up-sell brilliance!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/upSell.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-Cart-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/cartAbandoned.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Cart Abandonment Recovery
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Regain lost revenue by re-engaging customers who abandoned items in their shopping
                                    carts.
                                </h4>
                                <h3 className="section-sub-header">
                                    Abandonment Analysis
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Identify recurring trends and patterns in cart abandonment.
                                </h4>
                                <h3 className="section-sub-header">
                                    Persuasive Reminders
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Utilize reminder emails, notifications, and persuasive messages to entice
                                    customers back to complete their purchases.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Turn abandoned carts into cash!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-Nature-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Nurture High-Value Customers
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Optimize your marketing efforts for your most lucrative customer base.
                                </h4>
                                <h3 className="section-sub-header">
                                    Identify Big Spenders
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Identify high-value customers based on average order value (AOV).
                                </h4>
                                <h3 className="section-sub-header">
                                    Segmentation Strategy
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Plan and execute targeted campaigns to engage, upsell, cross-sell, reward,
                                    and retain these valuable customer segments.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Indulge your high-value customers – it pays off!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/nurtureHigh.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-bring-back-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/bringBack.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black paragraph-padding'>
                                    Bring Back Inactive Customers
                                </h2>
                                <h3 className="section-sub-header">
                                    Time-Based Segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Target inactive customers based on their purchase history, whether it's
                                    been three months, six months, a year, or more.
                                </h4>
                                <h3 className="section-sub-header">
                                    Win-Back Campaigns
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Craft compelling promotional offers to entice these customers back to your
                                    online store.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Rekindle lost revenue opportunities!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-Amplify-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Amplify What Works
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Reward your top performing regions/branches.
                                </h4>
                                <h3 className="section-sub-header">
                                    Sales Volume Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Utilize region-wise insights to identify high-sales locations.
                                </h4>
                                <h3 className="section-sub-header">
                                    Targeted Promotions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Send targeted gift vouchers and exclusive offers to solidify your presence.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Strengthen your hold on your top-performing market!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/amplifyWhat.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'ecommerce-target-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/ecommerce/targetSeasonal.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Target Seasonal Shoppers
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Some items have seasonal peaks; seize your customers' interest at the perfect
                                    moment.
                                </h4>
                                <h3 className="section-sub-header">
                                    Seasonal Flavors
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Promote products aligned with specific seasonal trends.
                                </h4>
                                <h3 className="section-sub-header">
                                    Historical Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Target previous buyers with a history of seasonal purchases, whether it's for the
                                    four seasons, back-to-school, festivals, holidays, or special occasions.
                                </h4>
                                <h3 className="section-sub-header">
                                    Strategic Timing
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Leverage the seasonality of demand to boost sales and engagement.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Seize your customers' seasonal shopping focus!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"ecommerce-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                               className="footer-sign-up-button">
                                Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                    Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };
    return (
        <div className="w-100">
            {renderUI()}
            <Footer/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    isXSView: state.appState.deviceInfo.isXSView,

});

export default connect(mapStateToProps, {})(withTranslation('translations')(withRouter(ECommerce)))
