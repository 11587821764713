import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import "../../assets/css/appTheme.css";
import "../../assets/css/contentApp.css";

function Tnc(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="w-100">
            <div className="px-3">
                <div className="row">
                    <div className="offset-md-2 col-md-8 col-12 ">
                        <div className="panel p-3 my-2">
                            <div className="d-flex justify-content-center mb-4 mt-2">
                                <h1 className="theme-font-page-title py-4">Terms of Service</h1>
                            </div>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">Effective as of May 2024.</h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Parent company DeccanLogic Private Limited (“DeccanLogic") is the sole proprietor of
                                    website
                                    <a className="px-1"
                                        target="_blank"
                                       href={"https://scuido.com/"}>
                                        https://www.scuido.com </a>
                                    holding all
                                    rights, title, and interest in the platform.
                                    By accessing or using the website
                                    <a className="px-1"
                                        target="_blank"
                                       href={"https://scuido.com/"}>
                                        https://www.scuido.com </a>
                                    , users acknowledge and
                                    agree
                                    that it is owned and operated by parent company DeccanLogic Private Limited. Any
                                    references to "we," "our," “us” or similar terms in the terms and conditions pertain
                                    to parent company DeccanLogic Private Limited.
                                </h6>
                            </div>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    These terms and conditions (“Agreement”) apply to your use of Scuido’s email, push
                                    and whatsapp messaging and analytics technology (including any data or content
                                    available through such technology) purchased by you, the customer (“Services”). By
                                    purchasing the Services, you agree to the terms of this Agreement. You represent and
                                    warrant to Scuido.com that if you are an individual (i.e., not a corporation) you
                                    are at least 18 years of age. By agreeing to this Agreement on behalf of an entity,
                                    you represent and warrant that you have been duly authorized to bind such entity and
                                    enter into this Agreement on such entity’s behalf. You also represent and warrant
                                    that you are legally permitted to use the Services. This Agreement is void where
                                    prohibited by law, and the right to use the Services is revoked in such
                                    jurisdictions.
                                </h6>
                            </div>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    The Privacy Policy, and the Acceptable Use Policy, along with any additional terms
                                    included in an executed order form, terms that apply to specific features of the
                                    Services, and any other terms referenced in this Agreement. If you do not agree to
                                    all the terms of this Agreement, you do not have any right to use the Services,
                                    Scuido.com may modify this Agreement, or any of the policies referred to in this
                                    Agreement, at any time by posting a notice on the Services, posting a revised
                                    version of this Agreement, or by sending you a notice via email. You will be
                                    responsible for reviewing and becoming familiar with any such modifications. Your
                                    use of the Services following such notification constitutes your acceptance of the
                                    modified terms. If at any time you do not agree to this Agreement, you must
                                    terminate your use of the Services. You will still remain liable for any obligations
                                    incurred or charges accrued on or before the date of termination.
                                </h6>
                            </div>
                            {/*<div>*/}
                            {/*    <h6 className="theme-font-content theme-font-normal">*/}
                            {/*        If you have any questions about our Terms, feel free to contact us at*/}
                            {/*        <a href={"mailto:" + 'support@scuido.com'}>support@scuido.com</a>.*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                            <h4 className="theme-font-content-lg pt-3 pb-2">1. Access to Services</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    Subject to the terms and conditions of this Agreement, Scuido.com will provide the
                                    Services, solely for you and your authorized employee and independent contractor end
                                    users (“Authorized Users”).
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    The Services include the ability to design customer outreach campaigns and send
                                    messaging through various communication channels, and includes the features and
                                    components made available to you by Scuido.com within the Services (including text,
                                    data, software, APIs, graphics, photographs, images, illustrations), excluding any
                                    Third Party Services (as defined in Section 9 below). Scuido.com may change, suspend
                                    or discontinue the Services for any reason, at any time, including the availability
                                    of any feature, integration, or content. Scuido.com may also restrict your access to
                                    parts or all of the Services without notice or liability.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com will use commercially reasonable efforts to ensure that the Services are
                                    available twenty-four hours a day, seven days a week. However, there will be
                                    occasions when the Services will be interrupted for maintenance, upgrades, emergency
                                    repairs, or due to failure of telecommunications links or equipment, or due to
                                    similar reasons. Scuido.com will take reasonable steps to minimize such disruption
                                    where it is within Scuido.com’s reasonable control. Scuido.com will not be liable to
                                    you or any other party for any suspension, modification, discontinuance, or lack of
                                    availability of the Services, your Data (defined below), or other content.
                                </h6>

                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">2. Intellectual Property Rights</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    As between Scuido.com and you, Scuido.com (or its licensors) is the sole and
                                    exclusive owner and will retain all right, title and interest in and to the
                                    Services. You will not copy or store any portion of the Services other than for your
                                    personal, noncommercial use, without prior written permission from Scuido.com, or
                                    from the applicable copyright holder. All trademarks, service marks, and trade names
                                    that appear on the Services are proprietary to Scuido.com or third parties. You will
                                    abide by all copyright notices and restrictions contained in the Services. Subject
                                    to the terms and conditions of this Agreement, Scuido.com grants you and your
                                    Authorized Users a nonexclusive, nontransferable, revocable right (without right to
                                    sublicense) to access and use the Services, solely for your and your Authorized
                                    Users’ internal business purposes. Except as provided in this Section, you may not
                                    modify, publish, transmit, participate in the transfer or sale of, reproduce, create
                                    derivative works based on, distribute, perform, display, or in any way exploit, any
                                    of the Services in whole or in part. Additionally, you will not attempt to
                                    circumvent any of Scuido.com’s technical measures, or decompile, reverse engineer,
                                    or disassemble the Services.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">3. Copyright</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    In accordance with the Indian Copyright Act and other applicable law, Scuido.com has
                                    a policy of terminating, in appropriate circumstances and in its sole discretion,
                                    users who are deemed to be repeat infringers. Scuido.com may also terminate the
                                    accounts of any users who infringe any intellectual property rights of others.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    If you think anyone has posted material to the Services that violates your copyright
                                    or other intellectual property right, then you can notify Scuido.com at
                                    <a href={"mailto:"}
                                       className='ps-1'>info@deccanlogic.com</a> or via mail at  Deccanlogic Pvt
                                    Ltd, Office # 05, 4ᵗʰ Floor,
                                    City Vista - B, Fountain Road, Kharadi, Pune-411014, Maharashtra, India. Your
                                    notice
                                    must include all of the information required under Indian Copyright Act. Upon
                                    receipt of such complaint, we may take whatever action, in our sole discretion, we
                                    deem appropriate, in accordance with applicable law.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    If we receive a notice that substantially complies with Indian Copyright Act notice
                                    requirements, we must expeditiously remove or disable access to the content claimed
                                    to be infringing. We may give notice of a claim of copyright infringement by means
                                    of a general notice in the Services, electronic mail, or by written communication.
                                    If we remove your content and you believe that we have removed or disabled this
                                    content by mistake or misidentification, you may notify us. Your notification must
                                    include all of the information required by Indian Copyright Act.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Please see for notification requirements under the Indian Copyright Act. Scuido.com
                                    may give notice of a claim of copyright infringement to users by means of a general
                                    notice on the Services, electronic mail or by written communication.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    4. Customer Data
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    As between you and Scuido.com, your data includes, and you own all right, title and
                                    interest in and to (a) the information or materials that you input into or upload to
                                    the Services for purposes of your messaging campaigns, including text, sound, video,
                                    or image files, and (b) the information about the recipients of your message
                                    campaigns that you provide to Scuido.com, including email address, name, telephone
                                    numbers, or other identifiers or information that you provide (“Data”). You grant
                                    Scuido.com a non-exclusive, worldwide, royalty-free, transferable license to use,
                                    modify, reproduce, and display such Data (including all related intellectual
                                    property rights) to provide, support, administer, and improve the Services. You
                                    represent and warrant that you: (i) possess sufficient rights in and to the Data as
                                    may be necessary to permit the use contemplated under this Agreement; and (ii) you
                                    will not contribute any Data that: (1) infringes any intellectual property right,
                                    proprietary right, or the privacy or publicity rights of another; (2) is libelous,
                                    defamatory, obscene, pornographic, harassing, hateful, offensive or otherwise
                                    violates any law or right of any third party; (3) contains a virus, trojan horse,
                                    worm, or other computer programming routine or engine intended to detrimentally
                                    interfere with the Services or any system, data or information; or (4) causes damage
                                    to the systems of Scuido.com or its users in any way.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    You bear all responsibility and liability for the accuracy, completeness, quality,
                                    integrity, legality, reliability, appropriateness, and right in the Data and
                                    Scuido.com’s access, possession and use of the Data as permitted herein. Scuido.com
                                    does not assume liability for any Data.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    To the extent you provide us with personally identifiable information or information
                                    that could be used to identify an individual (“Personal Data”) or use the Services
                                    to collect Personal Data, you represent and warrant that: (i) you comply with all
                                    applicable laws relating to the collection, use, and disclosure of Personal Data;
                                    (ii) you maintain and abide by a privacy policy, which clearly and conspicuously
                                    discloses that (a) you use third-party providers to provide services such as the
                                    Services; and (b) you may disclose such Personal Data to providers like Scuido.com;
                                    (iii) you have made all required notifications and obtained all required consents
                                    and authorizations relating to the collection, use, and disclosure of Personal Data
                                    with respect to the Services; and (iv) you are responsible for assessing whether or
                                    not the Services are appropriate for your use with respect to your obligations under
                                    any applicable laws or regulations. If you are subject to European data protection
                                    laws, United States data protection laws, or similar data protection laws, you
                                    further represent and warrant that you have executed Scuido.com’s Data Processing
                                    Addendum.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com reserves the right to remove any Data from the Services at any time,
                                    without notice, if Scuido.com reasonably determines that the Data violates this
                                    Agreement or poses a threat to the Services. In such event, Scuido.com will make
                                    commercially reasonable efforts to alert you of such actions as soon as practicable.
                                    Scuido.com may, but is not obligated to, monitor Data and may remove any such Data
                                    or prohibit any use of the Services, including by refusing to send messages to your
                                    recipients, if Scuido.com believes in its sole discretion, Data or your use thereof
                                    may be (or is alleged to be) in violation of this Agreement or any applicable laws,
                                    or may otherwise impact delivery of the Service.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    5. Site Submissions; Feedback
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Any feedback relating to the Services (“Feedback”) that you provide Scuido.com, you
                                    give Scuido.com, without charge, royalties, or other obligation to you, the right to
                                    make, have made, create derivative works, use, share, and commercialize your
                                    Feedback in any way and for any purpose. Scuido.com will not be required to treat
                                    any Feedback as confidential, and will not be liable for any ideas or incur any
                                    liability as a result of any similarities that may appear in future Services.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    6. Confidentiality
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    All business, technical, financial, and other information obtained from the
                                    disclosing party that the disclosing party identifies as confidential at the time of
                                    disclosure or should be reasonably known by the receiving party to be confidential
                                    or proprietary due to the nature of the information disclosed and the circumstances
                                    surrounding the disclosure constitutes the confidential property of the disclosing
                                    party (“Confidential Information”). For clarity, to the extent Data is non-public
                                    information or proprietary information, it will be deemed Confidential Information
                                    without any marking or further designation.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Except as expressly authorized herein, the receiving party will: (i) hold in
                                    confidence and not disclose any Confidential Information to third parties; and (ii)
                                    not use Confidential Information for any purpose other than fulfilling its
                                    obligations and exercising its rights under this Agreement. The receiving party may
                                    disclose Confidential Information to its employees, agents, contractors, suppliers,
                                    affiliates, and other representatives with a legitimate need to know, provided that
                                    such representatives are bound to confidentiality obligations no less protective of
                                    the disclosing party than those in this Section 6. The receiving party’s
                                    confidentiality obligations will not apply to information that the receiving party
                                    can document: (a) was rightfully in its possession or known to it prior to receipt
                                    of the Confidential Information; (b) was or has become public knowledge through no
                                    fault of the receiving party; (c) was rightfully obtained by the receiving party
                                    from a third party without breach of any confidentiality obligation; or (d) was
                                    independently developed by employees of the receiving party who had no access to
                                    such information or do not rely on such information. The receiving party may make
                                    disclosures to the extent required by law or court order, provided the receiving
                                    party notifies the disclosing party in advance (if reasonably practicable) and
                                    reasonably cooperates in any effort to obtain confidential treatment. The receiving
                                    party acknowledges that disclosure of Confidential Information would cause
                                    substantial harm for which damages alone would not be a sufficient remedy, and
                                    therefore that upon any such disclosure by the receiving party the disclosing party
                                    will be entitled to seek appropriate equitable relief in addition to whatever other
                                    remedies it may have at law.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Unless otherwise required by applicable law, within 60 days of the termination of
                                    this Agreement for any reason, a receiving party must: (i) cease the use of all
                                    Confidential Information of or relating to the disclosing party (or any affiliate of
                                    the disclosing party); and (ii) delete, and upon request certify that it has
                                    deleted, all documents and other materials in its possession or control containing,
                                    recording, or constituting that Confidential Information.
                                </h6>
                            </div>
                            <h4 className="theme-font-content-lg pt-3 pb-2">7. Commercial Terms</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Fees for the Services are posted on the Services or in a service order (“Service
                                    Order” and the fees therein, the “Fees”). You will pay the Fees for the initial term
                                    upon starting your subscription. Following your first payment, Fees are due, as
                                    follows: (i) for monthly subscriptions, each month on the same (or closest) date as
                                    the day you made your first payment; (ii) for annual subscriptions, each year on the
                                    same (or closest) date as the day you made your first payment; or (iii) as otherwise
                                    mutually agreed. If your usage exceeds your sending limit (as set forth in the
                                    Services or the Service Order), you agree to pay for the tier of usage associated
                                    with your usage for the remainder of your Subscription Term. If your usage exceeds
                                    the limits of your pre-paid plan, we will charge you overages to the card or
                                    payment
                                    method associated with your account. Scuido.com reserves the right to change
                                    Fees at
                                    any time by posting an update to the Services or otherwise providing reasonable
                                    notice to you. You are responsible for payment of all applicable Fees. You agree to
                                    provide valid and accurate credit card information and payment details and authorize
                                    Scuido.com to deduct the Fees against the payment instrument provided. If you are
                                    using a credit card, you represent and warrant you are authorized to use that credit
                                    card, and that any and all charges may be billed to that credit card and won’t be
                                    rejected.  Any amounts not paid when due shall bear interest from the due date at
                                    the
                                    rate of 1.5% per month or the highest rate of interest permitted by law. If you
                                    fail
                                    to pay any Fees when due, Scuido.com may terminate this Agreement in accordance with
                                    Section 13 of this Agreement.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com continually develops new features to improve or support the Services.
                                    From time to time, we may make certain features available to customers for free for
                                    a period of time as part of the Services. Where we introduce a feature for free, we
                                    reserve the right to discontinue that feature at any time, with or without notice to
                                    you. We also reserve the right to charge Fees for such feature upon at least 30
                                    days’ notice to you. If we provide you with notice that we will begin charging a Fee
                                    for a feature that was previously made available for free, we will also provide you
                                    with an opportunity to discontinue your use of that feature prior to incurring any
                                    Fees for the use thereof.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    If you are using the paid portion of the Services, you will pay or reimburse
                                    Scuido.com for all sales, use, excise, and all other taxes and all duties, related
                                    to Scuido.com’s performance under this Agreement excluding, taxes measured by
                                    Scuido.com’s profits, overall sales, employment, and property or other taxes
                                    measured in whole or in part by the value of Scuido.com’s assets.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com will provide you with reasonable customer support and maintenance for the
                                    paid portion of the Services via e-mail. Scuido.com does not guarantee that the
                                    support will be available at a certain time or that Scuido.com will respond within a
                                    certain time or resolve all problems in connection therewith.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    If you register for a free trial, we will make the applicable Services available to
                                    you on a trial basis free of charge until the earlier of: (a) the end of the free
                                    trial period (if not terminated earlier), or (b) the start date of your paid
                                    subscription. Unless you purchase a subscription to the applicable Services before
                                    the end of the free trial, all of your Data in the Services may be permanently
                                    deleted at the end of the trial, and will not be recoverable. If we include
                                    additional terms and conditions for a trial, those will apply and will govern over
                                    these terms in the event of conflict.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    8. Restrictions
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    You are responsible for all of your activity in connection with the Services. You
                                    agree that you will not, and that you are prohibited from: (a) copying, modifying,
                                    reproducing, duplicating, publishing, licensing, or creating derivative works or
                                    improvements of the Services, or any material made available within the Services;
                                    (b) renting, leasing, lending, selling, sublicensing, assigning, distributing,
                                    publishing, transferring, or otherwise making the Services, or any information,
                                    software or materials available within the Services, to any third-party; (c) reverse
                                    engineering, disassembling, decompiling, decoding, adapting, or otherwise attempting
                                    to derive or gain access to any portion of the Services, in whole or in part; (d)
                                    bypassing or breaching any security device or protection used by the Services or
                                    accessing or using the Services other than by an authorized user through the use of
                                    valid access credentials; (e) inputting, uploading, transmitting, or otherwise
                                    providing to or through the Services, any information or materials that are unlawful
                                    or injurious, or contain, transmit, or activate any harmful code; (f) damaging,
                                    destroying, disrupting, disabling, impairing, interfering with, or otherwise
                                    impeding or harming, in any manner, the Services or the provision of the Services,
                                    in whole or in part; (g) removing, deleting, altering, or obscuring from the
                                    Services any of our trademarks; (h) accessing or using the Services in any manner or
                                    for any purpose that infringes, misappropriates, or otherwise violates any
                                    intellectual property right, data privacy right, or other right of any third party
                                    (including by any unauthorized access to, misappropriation, use, alteration,
                                    destruction, or disclosure of data), or that violates any applicable law; (i)
                                    accessing or using the Services for purposes of developing a competitive offering;
                                    (j) sending unsolicited messages, phishing messages, spam, any message that attempts
                                    to obtain or derive information from an individual for an unlawful purpose, or
                                    running maillist, listserv, or any form of auto-responder; (k) impersonating any
                                    third-party; (l) using the Services in any manner that is or could be construed as
                                    obscene, threatening, abusive, defamatory, libelous, or which encourages criminal
                                    conduct; and (m) using the Services for any illegal activity or otherwise in
                                    violation of applicable law (including, as applicable, violation of laws related to
                                    the marketing or promotion of cannabis, alcohol, medical, medicinal, or
                                    pharmaceutical products, firearms or tobacco).
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    You represent, warrant, and covenant that your use of the Services will at all times
                                    comply with Scuido.com’s Acceptable Use Policy, as may be amended by Scuido.com from
                                    time to time, and you will not access or use third party mailing lists in connection
                                    with preparing or distributing e-mail through the Services.
                                </h6>
                            </div>
                            <h4 className="theme-font-content-lg pt-3 pb-2">9. Third Party Services and Integrations</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    The Services use third party messaging platforms, and we allow you to integrate with
                                    third party software, services, applications, or other tools (“Third Party
                                    Service”). The Third Party Services are not offered by Scuido.com, and are not
                                    subcontractors or subprocessors of Scuido.com. Scuido.com does not direct, control,
                                    or operate the Third Party Service and is not responsible for (a) how the Third
                                    Party Service is provided, (b) the accuracy, completeness, integrity, or security of
                                    any Third Party Service or any integration point controlled by a Third Party
                                    Service, or (c) how the Third Party Service transmits, accesses, processes, stores,
                                    uses, shares, or provides data. The Third Party Service may make changes to its
                                    service, or components thereof, or suspend or discontinue its service with or
                                    without notice.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    The Third Party Services may require that you agree to their terms and conditions
                                    prior to the use of the Third Party Service. These terms and conditions may be
                                    provided as a click through for you to accept within the Service or may be provided
                                    directly on the Third Party Service website or within your account with the Third
                                    Party Service. Third Party Service terms may be updated without notice to you. You
                                    are solely responsible for ensuring that you review and comply with all Third-Party
                                    Service terms and conditions.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal">
                                    With respect to the Third Party Services, the service agreement is entered into
                                    directly between you and the applicable Third Party Service. You agree that any
                                    claims you may have regarding the Third Party Service and all liability arising out
                                    of your use of the Third Party Service (if any) is solely between you and the
                                    applicable Third Party Service provider. We expressly disclaim all liability related
                                    to or arising from any Third Party Service, including liability related to or
                                    arising from any errors, inaccurate information, updates, modifications, outages,
                                    delivery failures, corruption of data, loss of data, use of data, security,
                                    discontinuance of services, violation of applicable law, or termination of the Third
                                    Party Service.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Third Party Services used by SCuiDO
                                    <ul className="ms-0">
                                        <li> Amazon AWS Services</li>
                                        <li>Google Cloud APIs</li>
                                        <li> Google Analytics</li>
                                        <li>OpenAI APis</li>
                                        <li>Razorpay Payment Services</li>
                                    </ul>
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">10. Disclaimers</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com has no special relationship with or fiduciary duty to you. You
                                    acknowledge that Scuido.com has no control over, and no duty to take any action
                                    regarding: (a) which end users gain access to the Services; (b) what results you may
                                    obtain via the Services; (c) how you may interpret or use such results; or (d) what
                                    actions you may take as a result of having been exposed to the Services. You hereby
                                    release Scuido.com from all liability arising from or related to your use of the
                                    Services. Scuido.com will not be liable for any failures for any features in the
                                    Services or other problems which arise from or are related to your Data or any
                                    equipment or service outside of Scuido.com’s facilities or control. You will use the
                                    Services only in compliance with all applicable laws (including policies and laws
                                    related to spamming, privacy, intellectual property, consumer and child protection,
                                    obscenity, or defamation.) You’re responsible for determining whether the Services
                                    are suitable for your use in light of any regulations like HIPPA, data protection
                                    laws, or other laws.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    TO THE FULLEST EXTENT ALLOWED BY LAW, SCUIDO.COM WILL NOT BE RESPONSIBLE OR LIABLE
                                    FOR THE ACCURACY, RELIABILITY, AVAILABILITY, COMPLETENESS, COPYRIGHT COMPLIANCE,
                                    LEGALITY, OPERABILITY OR DECENCY OF MATERIAL CONTAINED IN OR ACCESSED THROUGH THE
                                    SERVICES. THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS, WITHOUT WARRANTIES OF ANY
                                    KIND, EITHER EXPRESS OR IMPLIED. SCUIDO.COM DISCLAIMS ALL SUCH WARRANTIES, INCLUDING
                                    ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE
                                    OR NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                                    IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    BY USING THE SERVICES, YOU ACKNOWLEDGE THAT SCUIDO.COM IS NOT RESPONSIBLE OR LIABLE
                                    FOR ANY HARM RESULTING FROM: (i) YOUR SPECIFIC USE OF THE SERVICES (INCLUDING THE
                                    DATA YOU SEND OR THE RECIPIENTS TO WHOM YOU SEND IT TO); (ii) YOUR UNAUTHORIZED
                                    DISCLOSURE OF IMAGES, INFORMATION OR DATA THROUGH THE SERVICES; AND (iii) YOUR
                                    INABILITY TO ACCESS OR RETRIEVE ANY DATA FROM THE SERVICES (iv)THE ACCURANCY,
                                    RELIABILITY, AVAILABILITY, COMPLELENESS, COPYRIGHT COMPLIANCE, LEGALITY, OPERABILITY
                                    OR DECENCY OF ANY DATA YOU SEND VIA THE SERVICES.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    11. Registration and Security
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    As a condition of using the Services, you and your Authorized Users may be required
                                    to supply Scuido.com with certain registration information. You will provide
                                    Scuido.com with accurate, complete, and updated registration information. Neither
                                    you nor your Authorized Users may share your password or login credentials with
                                    anyone. You are responsible for maintaining the confidentiality of such credentials.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Your Authorized Users must create their own respective account, subject to any
                                    applicable seat limits and such users’ compliance with this Agreement. Both you and
                                    the Authorized User are responsible for all activity under such accounts. You will
                                    immediately notify us of any suspected, unauthorized use of your or an Authorized
                                    User’s account or any other breach of security you become aware of. Scuido.com has
                                    the right to refuse registration of and suspend or terminate any Authorized User in
                                    its discretion. Scuido.com may further suspend access to the Services (including by
                                    refusing to send messages) in the event Scuido.com reasonably believes such action
                                    is necessary due to your breach of this Agreement, your violation of any applicable
                                    message limits or caps, breach of applicable law, or to protect the security or
                                    integrity of the Services or any data thereon; Scuido.com will provide advanced
                                    written notice of such suspension, if reasonably practicable.
                                </h6>
                            </div>

                            <h4 className="theme-font-content-lg pt-3 pb-2">12. Indemnification</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    You will indemnify, defend, and hold Scuido.com, its directors, officers, employees,
                                    agents, and representatives harmless, including costs and reasonable attorneys’
                                    fees, from any claim or demand made by any third party due to or arising out of: (i)
                                    your access or use of the Services, (ii) your violation of this Agreement, (iii)
                                    your infringement, or the infringement by any third party using your registration
                                    information, of any intellectual property, or other right of any person or entity,
                                    and (iv) the Data or any other materials provided to Scuido.com
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">13. Limitation of Liability</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, EXCEPT FOR DAMAGES AND LIABILITIES ARISING
                                    FROM: (i) YOUR VIOLATION OF THE ACCEPTABLE USE POLICY; (ii) A PARTY’S
                                    INDEMNIFICATION OBLIGATIONS; OR (iii) GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT, IN
                                    NO EVENT SHALL EITHER PARTY, ITS DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES,
                                    MEMBERS, AGENTS AND REPRESENTATIVES BE LIABLE TO THE OTHER FOR ANY INDIRECT,
                                    INCIDENTAL PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR DAMAGES FOR LOSS OF
                                    USE, PROFITS, DATA, IMAGES, OR OTHER INTANGIBLES, OR IN THE CASE OF SCUIDO.COM,
                                    DAMAGES FOR UNAUTHORIZED USE, NON-PERFORMANCE OF THE SERVICES, ERRORS OR OMISSIONS.
                                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, SCUIDO.COM’S LIABILITY UNDER THIS AGREEMENT
                                    WILL BE LIMITED TO THE GREATER OF: (A) THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING
                                    THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE THE CLAIM AROSE; OR (B)
                                    $100.00 US DOLLARS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                                    INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT
                                    APPLY TO YOU.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">14. Termination</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    This Agreement begins on the date you first use the Services and continues as long
                                    as you access the Services. If you purchase a subscription, your subscription will
                                    automatically renew at the subscription period frequency referenced on the Services
                                    (or if not designated, then monthly), or as otherwise set forth in an applicable
                                    Service Order (“Subscription Term”). Scuido.com reserves the right to increase the
                                    Fees for your subscribed Services upon any renewal. You may terminate your use of
                                    the Services, any Service Order and this Agreement by providing written notice of
                                    termination to Scuido.com; please note that you will remain liable for all Fees for
                                    the remainder of the then-current Subscription Term. Scuido.com may also terminate
                                    or suspend any and all Services immediately, without prior notice or liability, if
                                    you breach any of the terms or conditions of this Agreement. Scuido.com may
                                    immediately terminate this Agreement if (i) you become subject to or file for
                                    bankruptcy or experience another insolvency event; or (ii) you assign this Agreement
                                    in accordance with Section 17. In the event Scuido.com terminates this Agreement due
                                    to your breach, or upon your filing for bankruptcy or other insolvency event, you
                                    will remain liable for all Fees for the remainder of the then-current term. Any Fees
                                    paid hereunder are non-refundable, unless otherwise required by law. Upon any
                                    termination, your right to use the Services and access Data through the Services
                                    will immediately cease. All provisions of this Agreement which by their nature
                                    should survive termination will survive termination, including, without limitation,
                                    ownership provisions, warranty disclaimers, indemnification obligations, and
                                    limitations of liability. In the event this Agreement terminates or expires before
                                    the termination or expiration of a Service Order, this Agreement will govern such
                                    Service Order, until the Service Order terminates or expires.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">15. Privacy</h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Please review Scuido.com’s Privacy Policy, which governs Scuido.com’s use of
                                    Personal Data and Data.
                                </h6>
                            </div>
                            <h4 className="theme-font-content-lg pt-3 pb-2">16. Jurisdiction</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    All matters relating to your access to or use of our online services, including all
                                    disputes, will be governed by the laws of India without regard to its conflict of
                                    law provisions. You agree that any legal action or proceeding between you and our
                                    company for any purpose concerning these Terms of Service or the parties'
                                    obligations hereunder shall be brought exclusively in a court of competent
                                    jurisdiction sitting in Pune, Maharashtra, India. By using our services, you consent
                                    to the jurisdiction and venue of such courts and waive any objections to the
                                    exercise of jurisdiction over you by such courts and to venue in such courts.
                                </h6>
                            </div>
                            <div>
                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    17. Miscellaneous
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    The failure of either party to exercise in any respect any right provided for herein
                                    will not be deemed a waiver of any further rights hereunder. If any provision of
                                    this Agreement is found to be unenforceable or invalid, that provision will be
                                    limited or eliminated to the minimum extent necessary so that this Agreement will
                                    otherwise remain in full force and effect and enforceable.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    This Agreement is not assignable, transferable, or sublicensable by you except with
                                    Scuido.com’s prior written consent. Scuido.com may transfer, assign or delegate this
                                    Agreement and any or all of its rights and obligations without consent.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Scuido.com may publicly disclose in writing that you purchased Services and may use
                                    your trademarks, service marks or trade name solely in connection with such
                                    disclosures.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    This Agreement (including the Privacy Policy and Acceptable Use Policy) is the
                                    complete and exclusive statement of the mutual understanding of the parties and
                                    supersedes and cancels all previous written and oral agreements, communications and
                                    other understandings relating to the subject matter of this Agreement. No agency,
                                    partnership, joint venture, or employment is created as a result of this Agreement
                                    and you do not have any authority to bind Scuido.com in any respect whatsoever.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2 mb-3">
                                    Any notice that is required or permitted by this Agreement to be in writing will be
                                    deemed effective upon receipt, when sent by confirmed e-mail, if to you, at the
                                    email address you provide with your account, and if to Scuido.com, to
                                    <a href={"mailto:"}
                                       className='ps-1'>info@deccanlogic.com</a> or when delivered in person by nationally recognized overnight
                                    courier or mailed by first class, registered or certified mail, postage prepaid, to:
                                    Deccanlogic Pvt Ltd, Office # 05, 4ᵗʰ Floor, City Vista - B, Fountain Road, Kharadi,
                                    Pune-411014, Maharashtra, India. Notwithstanding the foregoing, for any notice that
                                    is required or permitted to be made, Scuido.com may update the applicable URL and
                                    such updates will be effective on the effective date posted at the top of the
                                    applicable URL.
                                </h6>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className={"block-container"}>
                <div className="footer-border-light"/>
            </div>
            <Footer {...props}/>
        </div>);
}

export default Tnc;
