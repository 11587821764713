import React, {useEffect} from 'react';
import "./emailTemplate.css";
import {redirectTo} from "../../utils/helper";
import '../../assets/css/appTheme.css';
import Footer from "../../core/layout/footer/footer";
import {Link} from "react-router-dom";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function EmailTemplate(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderEmailTempCraftCompellingMsg = () => {

        return (
            <div>
                <div className="d-md-block d-none">
                    <div className="block-container padding-section-vertical">
                        <div className="row ">
                            <div className="col-5 d-flex align-items-center">
                                <div>
                                    <h2 className="theme-font-section-title theme-text-primary text-start m-0 padding-section-header-bottom">
                                         Craft compelling messages
                                    </h2>
                                    <h4 className="theme-font-content-lg pe-5">
                                        Use customer insights to create persuasive email that address the specific needs and
                                        inclinations of a segment.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-7 ">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/template/craftMsgTemplate.jpg'}
                                     className="img-fluid"
                                     width={'662px'}
                                     height={'695px'}
                                     alt={'An image showing image template editor with drag and drop options.'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-md-none d-block">
                    <div className="block-container padding-section-vertical">
                        <h2 className="theme-font-section-title text-center m-0 padding-section-header-bottom">
                             Craft compelling messages
                        </h2>
                        <h4 className="theme-font-content-lg theme-font-normal m-0 padding-section-header-bottom"
                            style={{marginTop: "30px"}}>
                            Use customer insights to create persuasive email that address the specific needs and
                            inclinations of a segment.
                        </h4>
                        <img loading={'lazy'}
                             src={s3BaseURL + '/images/template/craftMsgTemplate.jpg'}
                             className="img-fluid px-3"
                             width={'732px'}
                             height={'782px'}
                             alt={'An image showing image template editor with drag and drop options.'}/>
                    </div>
                </div>
            </div>
        );
    };

    const renderEmailContent = () => {
        return (
            <div>

                <div className="d-md-block d-none" style={{paddingBottom: 150}}>
                    <div className="block-container">
                        <div className="row">
                            <div className="col-5 d-flex align-items-center">
                                <div>
                                    <h2 className="theme-font-section-title text-start theme-text-primary ">
                                      Validate email content
                                    </h2>
                                    <h4 className="theme-font-content-lg"
                                        style={{paddingTop: "40px"}}>
                                        Send a test email to review the message, assess the layout, gauge the design
                                        responsiveness, and
                                        verify the functionality of buttons and links before launching your campaign.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-7">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/template/emailContentTemplate.jpg'}
                                     className="img-fluid"
                                     width={'622px'}
                                     height={'599px'}
                                     alt={'An email template is shown on different devices like laptop, tablet and mobile.'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-md-none d-block block-container">
                    <div className='block-container'>
                        <div className='theme-border'/>
                    </div>
                    <div className='padding-section-vertical'>
                        <h2 className="theme-font-section-title m-0 text-center padding-section-header-bottom">
                            Validate email content
                        </h2>
                        <h4 className="theme-font-content-lg theme-font-normal m-0 padding-section-header-bottom">
                            Send a test email to review the message, assess the layout, gauge the design responsiveness, and
                            verify the functionality of buttons and links before launching your campaign.
                        </h4>
                        <img loading={'lazy'}
                             src={s3BaseURL + '/images/template/emailContentTemplate.jpg'}
                             className="img-fluid px-3"
                             width={'622px'}
                             height={'599px'}
                             alt={'An email template is shown on different devices like laptop, tablet and mobile.'}/>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesignTemplate = () => {
        return (
            <div>
                <div className="d-md-block d-none" style={{paddingBottom: 150}}>
                    <div className="row mx-0">
                        <div className="col-7">
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/template/designTemplate.jpg'}
                                 className="img-fluid"
                                 width={'820px'}
                                 height={'522px'}
                                 alt={'An image showing email templates can be sent as test email and on clicking on it user can visit website.'}/>
                        </div>
                        <div className="col-5 d-flex align-items-center">
                            <div className="designTempContentContainer">
                                <h2 className="theme-font-section-title theme-text-primary ">
                                    Design templates that look great across devices
                                </h2>
                                <h4 className="theme-font-content-lg" style={{paddingTop: "40px"}}>
                                    Customize and add branding to a range of ready-to-use templates built by domain experts
                                    or effortlessly create templates from scratch with easy-to- use design tools.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-md-none d-block block-container">
                    <div className='block-container'>
                        <div className='theme-border'/>
                    </div>
                    <div className=' padding-section-vertical'>
                        <h2 className="theme-font-section-title text-center padding-section-header-bottom m-0">
                            Design templates that look great across devices
                        </h2>
                        <h4 className="theme-font-content-lg theme-font-normal padding-section-header-bottom m-0">
                            Customize and add branding to a range of ready-to-use templates built by domain experts or
                            effortlessly create templates from scratch with easy-to- use design tools.
                        </h4>
                        <img loading={'lazy'}
                             src={s3BaseURL + '/images/template/designTemplate.jpg'}
                             className="img-fluid"
                             width={'604px'}
                             height={'363px'}
                             alt={'An image showing email templates can be sent as test email and on clicking on it user can visit website.'}/>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="w-100">
            <section id={'email-template-banner'}>
                <div className="banner-background d-flex align-items-center justify-content-center">
                    <div className="d-md-block d-none">
                        <h1 className="theme-font-page-title theme-text-primary"
                            style={{marginBottom: '48px'}}>
                            Responsive, customizable and brandable emails
                        </h1>
                    </div>
                    <div className="d-md-none d-block">
                        <h1 className="theme-font-page-title theme-text-primary text-center"
                            style={{marginBottom: '48px'}}>
                            Responsive, customizable and brandable emails
                        </h1>
                    </div>
                </div>
            </section>

            <section id={'craft-compelling-msg'}>
                {renderEmailTempCraftCompellingMsg()}
            </section>

            <section id='design-template'>
                {renderDesignTemplate()}
            </section>

            <section id={'email-content'}>
                {renderEmailContent()}
            </section>

            <section id={'email-template-secondary-footer'}>
                <div className="d-lg-block d-none">
                    <section className='block-container padding-section-vertical secondary-banner-bg'
                             id={"education-secondary-footer-desktop"}>
                        <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <button onClick={() => redirectTo('/app/user/register')}
                                    className="footer-sign-up-button">
                                    Sign Up
                            </button>
                            <div style={{marginLeft: 20}}>
                                <button className="footer-free-demo-button"
                                        onClick={() => redirectTo('/contact-us')}>
                                        Get A Free Demo
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="d-lg-none d-block">
                    <section id={'home-services-secondary-footer-mobile'}>
                        <div className='block-container padding-section-vertical secondary-banner-bg'>
                            <div className='text-center px-2'>
                                <h4 className='section-title theme-text-white theme-font-bold pb-60'>
                                    Want to take Your Customer Outreach to the Next Level?
                                </h4>
                            </div>
                            <div className='d-flex justify-content-center '>
                                <button onClick={() => redirectTo('/app/user/register')}
                                    className="footer-sign-up-button">
                                        Sign Up
                                </button>
                                <div style={{marginLeft: 20}}>
                                    <button className="footer-free-demo-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                            Get A Free Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

            <Footer {...props}/>
        </div>
    );
}


export default EmailTemplate
