import {httpGet, httpPost} from "../../utils/httpHelper";

export const getTicketFor = (accountId) => {
    let url = '/service/provisioning/support/ticketFor';

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const raisePreLoginTicket = (data, accountId) => {
    let url = '/service/provisioning/support/preLoginTicketRaised';
    return httpPost(url,data,accountId)
        .then((res) => {
            return res
        })

};