import {httpGet} from "../utils/httpHelper";

export const getServiceDetailsForPreLogin = (code) => {
    let url;
    url = '/service/provisioning/service/preLogin?code='+code;

    return httpGet(url)
        .then((res) => {
            return res;
        })
};