import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import appTheme from "../../assets/appTheme";

function CookiePolicy(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className="w-100">
            <div className="page-container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12 p-3">
                        <div className="panel p-3">
                            <div>
                                <h1 className="theme-font-page-title text-center mt-2 mb-4">
                                    Cookie Policy
                                </h1>
                                <h4 className="theme-font-content py-2">
                                    LAST UPDATED: October 29, 2018
                                </h4>
                                <h4 className="theme-font-content theme-font-normal">
                                    This Cookie Notice explains how and why scuido Pty Ltd (collectively “SCuiDO”, "we",
                                    "us", and "ours") uses cookies and similar technologies to recognise you, as well as
                                    track and analyse your actions when you visit our websites at
                                    www.campaignmonitor.com
                                    ("Websites"). We also provide information related to how and why those same
                                    technologies
                                    are applied to tracking and analysing logged-in users of our software application
                                    (“Services”) and recipients of emails sent through our Services by users of our
                                    Services. Information about what these technologies are and why we use them, as well
                                    as
                                    your rights to control our use of them, can be found below.
                                </h4>
                            </div>
                            <div className="py-2">
                                <h4 className="theme-font-content py-2">
                                    What are cookies?
                                </h4>
                                <h4 className="theme-font-content theme-font-normal">
                                    Cookies are small data files that are placed on your computer or mobile device when
                                    you visit a website. Cookies are widely used by website owners in order to make
                                    their websites work, or to work more efficiently, as well as to provide reporting
                                    information.
                                </h4>
                                <h4 className="theme-font-content theme-font-normal">
                                    We refer to cookies created by us as "first party cookies". We refer to cookies that
                                    we set on our website but that are created by parties other than us as "third party
                                    cookies". Third party cookies enable third party features or functionality to be
                                    provided on or through the website (e.g. like advertising, interactive content and
                                    analytics). The parties that provide these third party cookies can recognise your
                                    computer both when it visits the website in question and also when it visits certain
                                    other websites.
                                </h4>
                            </div>

                            <div className="py-2">
                                <h4 className="theme-font-content py-2">
                                    Why we use cookies
                                </h4>
                                <h4 className="theme-font-content theme-font-normal">
                                    We use both first and third party cookies for several reasons. First, let’s look at
                                    the broad categories of first and third party cookies in use on our sites:
                                </h4>
                                <h4 className="theme-font-content theme-font-normal">
                                    <ul>
                                        <li>
                                            <b className="theme-text-disabled">Essential website cookies: </b>
                                            These cookies are strictly necessary to provide
                                            you with services available through our Websites and to use some of its
                                            features, such as access to secure areas. Refusal of these cookies may
                                            impact website functionality.
                                        </li>
                                        <li>
                                            <b className="theme-text-disabled">Performance and functionality cookies:</b>
                                            These cookies are used to enhance the performance and functionality of our Websites
                                            but are non-essential to their use. However, without these cookies, certain
                                            functionality may become unavailable.
                                        </li>
                                        <li>
                                            <b className="theme-text-disabled">Analytics and customisation cookies:</b>
                                            These cookies collect information that
                                            is used either in aggregate form to help us understand how our Websites are
                                            being used or how effective are marketing campaigns are, or to help us
                                            customise our Websites for you.
                                        </li>
                                        <li>
                                            <b className="theme-text-disabled">Advertising cookies:</b>
                                            These cookies are used to make advertising messages
                                            more relevant to you. They perform functions like preventing the same ad
                                            from continuously re-appearing, ensuring that ads are properly displayed for
                                            advertisers, and in some cases selecting advertisements that are based on
                                            your interests.
                                        </li>
                                        <li>
                                            <b className="theme-text-disabled">Social networking cookies:</b>
                                            These cookies are used to enable you to share
                                            pages and content that you find interesting on our Websites through third
                                            party social networking and other websites. These cookies may also be used
                                            for advertising purposes too.
                                        </li>
                                    </ul>
                                </h4>
                            </div>


                            <h4 className="theme-font-content py-2">
                                FIRST PARTY COOKIES
                            </h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Cookie Name</th>
                                        <th>Cookie Type</th>
                                        <th>Subject</th>
                                        <th>Purpose</th>
                                        <th>Retention Policy</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>CM-Init-Visit</td>
                                        <td>Performance/Functionality</td>
                                        <td>Performance/Functionality</td>
                                        <td>Detects and stores very first visit to the site, allowing first touch
                                            actions to occur on some pages
                                        </td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>CM-PTB-Visit</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Detects and stores first visit to PTB page, recording a view and close
                                            action of the exit intent box
                                        </td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>CM-comm</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>4 sets of data for the Help docs section covering the page they came from
                                            before the first touch to the help docs, the date of the first touch, the
                                            last touch page and the last search term
                                        </td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-currency (string)</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Detecting currency, stored and passed along through the signup form to
                                            provide consistent currency to be displayed throughout the experience with CM
                                        </td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-abbr (string)</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Location data used for various personalisation around the site</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-city (string)</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-country (string) country name</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-set (string) Region name</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-state (string)</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-timezone (string), Timezone</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-zip (string), Postcode/Zip code</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>1 year</td>
                                    </tr>
                                    <tr>
                                        <td>CM-location-check (string), Region name</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Data used to locate a site visitor</td>
                                        <td>7 days</td>
                                    </tr>

                                    <tr>
                                        <td>CM-location-dv5 (encoded string)</td>
                                        <td>Performance/Functionality</td>
                                        <td>scuido.com site visitor</td>
                                        <td>To display pricing based on region</td>
                                        <td>1 year</td>
                                    </tr>


                                    <tr>
                                        <td>_cdeTrkData</td>
                                        <td>Analytics</td>
                                        <td>scuido.com site visitor</td>
                                        <td>This data helps us analyze site traffic to better optimize performance.</td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>CM-location-eu and CM-location-gdpr</td>
                                        <td>Essential</td>
                                        <td>scuido.com site visitor</td>
                                        <td>To determine whether user is in the EU or not and whether user requires GDPR compliance</td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>CM-location-status</td>
                                        <td>Essential</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Whether a location was able to be determined</td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>cookie-accept</td>
                                        <td>Essential</td>
                                        <td>scuido.com site visitor</td>
                                        <td>Whether a location was able to be determinedFlag of whether a user has given
                                            consent or not. Value is set to 'implied' for non-EU users</td>
                                        <td>1 year</td>
                                    </tr>

                                    <tr>
                                        <td>apex__payload</td>
                                        <td>Performance/Functionality</td>
                                        <td>help.campaignmonitor.com or help.createsend.com site visitor</td>
                                        <td>To provide support contact details relevant to a scuido account, and account
                                            details when submitting a support request
                                        </td>
                                        <td>1 year</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                            <h4 className="theme-font-content py-2">
                                THIRD PARTY COOKIES
                            </h4>
                            <div className="py-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Cookie Name</th>
                                            <th>Cookie Type</th>
                                            <th>Subject</th>
                                            <th>Purpose</th>
                                            <th>Retention Policy</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Optimizely</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>Website testing.</td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Marketo</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Google Analytics</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Facebook</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>LinkedIn</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Twitter</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Google AdWords</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>Microsoft Bing</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        <tr>
                                            <td>GetApp</td>
                                            <td>Analytics</td>
                                            <td>scuido.com site visitor</td>
                                            <td>The Marketo and Google Analytics cookies help us measure and evaluate
                                                our site’s performance by providing insights into site visitor behavior
                                                and actions.
                                            </td>
                                            <td>1 year</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div className="py-2">
                                    <h4 className="theme-font-content py-2">
                                        How to control the use of cookies
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        Electronic devices and software applications on these devices may offer you
                                        tools to opt out of or block advertisements on the device or in specific
                                        applications. Consult the help documentation and settings specific to your
                                        devices and applications to learn more about your options. You have the right to
                                        decide whether to accept or reject cookies. You can withdraw your consent for
                                        any of the cookies listed in the tables above by deleting them from your device.
                                        Should you choose to remove or block cookies, some website functionality may
                                        become unavailable or unreliable.
                                    </h4>
                                </div>

                                <div className="py-2">
                                    <h4 className="theme-font-content py-2">
                                        Other Tracking Technologies
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        Other than the cookies listed above, we have also implemented page tags
                                        (sometimes known as web beacons) and other tracking technologies within certain
                                        parts of our Websites and Services.
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        Page tags and web beacons. These technologies are used for the purposes of
                                        tracking visitors as they navigate our Websites to better understand Website
                                        performance, and to measure the performance of online advertising. Recipients of
                                        emails sent by our customers through our Services are also tracked using this
                                        technology. For example, web beacons track whether an email sent through the
                                        Services was delivered and opened and whether links within the email were
                                        clicked. This information is collected for the purposes of providing email
                                        campaign performance information and measuring the performance of email messages
                                        they send and is processed according to our Privacy Notice.
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        Local and session storage.Our website uses local and session storage to
                                        temporarily store information to improve user experience while interacting with the website.
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        This Privacy Notice also contains specific information about the rights of data
                                        subjects residing in the EEA and how to exercise them.
                                    </h4>
                                </div>

                                <div className="py-2">
                                    <h4 className="theme-font-content py-2">
                                        Updates to this Cookie Notice
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        scuido will periodically make amendments to this policy, as necessitated by
                                        changes to our use of cookies and the legal requirements for notice of these
                                        uses. Please continue to check this policy regularly to stay aware of these
                                        changes and how they affect your continued use of our websites.
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        The date at the top of this Cookie Notice indicates when it was last updated.
                                    </h4>
                                </div>

                                <div className="py-2">
                                    <h4 className="theme-font-content py-2">
                                        Questions?
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal">
                                        If you have any questions about our use of cookies or other technologies, please
                                        <a  href={'/contact-us'} className="px-1" style={{color: appTheme.linkStyle.color}}>contact us</a>
                                    </h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer {...props}/>
        </div>


    );
}

CookiePolicy.propTypes = {};

export default CookiePolicy;
