import React from 'react';
import {getDevice, numberWithCommas, redirectTo} from "../../utils/helper";
import Footer from "../../core/layout/footer/footer";
import {basicPermissions, businessPermissions, enterprisePermissions, proPermissions} from "./pricingModel";
import '../../assets/css/contentApp.css'
import '../../assets/css/appTheme.css'
import './pricing.css'

const PricingView = (props) => {

    const {isLGView, handleYearToMonthChange} = props;

    const getIcon = (serviceAvailable) => {
        if (serviceAvailable) {
            return (
                <i className="fa-solid fa-check active-icon" style={{color: "#1e53bd", fontSize: '16px'}}/>
            )
        }
    };

    const getPermissionObject = (service) => {

        switch (service) {
            case 'ENTERPRISE':
                return enterprisePermissions;
            case 'BUSINESS':
                return businessPermissions;
            case 'PRO':
                return proPermissions;
            case 'BASIC':
                return basicPermissions;
        }
    };

    const getPermissionUi = (plan) => {
        let permissions = getPermissionObject(plan?.code);
        return permissions?.map((name, index) => {

            return (
                <div className={''} key={index}>
                    <div className='px-3 d-flex align-items-center' key={index}
                         style={{borderBottom: '1px solid #E2E1E1', minHeight: "60px"}}>
                        <div className=' text-center'>
                            {getIcon(name?.serviceAvailable)}
                        </div>
                        <div className='ms-2 pt-3'>
                            <div className='theme-font-content theme-text-black'
                                 style={{fontFamily: 'Barlow, sans-serif',
                                          marginBottom: '1.5rem'}}>
                                {name?.name}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

    };

    const renderPriceColor = (planCode) => {
        switch (planCode) {
            case 'BASIC':
                return ('#37B2BF');
            case 'PRO':
                return ('#127ef9');
            case 'BUSINESS':
                return ('#7169e5');
            case 'ENTERPRISE':
                return ('#81B554')
        }
    };

    const getPrice = (plan) => {

        const {isYearly} = props;
        if (plan?.code === 'ENTERPRISE') {
            return (
                <div className=' mt-1 pt-1'
                     style={{
                         fontFamily: 'Barlow, sans-serif',
                         textAlign: 'center',
                         color: renderPriceColor(plan?.code),
                         fontSize: 'var(--font-size-sm)'
                     }}>
                    Contact Sales
                </div>
            )
        }

        let monthlyPrice = plan?.price?.monthlyPrice;
        let yearlyPrice = plan?.price?.effectiveMonthlyPrice;
        let currency = plan?.price?.currency;

        if (isYearly) {

            if (yearlyPrice !== 0) {
                return (
                    <>
                        <div className=''
                             style={{
                                 fontFamily: 'Barlow, sans-serif',
                                 textAlign: 'center',
                                 color: renderPriceColor(plan?.code)
                             }}>
                            <span style={{fontSize: 'var(--font-size-sm)'}}> Starts from  </span>
                            <span style={{fontSize: 'var(--font-size-md)'}}>
                                {(currency === 'INR') ? '₹ ' + numberWithCommas(yearlyPrice) : '$ ' + numberWithCommas(parseFloat(yearlyPrice).toFixed(2))}
                            </span>
                            <span style={{fontSize: 'var(--font-size-sm)'}}> / month</span>
                        </div>
                    </>
                )
            }

            return (
                <div className=''
                     style={{
                         fontFamily: 'Barlow, sans-serif',
                         textAlign: 'center',
                         color: renderPriceColor(plan?.code),
                         fontSize: isLGView ? '20px' : '26px'
                     }}>
                    Free
                </div>
            )
        } else {
            if (monthlyPrice !== 0) {
                return <>
                    <div className=''
                         style={{
                             fontFamily: 'Barlow, sans-serif',
                             textAlign: 'center',
                             color: renderPriceColor(plan?.code)
                         }}>
                        <span style={{fontSize: 'var(--font-size-sm)'}}> Starts from  </span>
                        <span style={{fontSize: 'var(--font-size-md)', }}>
                            {(currency === 'INR') ? '₹ ' + numberWithCommas(monthlyPrice) : '$ ' + numberWithCommas(parseFloat(monthlyPrice).toFixed(2))}
                        </span>
                        <span style={{fontSize: 'var(--font-size-sm)'}}> / month</span>
                    </div>
                </>
            }
            return (
                <div className=''
                     style={{
                         fontFamily: 'Barlow, sans-serif',
                         textAlign: 'center',
                         color: renderPriceColor(plan?.code),
                         fontSize: isLGView ? '20px' : '26px'
                     }}>
                    Free
                </div>
            )
        }
    };

    const choosePlanFor = (plan) => {
        const{isMobileView}=props;

        switch (plan?.code) {
            case 'BASIC':
                return (
                    <>
                        <div style={{fontSize: isLGView ? '16px' : '20px',
                            color: '#575757',
                            fontWeight: 500,
                            fontFamily: 'Barlow, sans-serif',
                            textAlign: 'center'}}>
                            Free plan
                        </div>
                        <div style={{fontSize: isLGView ? '16px' : '20px',
                            color: '#575757',
                            fontWeight: 500,
                            fontFamily: 'Barlow, sans-serif',
                            textAlign: 'center',
                            marginBottom: '1.5rem'}}>
                            to get started
                        </div>
                        <button onClick={() => {redirectTo('/app/user/register') }}
                                style={{backgroundColor: '#4380c5',
                                    color: "white",
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 18,
                                    border: 'none',
                                    minHeight: '40px',}}>
                            {handleDisable(plan) ? 'Not Applicable' : 'Start Now, it\'s free'}
                        </button>
                    </>
                );
            case 'PRO':
                return (
                    <>
                        <div className={!isMobileView ? "mb-3" : "mb-1"}
                             style={{
                                 height: isMobileView ? 48 : 28,
                                 fontSize: '16px',
                                 fontFamily: 'Barlow, sans-serif',
                                 fontWeight: 500
                             }}>
                           <div> For professionals, small businesses </div>
                        </div>

                        <div className={!isMobileView? "mb-3" : "mb-1"}
                             style={{height: isMobileView? 48 : 28 }}>
                            {getPrice(plan)}
                        </div>

                        <button className={"theme-bg-primary"}
                                onClick={() => {redirectTo('/app/user/register')}}
                                style={{
                                    color: "white",
                                    fontWeight: 300,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 16,
                                    border: 'none',
                                    minHeight: '40px',
                                }}>
                            {handleDisable(plan) ? 'Not Applicable' : 'Choose'}
                        </button>
                    </>
                );
            case 'BUSINESS':
                return (
                    <>
                        <div className={!isMobileView ? "px-2 mb-3" : "px-2 mb-1"}
                             style={{
                                 height: isMobileView ? 48 : 28,
                                 fontSize: '16px',
                                 fontFamily: 'Barlow, sans-serif',
                                 fontWeight: 500
                             }}>
                            <div> For medium sized and growing businesses </div>
                        </div>

                        <div className={!isMobileView? "mb-3" : "mb-1"}
                             style={{height: isMobileView? 48 : 28 }}>
                            {getPrice(plan)}
                        </div>
                        <button className={"theme-bg-primary"}
                                onClick={() => {redirectTo('/app/user/register')}}
                                style={{
                                    color: "white",
                                    fontWeight: 300,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 16,
                                    border: 'none',
                                    minHeight: '40px',}}>
                            {handleDisable(plan) ? 'Not Applicable' : "Choose"}
                        </button>
                    </>
                );
            case 'ENTERPRISE':
                return (
                    <>
                        <div className={!isMobileView ? "mb-3" : "mb-1"}
                             style={{
                                 height: isMobileView ? 48 : 28,
                                 fontSize: '16px',
                                 fontFamily: 'Barlow, sans-serif',
                                 fontWeight: 500
                             }}>
                            <div>For large enterprises</div>
                        </div>

                        <div className={!isMobileView ? "mb-3" : "mb-1"}
                             style={{height: isMobileView ? 48 : 28}}>
                            {getPrice(plan)}
                        </div>
                        <button className={"theme-bg-primary"}
                                      onClick={() => {}}
                                      style={{
                                          color: "white",
                                          fontWeight: 300,
                                          textTransform: 'initial',
                                          padding: '8px 0px',
                                          borderRadius: 8,
                                          lineHeight: "22px",
                                          height: '42px',
                                          minWidth: '171px',
                                          fontSize: 16,
                                          border: 'none',
                                          minHeight: '40px'}}>
                        {handleDisable(plan) ? 'Not Applicable' : "Contact Us"}
                    </button>
                    </>
                )
        }
    };

    const choosePlanForOld = (plan) => {

        const{isMobileView}=props;

        switch (plan?.code) {
            case 'BASIC':
                return (
                    <>
                        <div style={{fontSize: isLGView ? '16px' : '20px',
                            color: '#575757',
                            fontWeight: 500,
                            fontFamily: 'Barlow, sans-serif',
                            textAlign: 'center'}}>
                            Free plan
                        </div>
                        <div style={{fontSize: isLGView ? '16px' : '20px',
                            color: '#575757',
                            fontWeight: 500,
                            fontFamily: 'Barlow, sans-serif',
                            textAlign: 'center',
                            marginBottom: '1.5rem'}}>
                            to get started
                        </div>
                        <button onClick={() => {redirectTo('/app/user/register') }}
                            style={{backgroundColor: '#4380c5',
                                color: "white",
                                fontWeight: 500,
                                textTransform: 'initial',
                                padding: '8px 0px',
                                borderRadius: 8,
                                lineHeight: "22px",
                                height: '42px',
                                minWidth: '171px',
                                fontSize: 18,
                                border: 'none',
                                minHeight: '40px',}}>
                            {handleDisable(plan) ? 'Not Applicable' : 'Start Now, it\'s free'}
                        </button>
                    </>
                );
            case 'PRO':
                return (
                    <>
                        <div className={!isMobileView? "mb-3" : "mb-1"} style={{height: isMobileView ? 48 : 28}}>

                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                                 fontFamily: 'Barlow, sans-serif'
                             }}>
                            For professionals,
                        </div>
                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                                 fontFamily: 'Barlow, sans-serif',
                                 marginBottom: '1.5rem'
                             }}>
                            small businesses
                        </div>
                        </div>
                        <button className={"theme-bg-primary"}
                                onClick={() => {redirectTo('/app/user/register')}}
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 18,
                                    border: 'none',
                                    minHeight: '40px',
                                }}>
                            {handleDisable(plan) ? 'Not Applicable' : 'Choose'}
                        </button>
                    </>
                );
            case 'BUSINESS':
                return (
                    <>
                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                            fontFamily: 'Barlow, sans-serif',}}>
                            For medium sized
                        </div>
                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                            fontFamily: 'Barlow, sans-serif',
                            marginBottom: '1.5rem'}}>
                            and growing businesses
                        </div>
                        <button className={"theme-bg-primary"}
                                onClick={() => {redirectTo('/app/user/register')}}
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 18,
                                    border: 'none',
                                    minHeight: '40px',}}>
                            {handleDisable(plan) ? 'Not Applicable' :"Choose"}
                        </button>
                    </>
                );
            case 'ENTERPRISE':
                return (
                    <>
                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                            fontFamily: 'Barlow, sans-serif'}}>
                            For large
                        </div>
                        <div className='theme-font-semiBold theme-text-black'
                             style={{
                                 fontSize: '16px',
                            fontFamily: 'Barlow, sans-serif',
                            marginBottom: '1.5rem'}}>
                            enterprises
                        </div>
                        <button className={"theme-bg-primary"}
                                onClick={() => {}}
                                style={{
                                    color: "white",
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    padding: '8px 0px',
                                    borderRadius: 8,
                                    lineHeight: "22px",
                                    height: '42px',
                                    minWidth: '171px',
                                    fontSize: 18,
                                    border: 'none',
                                    minHeight: '40px'}}>
                            {handleDisable(plan) ? 'Not Applicable' : "Contact Us"}
                        </button>
                    </>
                )
        }
    };

    const handleDisable = (plan) => {
        const {isApiLoadedPRO, isApiLoadedBusiness, isApiLoadedEnterprise, isApiLoadedBasic} = props;
        return !!(isApiLoadedPRO || isApiLoadedBusiness || isApiLoadedEnterprise || isApiLoadedBasic || plan?.isDisable);
    };

    const renderHeaderColor = (planCode) => {
        switch (planCode) {
            case 'BASIC':
                return ('bg-basic');
            case 'PRO':
                return ('bg-pro');
            case 'BUSINESS':
                return ('bg-business');
            case 'ENTERPRISE':
                return ('bg-enterprise')
        }
    };

    const getChannelNames=(channels)=>{
        return  channels.map(channel=>{
            if(channel === 'EMAIL'){
                return 'Send Emails'
            }else if(channel === 'WHATSAPP'){
                return 'WhatsApp'
            }else if(channel === 'PUSH'){
                return 'Push Messages'
            }else if(channel === 'SMS'){
                return 'SMS'
            }
        });
    };

    const showPlanFeatures = (plan) => {
        let headerArr = getPermissionObject(plan?.code);

        return <div>
            {headerArr[0].header.map((headerText) => {
                return <div className='px-3 d-flex align-items-center'
                            style={{borderBottom: '1px solid #E2E1E1', minHeight: "60px"}}>
                    <div className='text-center'>
                        {getIcon(true)}
                    </div>
                    <div className='ms-2 theme-font-content theme-text-black pt-3'
                         style={{fontFamily: 'Barlow, sans-serif',
                             marginBottom: '1.5rem'}}>
                        {headerText.name}
                    </div>
                </div>
            })}
        </div>
    };

    const showPlanFeaturesFooter = (plan) => {
        let footerArr = getPermissionObject(plan?.code);

        return <div>
            {footerArr[1].footer.map((footerText, index) => {
                return <div className='px-3 d-flex align-items-center'
                            style={{borderBottom:  (index < footerArr[1].footer.length -1) ? '1px solid #E2E1E1' : '', minHeight: "60px"}}>
                    <div className='text-center'>
                        {getIcon(true)}
                    </div>
                    <div className='ms-2 theme-font-content  theme-text-black pt-3'
                         style={{fontFamily: 'Barlow, sans-serif',
                             marginBottom: '1.5rem'}}>
                        {footerText.name}
                    </div>
                </div>
            })}
        </div>
    };

    const renderPlanFeatures=(plan)=>{
        const channels= plan.planConfig.availableChannels;
        const channelStr= getChannelNames(channels);

        return (
            <div className='px-3 d-flex align-items-center'
                 style={{borderBottom: '1px solid #E2E1E1', minHeight: "60px"}}>
                <div className='text-center'>
                    {getIcon(true)}
                </div>
                <div className='ms-2 theme-font-content theme-text-black pt-3'
                     style={{fontFamily: 'Barlow, sans-serif',
                         marginBottom: '1.5rem'}}>
                    {channelStr.join(', ')}
                </div>
            </div>
        )
    };

    const getFeatType=(specType)=>{
        switch (specType) {
            case 'EMAIL': return ' emails/month';
            case 'WHATSAPP': return ' whatsapp messages/month';
            case 'PUSH': return ' push messages/month';
            case 'SMS': return ' sms/month';
        }
    };

    const renderPlanSpecs=(plan)=>{
        const specs= plan.planConfig.channelCustomisation;

        return specs.map(spec=>{
            return <div className='px-3 d-flex align-items-center'
                        style={{borderBottom: '1px solid #E2E1E1', minHeight: "60px"}}>
                <div className='text-center'>
                    {getIcon(true)}
                </div>
                <div className='ms-2 theme-font-content theme-text-black pt-3'
                         style={{fontFamily: 'Barlow, sans-serif',
                             marginBottom: '1.5rem'}}>
                    {'Starts from ' + numberWithCommas(spec.min) + ' Up to ' + numberWithCommas(spec.max) + getFeatType(spec.type)}
                </div>
            </div>
        });
    };

    const renderPlanUi = () => {

        return props.plans.map((plan, index) => {
            return (
                <div className='col-xl-4 col-md-4 col-12  my-3' key={index}>
                    <div className='h-100 px-0'>
                        <div className='d-flex flex-column justify-content-between mx-0  h-100'>
                            <div className='d-flex flex-column h-100'>
                                <div className='d-flex flex-column justify-content-between h-100'
                                     style={{height: '190px'}}>
                                    <div className={renderHeaderColor(plan?.code)}
                                         style={{borderRadius: '8px 8px 0px 0px'}}>
                                        <div  className='d-flex flex-column justify-content-center align-items-center py-2 panel-header'
                                              style={{maxHeight: '42px'}}>
                                            <div className='theme-font-section-sub-header theme-text-white theme-font-semiBold'
                                                 style={{fontFamily: 'Barlow, sans-serif', fontSize:'16px'}}>
                                                {plan?.code}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'panel h-100'}
                                         style={{borderRadius: '0px 0px 8px 8px'}}>
                                        {plan.code === 'ENTERPRISE' ?
                                            <div>
                                                <div className='my-4 text-center  px-3 '>
                                                    {choosePlanFor(plan)}
                                                </div>
                                                <div className={''}>
                                                    <div className='mx-2' style={{borderTop: '1px solid #E2E1E1'}}>
                                                        {getPermissionUi(plan)}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className='my-4 text-center px-3 '>
                                                    {choosePlanFor(plan)}
                                                </div>
                                                <div className='text-center'
                                                     style={{borderTop: '1px solid #E2E1E1'}}>
                                                    <div className='mx-2'>
                                                        {showPlanFeatures(plan)}
                                                    </div>
                                                </div>

                                                <div className='mx-2'>
                                                    {renderPlanFeatures(plan)}
                                                </div>

                                                <div className='mx-2'>
                                                    {renderPlanSpecs(plan)}
                                                </div>
                                                <div className='mx-2'>
                                                    {showPlanFeaturesFooter(plan)}
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {/*<div className='panel h-100' style={{borderRadius: '0px 0px 8px 8px'}}>*/}
                                    {/*    <div className='d-flex flex-column justify-content-center align-items-center mt-2 pt-1'>*/}
                                    {/*        <div className='' style={{fontSize: '1.31950791rem'}}>*/}
                                    {/*             <span>*/}
                                    {/*                {getPrice(plan)}*/}
                                    {/*             </span>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='my-2 text-center'>*/}
                                    {/*        {choosePlanFor(plan)}*/}
                                    {/*    </div>*/}
                                    {/*    <div className={'mt-3'}>*/}
                                    {/*        <div className='mx-2' style={{borderTop: '1px solid #E2E1E1'}}>*/}
                                    {/*            {getPermissionUi(plan)}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    };

    const getHeaderCSS = () => {
        const {isXSView} = props;
        if (isXSView || (getDevice() === 'iPad')) {
            return 'd-flex justify-content-center align-items-center  flex-column theme-font-section-sub-header theme-text-tertiary theme-font-normal'
        } else {
            return 'd-flex justify-content-center align-items-center flex-column theme-font-section-sub-header theme-text-tertiary theme-font-semiBold pageContainerMargin'
        }
    };

    return (
        <div className=' bg-white  w-100 '>
            <div className={(props.isXSView || (getDevice() === 'iPad')) ? 'white pageContainerMargin' :'bg-white plan-container'}>
                <div className={(props.isXSView || (getDevice() === 'iPad'))? "panel my-3 p-3" : "panel my-4 p-3 pageContainerMargin"}>
                    <div className={getHeaderCSS()}>
                        {'Beta Testing : Now Live!'}
                    </div>
                    <div className="my-3 d-flex justify-content-center align-items-center text-center">
                        <div className='theme-font-content theme-font-normal theme-text-black'
                             style={{maxWidth: '900px', fontSize: '14px'}}>
                            We're thrilled to announce that our much-anticipated Beta Testing phase has now kicked
                            off! This approach enables us to gather valuable feedback from individuals with diverse
                            backgrounds and experiences, enabling us to shape the future of SCuiDO.
                        </div>
                    </div>
                    <div className={"mb-3 text-center"}>
                        <div className='theme-font-content theme-font-bold theme-text-black'
                             style={{ fontSize: '14px'}}>
                            Don't miss out on this incredible chance to be among the first to experience our cutting
                            edge product!
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className='mb-3 text-center'>
                            <button onClick={() => {
                                    props.history.push('/subscription/beta-request')}}
                                className="theme-bg-primary"
                                style={{
                                    color: 'white',
                                    minHeight: '40px',
                                    fontSize: '16px',
                                    borderRadius: '8px',
                                    textTransform: 'initial',
                                    minWidth: '170px',
                                    fontfamily: 'Barlow',
                                    border: '1px solid #0B407D',
                                    padding: '8px 10px',
                                    borderWidth: '2px',
                                    lineHeight: '22px',
                                    fontWeight: 500
                                }}>
                                Request Access
                            </button>
                        </div>
                    </div>
                </div>
                <div className={getHeaderCSS()}>
                    <div className='d-flex justify-content-center align-items-center pt-3'>
                        {'Choose Your Plan'}
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="">
                            <div className='theme-font-content theme-font-semiBold theme-text-black' style={{
                                lineHeight: "56.21px",
                                fontFamily: 'Barlow, sans-serif',
                                textAlign: 'center'}}>
                                Monthly
                            </div>
                        </div>
                        <div className='ms-2' style={{lineHeight: '0px'}}>
                            <div className='mt-1'
                                 onChange={() => handleYearToMonthChange()}>
                                <label className="switch ">
                                    <input type="checkbox"  checked={props.isYearly}/>
                                    <span className="slider round "/>
                                </label>
                            </div>
                        </div>

                        <div className='ms-2'>
                            <div className='theme-font-semiBold theme-font-content'
                                 style={{lineHeight: "56.21px",
                                color: "#575757",
                                fontFamily: 'Barlow, sans-serif',
                                textAlign: 'center'}}>
                                Annual
                            </div>
                        </div>
                        <div style={{marginLeft: "10px", paddingTop: 4}}>
                            <div className={"d-flex align-items-center theme-font-content"}
                                 style={{
                                     backgroundColor: '#fea133',
                                     borderRadius: 8,
                                     padding: '4px 11px 6px 12px',
                                     color: 'white',
                                     height: '30px',}}>
                                {"Save 20%"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className= {(props.isXSView || (getDevice() === 'iPad')) ? "row " : 'row pageContainerMarginRow'}>{renderPlanUi()}</div>
            </div>
            <div className={"block-container"}>
                <div className="footer-border-light"/>
            </div>
            <Footer {...props}/>
        </div>
    );

};

PricingView.propTypes = {};

export default PricingView;
