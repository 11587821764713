import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

function RefundPolicy(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className="w-100">
            <div className="px-3">
                <div className="row">
                    <div className="offset-md-2 col-md-8 col-12 ">
                        <div className="panel p-3 my-2">
                            <div className="d-flex justify-content-center mb-4 mt-2">
                                <h1 className="theme-font-page-title py-4">
                                    REFUND POLICY
                                </h1>
                            </div>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    Last updated May 20, 2024
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    As a company policy, we do not issue refunds unless a system malfunction caused a
                                    problem or your account was terminated without cause.
                                    <br/>
                                    In this article, you will learn about SCuiDO's refund policy.
                                </h6>
                            </div>

                            <h4 className="theme-font-content-lg pt-3 pb-2">Eligibility for Refunds:</h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    <ul>
                                        <li>We do not offer refunds for any monthly or yearly subscriptions that have
                                            already been billed.
                                        </li>
                                        <li>Exceptions to this policy may be made at our sole discretion under
                                            extraordinary circumstances, such as a system-wide failure that prevented
                                            you from using the service.
                                        </li>
                                    </ul>
                                </h6>
                            </div>


                            <h4 className="theme-font-content-lg pt-3 pb-2">
                                Requesting a Refund:
                            </h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    <ul>
                                        <li>
                                            To request a refund, please contact our support team at
                                            <a className="px-1" href={"mailto:"}>support@scuido.com</a>
                                            with your account details and a description of the issue.
                                        </li>
                                        <li>
                                            Refund requests must be made within 14 days of the billing date to be
                                            considered.
                                        </li>
                                    </ul>
                                </h6>
                            </div>


                            <h4 className="theme-font-content-lg pt-3 pb-2">
                                Changes to Subscription Plans and Pricing
                            </h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    <ul>
                                        <li>
                                            We reserve the right to modify our subscription plans and pricing at any
                                            time. Any changes will be communicated to you in advance and will take
                                            effect at the start of your next billing cycle.
                                        </li>
                                        <li>
                                            If you do not agree to the changes, you may cancel your subscription before
                                            the new billing cycle begins.
                                        </li>
                                    </ul>
                                </h6>
                            </div>


                            <h4 className="theme-font-content-lg pt-3 pb-2">
                                Payment Processing
                            </h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    <ul>
                                        <li>
                                            All payments are processed through Razorpay, a secure and trusted payment
                                            gateway.
                                        </li>
                                        <li>
                                            By subscribing to our service, you agree to be bound by Razorpay's terms and
                                            conditions.
                                        </li>
                                    </ul>
                                </h6>
                            </div>


                            <h4 className="theme-font-content-lg pt-3 pb-2">
                                Contact Information
                            </h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    For any questions or concerns regarding our cancellation and refund policy, please
                                    contact our support team at: <a className="px-1"
                                                                    href={"mailto:"}>support@scuido.com</a>
                                </h6>
                            </div>

                            <h4 className="theme-font-content-lg pt-3 pb-2">
                                Razorpay Terms and Conditions
                            </h4>
                            <div>
                                <h6 className="theme-font-content theme-font-normal py-2">
                                    <ul>
                                        <li>
                                            All payments made through Razorpay are subject to Razorpay's terms and
                                            conditions. For more information, please visit Razorpay's official website
                                            or refer to
                                            <a className="px-1 text-break"
                                               target={"_blank"}
                                               href={'https://razorpay.com/s/terms/customer/'}>
                                                https://razorpay.com/s/terms/customer/
                                            </a>
                                            to know more.
                                        </li>
                                    </ul>
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2 mb-3">
                                    This policy ensures that customers are informed about their subscription commitments
                                    and the conditions under which they can cancel or request refunds, aligning with
                                    standard practices in the industry and Razorpay's payment processing requirements.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"block-container"}>
                <div className="footer-border-light"/>
            </div>
            <Footer {...props}/>
        </div>
    );
}

export default RefundPolicy;
