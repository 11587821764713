export const enterprisePermissions =[
    {
        serviceAvailable:true,
        name:'Everything in Business plan',
    },
    {
        serviceAvailable:true,
        name:'Custom deployment in separate infrastructure',
    },
    {
        serviceAvailable:true,
        name:'Dedicated Relationship Manager',
    },
    {
        serviceAvailable:true,
        name:'Consulting Services available on request',
    },
    {
        serviceAvailable:true,
        name:'Tech Support for migration',
    }
];

export const businessPermissions = [
    {
        header: [
            {
                serviceAvailable: true,
                name: 'Unlimited Contacts',
            },
        ]
    },
    {
        footer: [
            // {
            //     serviceAvailable: true,
            //     name: 'Unlimited emails',
            // },
            // {
            //     serviceAvailable: true,
            //     name: 'Unlimited push messages',
            // },
            {
                serviceAvailable: true,
                name: 'Advanced Segmentation',
            },
            {
                serviceAvailable: true,
                name: 'Up to 25 team members',
            },
            {
                serviceAvailable: true,
                name: 'On-call support in business hours',
            },
        ]
    },

    {
        serviceAvailable: true,
        name: 'Send Emails, Push Messages',
    },

];

export const proPermissions = [
    {
        header: [
            {
                serviceAvailable: true,
                name: 'Unlimited Contacts',
            },
        ]
    },
    {
        footer: [
            {
                serviceAvailable: true,
                name: 'Advanced Segmentation',
            },
            {
                serviceAvailable: true,
                name: 'Up to 7 team members',
            },
            {
                serviceAvailable: true,
                name: 'Emails Support',
            },
        ]
    },
    {
        serviceAvailable: true,
        name: 'Send Emails, Push Messages',
    },
    {
        serviceAvailable: true,
        name: 'Up to 1,000,000 emails/month',
    },
    {
        serviceAvailable: true,
        name: 'Up to 1,000,000 push messages/ month',
    }
];

// export const businessPermissions=[
//     {
//         serviceAvailable:true,
//         name:'Unlimited Contacts',
//     },
//     {
//         serviceAvailable:true,
//         name:'Send Emails, Push Messages',
//     },
//     {
//         serviceAvailable:true,
//         name:'Unlimited emails',
//     },
//     {
//         serviceAvailable:true,
//         name:'Unlimited push messages',
//     },
//     {
//         serviceAvailable:true,
//         name:'Advanced Segmentation',
//     },
//     {
//         serviceAvailable:true,
//         name: 'Up to 25 team members',
//     },
//     {
//         serviceAvailable:true,
//         name:'On-call support in business hours',
//     },
// ];
//
// export const proPermissions=[
//     {
//         serviceAvailable:true,
//         name:'Unlimited Contacts',
//     },
//     {
//         serviceAvailable:true,
//         name:'Send Emails, Push Messages',
//     },
//     {
//         serviceAvailable:true,
//         name:'Up to 1,000,000 emails/month',
//     },
//     {
//         serviceAvailable:true,
//         name:'Up to 1,000,000 push messages/ month',
//     },
//     {
//         serviceAvailable:true,
//         name:'Advanced Segmentation',
//     },
//     {
//         serviceAvailable:true,
//         name: 'Up to 7 team members',
//     },
//     {
//         serviceAvailable:true,
//         name:'Emails Support',
//     },
// ];

export const basicPermissions=[
    {
        serviceAvailable:true,
        name:'Up to 500 Contacts',
    },
    {
        serviceAvailable:true,
        name:'Send Emails',
    },
    {
        serviceAvailable:true,
        name:'Up to 2,000 Emails/month',
    },
    {
        serviceAvailable:true,
        name:'Basic Segmentation',
    },
    {
        serviceAvailable:true,
        name:'Single User plan',
    }
];