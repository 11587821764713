import React, {useEffect} from "react";
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {redirectTo} from "../../utils/helper";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";


function HomeServices(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'home-service-banner-mobile'}
                             className="section-padding-bottom ">
                        <div className={isProdEnvironment ? "d-flex home-service-banner-bg-prod banner-bg-height py-5":
                            "d-flex home-service-banner-bg banner-bg-height py-5"}>
                            <div className="d-flex align-items-center block-container ">
                                <div>
                                    <h1 className='page-title theme-font-semiBold '>
                                        Customer Outreach for
                                        <br/>
                                        <span className="theme-font-bolder px-1"
                                              style={{color: 'var(--tertiary)'}}>
                                              Home Services
                                        </span>
                                    </h1>
                                    <h3 className="py-4 section-header theme-font-normal"
                                        style={{width: (isXSView ? "" : "60%")}}>
                                        Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and
                                        Sales Teams
                                    </h3>
                                    <button className="sign-up-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                        Contact Us
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-leading-advantage-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center">
                            <div className="block-container">
                                <h2 className='section-title theme-text-black theme-font-bold'>
                                    Frequent Patronage, Exclusive Advantages
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    Your frequent customers deserve something extra. Design special schemes for
                                    them.
                                </h3>
                                <h3 className="section-sub-header theme-font-normal">
                                    Exclusive Special Schemes
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Access unique programs to enhance customer loyalty.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Tailored Benefits
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer personalized discounts, rewards, and benefits.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Boost repeat sales from frequent customers!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/frequentPatronage.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-target-value-added-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center w-100">
                            <div className="block-container">
                                <h2 className='section-title theme-text-black theme-font-bold'>
                                    Targeted Value-Added Services
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                    Explore precision marketing for your local home service business.
                                </h3>
                                <h3 className="section-sub-header theme-font-normal">
                                    Geographic Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Connect with customers in specific localities and highlight your value-added
                                    services.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Value-Added Offerings
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Promote unique packages like the "Home Wellness Package" to enhance customer
                                    loyalty.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Engage, track, and grow your business!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/targetedValue.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-periodic-cleaning-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center">
                            <div className="block-container">
                                <h2 className='section-title theme-font-bold'>
                                    Periodic Cleaning – Timely Reminders!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                    Every house requires periodic cleaning.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Effortless Cleaning Routines
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Streamline your customers' cleaning routines.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Scheduled Maintenance
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Eliminate the need to remember cleaning tasks with tailored schedules.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Timing Is Key
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Reach out to customers precisely when they need your services.
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/periodicCleaning.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-ease-customer-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center">
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    Ease Your Customer's Moving Experience!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Moving to a new house brings anxiety for all. Make it easy for them!
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Seize the moment
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Capitalize on the customer's move to a new house to expand your service offerings.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Reliable Neighborhood Ally
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Establish yourself as a dependable resource in their new neighborhood.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Timely Assistance
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Offer prompt help and solutions to build trust from the start.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Simplify moves, expand your footprint, and boost referrals!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/easeYour.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-bring-back-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center">
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Bring Back Inactive Customers
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Pay attention to your inactive customers.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Reconnect with Inactive Customers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Rekindle relationships with customers who haven't used your services in varying
                                    timeframes.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Customize Date Ranges
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Choose specific time periods (3 months, 6 months, a year, or more) for targeted
                                    outreach.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Exclusive Promotions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Share enticing offers designed to win back these inactive customers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Don't overlook this uncharted growth potential—seize it today!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/bringBackInactive.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-retarget-customer-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Retarget Customers to Cross-Sell
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Explore smart cross-selling opportunities like never before.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Smart Recommendations
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Suggest additional or related services to existing customers based on their recent
                                    subscription.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Targeted Nudge Marketing
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer services like window cleaning to customers who've recently had floor cleaning
                                    and many more ideas like this.
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/retargetCustomers.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-uncover-upsell-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Uncover Upsell Opportunities
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Value-added services are a game-changer for your business. Use precision targeted
                                    upsell.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Game-Changing Additions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Value-added services can transform your business dynamics, captivating your
                                    customers.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Personalized for Subscribers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Craft services such as the "Home Wellness Package" exclusively for your loyal
                                    subscribers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Ultimate home service experience for your customers and loyal customer base for you!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/uncoverUpsell.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-send-reminders-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-40'>
                                    Send Reminders to Abandoned Cart Customers
                                </h2>
                                <h3 className="section-sub-header theme-font-normal">
                                    Targeted Reminder Campaigns
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Send emails, notifications, and messages to re-engage customers who abandoned the
                                    checkout process.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Persuasive Messaging
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Craft compelling content to persuade potential buyers to complete their purchase.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Discount Incentives
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Implement discount schemes to effectively address checkout abandonment, particularly
                                    for price-sensitive customers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Recover your lost revenue!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/sendReminders.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'home-service-reward-mobile'}
                             className="section-padding-bottom">
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-40'>
                                    Reward Your Top-Performing Customer Segment
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Maximize your strengths!
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Identify Top-Performing Segments
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Determine your strongest market segments based on performance.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Tailored Exclusive Offers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Create compelling, exclusive offers designed specifically for these highperforming
                                    segments.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Precision Segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Utilize various segmentation criteria, from cities to service types, to pinpoint the
                                    most profitable segments.
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/homeService/rewardYour.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section className='padding-section-vertical secondary-banner-bg'
                             id={"home-service-secondary-footer-mobile"}>
                        <h4 className='theme-text-white mb-0 text-center theme-font-bold section-title'
                            style={{paddingBottom: 60}}>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                    className="footer-sign-up-button">
                                    Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                <button onClick={() => redirectTo('/contact-us')}
                                    className="footer-free-demo-button">
                                        Get A Free Demo
                                </button>
                            </div>
                        </div>
                    </section>

                </>
            )
        }
        return (
            <div>
                <section id={'home-service-banner-desktop'} className="section-padding-bottom">
                    <div className={isProdEnvironment ? "d-flex home-service-banner-bg-prod banner-bg-height":
                        "d-flex home-service-banner-bg banner-bg-height"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container " style={{width: "90%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer Outreach for
                                    <span className="theme-font-bolder px-1"
                                          style={{color: 'var(--tertiary)'}}>
                                     Home Services
                                    </span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales
                                    Teams
                                </h3>
                                <a href={"/contact-us"}>
                                    <button className="sign-up-button">
                                        Contact Us
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'home-service-frequent-patronage-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/frequentPatronage.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Frequent Patronage, Exclusive Advantages
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    Your frequent customers deserve something extra. Design special schemes for them.
                                </h3>
                                <h3 className="section-sub-header  pt-2">
                                    Exclusive Special Schemes
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Access unique programs to enhance customer loyalty.
                                </h4>
                                <h3 className="section-sub-header">
                                    Tailored Benefits
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer personalized discounts, rewards, and benefits
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40 ">
                                    Boost repeat sales from frequent customers!
                                </h4>

                            </div>
                        </div>
                    </div>
                </section>

                <section id={'home-service-target-value-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Targeted Value-Added Services
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Explore precision marketing for your local home service business.
                                </h3>
                                <h3 className="section-sub-header">
                                    Geographic Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Connect with customers in specific localities and highlight your value-added
                                    services.
                                </h4>
                                <h3 className="section-sub-header">
                                    Value-Added Offerings
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Promote unique packages like the "Home Wellness Package" to enhance
                                    customer loyalty.
                                </h4>

                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Engage, track, and grow your business!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/targetedValue.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'home-service-periodic-cleaning-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/periodicCleaning.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title '>
                                    Periodic Cleaning – Timely Reminders!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                    Every house requires periodic cleaning.
                                </h4>
                                <h3 className="section-sub-header">
                                    Effortless Cleaning Routines
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Streamline your customers' cleaning routines.
                                </h4>
                                <h3 className="section-sub-header">
                                    Scheduled Maintenance
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Eliminate the need to remember cleaning tasks with tailored schedules.
                                </h4>
                                <h3 className="section-sub-header">
                                    Timing Is Key
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Reach out to customers precisely when they need your services.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'home-service-ease-customer-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    Ease Your Customer's Moving Experience!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Moving to a new house brings anxiety for all. Make it easy for them!
                                </h4>
                                <h3 className="section-sub-header">
                                    Seize the moment
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Capitalize on the customer's move to a new house to expand your service offerings.
                                </h4>
                                <h3 className="section-sub-header">
                                    Reliable Neighborhood Ally
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Establish yourself as a dependable resource in their new neighborhood.
                                </h4>
                                <h3 className="section-sub-header">
                                    Timely Assistance
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Offer prompt help and solutions to build trust from the start.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Simplify moves, expand your footprint, and boost referrals!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/easeYour.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'home-service-bring-back-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/bringBackInactive.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Bring Back Inactive Customers
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Pay attention to your inactive customers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Reconnect with Inactive Customers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Rekindle relationships with customers who haven't used your services in varying
                                    timeframes.
                                </h4>
                                <h3 className="section-sub-header">
                                    Customize Date Ranges
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Choose specific time periods (3 months, 6 months, a year, or more) for targeted
                                    outreach.
                                </h4>
                                <h3 className="section-sub-header">
                                    Exclusive Promotions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Share enticing offers designed to win back these inactive customers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Don't overlook this uncharted growth potential—seize it today!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'home-service-retarget-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Retarget Customers to Cross-Sell
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Explore smart cross-selling opportunities like never before.
                                </h4>
                                <h3 className="section-sub-header">
                                    Smart Recommendations
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Suggest additional or related services to existing customers based on their recent
                                    subscription.
                                </h4>
                                <h3 className="section-sub-header">
                                    Targeted Nudge Marketing
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Offer services like window cleaning to customers who've recently had floor cleaning
                                    and many more ideas like this.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/retargetCustomers.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'home-service-uncover-upselling-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/uncoverUpsell.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Uncover Upsell Opportunities
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Value-added services are a game-changer for your business. Use precision targeted
                                    upsell.
                                </h4>
                                <h3 className="section-sub-header">
                                    Game-Changing Additions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Value-added services can transform your business dynamics, captivating your
                                    customers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Personalized for Subscribers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Craft services such as the "Home Wellness Package" exclusively for your loyal
                                    subscribers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Ultimate home service experience for your customers and loyal customer base for you!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'home-service-send-reminder-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Send Reminders to Abandoned Cart Customers
                                </h2>
                                <h3 className="section-sub-header">
                                    Targeted Reminder Campaigns
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Send emails, notifications, and messages to re-engage customers who abandoned the
                                    checkout process.
                                </h4>
                                <h3 className="section-sub-header">
                                    Persuasive Messaging
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Craft compelling content to persuade potential buyers to complete their purchase.
                                </h4>
                                <h3 className="section-sub-header">
                                    Discount Incentives
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Implement discount schemes to effectively address checkout abandonment, particularly
                                    for price-sensitive customers.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Recover your lost revenue!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/sendReminders.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'home-service-reward-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/homeService/rewardYour.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Reward Your Top-Performing Customer Segment
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Maximize your strengths!
                                </h4>
                                <h3 className="section-sub-header">
                                    Identify Top-Performing Segments
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Determine your strongest market segments based on performance.
                                </h4>
                                <h3 className="section-sub-header">
                                    Tailored Exclusive Offers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Create compelling, exclusive offers designed specifically for these highperforming
                                    segments.
                                </h4>
                                <h3 className="section-sub-header">
                                    Precision Segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Utilize various segmentation criteria, from cities to service types, to pinpoint the
                                    most profitable segments.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"home-service-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>
                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button onClick={() => redirectTo('/contact-us')}
                                className="footer-free-demo-button">
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(HomeServices));
