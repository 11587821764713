import React, {useEffect} from "react";
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {redirectTo} from "../../utils/helper";
import "../useCases.css";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";


function Education(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'education-banner-mobile'} className="section-padding-bottom ">
                        <div className={isProdEnvironment ? "d-flex education-banner-bg-prod banner-bg-height py-5" : "d-flex education-banner-bg banner-bg-height py-5"}>
                            <div className="d-flex align-items-center block-container ">
                                <div>
                                    <h1 className='page-title theme-font-semiBold '>
                                        Student Outreach for
                                        <br/>
                                        <span className="theme-font-bolder"
                                              style={{color: 'var(--tertiary)'}}>
                                            Education Institutes
                                        </span>
                                    </h1>
                                    <h3 className="py-4 section-header theme-font-normal"
                                        style={{width: (isXSView ? "" : "60%")}}>
                                        Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales Teams
                                    </h3>
                                    <a href={"/contact-us"}>
                                        <button className="sign-up-button">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-brand-awareness-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Brand Awareness before Admissions
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                        During the admission process, communicate your institution's brand awareness by highlighting its unique value proposition.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Student Success Rates
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Celebrate achievements of your current and past students. Ignite the Dreams of Future Scholars.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Attract Talent with Innovation
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Highlight distinctive innovative programs that set your institution apart.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Strengths Showcase
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Highlight your brand's strengths, including faculty expertise, facilities, and accreditations, to instil confidence.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Reputation Reinforcement
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Leverage your institution's strong reputation as a key selling point in your admissions communication strategy.
                                    </h4>

                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Years of dedication have forged your institution's unique identity -
                                        <span className="px-1 theme-text-tertiary"> Flaunt It! </span>
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/brandAwarness.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-admission-journey-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center w-100" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Foster an Exceptional Admission Journey
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        Effective communication during the admission process involves a multi-stage approach.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Welcome and Guidance
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Start with a warm welcome email and an informative FAQ sheet to assist applicants from the beginning.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Personalized Support
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Keep applicants engaged with tailored updates and timely reminders throughout the admission journey.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Acceptance Appreciation
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Upon acceptance, extend a personalized letter or email to leave a lasting, positive impression.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Delight applicants with a best-in-class admission experience!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" >
                                <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/fosterAnExceptional.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-attract-top-talent-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className=' section-title theme-font-bold'>
                                        Attract Top Talent
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                        How would you attract talented students to your institution? Run precision campaigns to engage with them.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Innovative Programs
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Highlight unique courses and supportive learning environments that attract high-achieving students.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Financial Support
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Inform eligible students about competitive scholarships, financial aid, and valuable career development services.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Showcase Past Triumphs
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Promote your institution's achievements and positive reputation to draw talented students.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/attractTopTalent.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-extracurricular-engagement-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-2 theme-font-bold'>
                                        Extracurricular Engagement Redefined
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal">
                                        Promote all-round student development in sports, arts, music, debates, and community service.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Campus Events Communication
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Effectively manage and target communication for campus events.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Club and Initiative Support
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Facilitate effective communication for various student clubs and initiatives.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Become the top choice for <span className="px-1 theme-text-tertiary">dynamic student life!</span>
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/extracurricular.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-organized-academic-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Organized Academic Life
                                    </h2>
                                    <h4 className="sub-section-title pb-40 pt-2 theme-font-normal">
                                        Unlock your academic potential with a seamless calendar of events: We've got the hard work covered!
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Streamlined Communication
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Effortlessly share class schedules, lab details, assignments, tests, and deadlines with students.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Stress-Free Re-Scheduling
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Eliminate last-minute changes that inconvenience students.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Announcement Hub
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Efficiently manage updates on research projects, study groups, and competitions.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Celebratory Newsletters
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Recognize and celebrate academic achievements through engaging newsletters.
                                    </h4>

                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/organisedAcademic.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-career-development-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Supercharge Career Development
                                    </h2>
                                    <h4 className="sub-section-title pb-40 pt-2 theme-font-normal">
                                        Maximize the potential of your career development program with proactive and timely communication.                              </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Networking Excellence
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Organize engaging networking events and job fairs.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        On-Campus Opportunities
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Facilitate on-campus interviews for students.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Internship Pathways
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Provide valuable internship programs.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Corporate Collaborations
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Forge strategic partnerships with corporate entities.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/superchargeCareer.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-money-matters-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-40 theme-font-bold'>
                                        Money Matters
                                    </h2>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Fee Payments
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Communicate effectively for student fee payments and send timely reminders for late payments.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Scholarships & Financial Aid
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Keep students informed about scholarships and financial aid opportunities.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Alumni Fundraising
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Manage communication for fundraising efforts with alumni.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0"  style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/education/moneyMatters.jpg'}
                                     className="img-fluid"
                                />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' padding-section-vertical secondary-banner-bg'
                             id={"education-secondary-footer-mobile"}>
                            <h4 className='theme-text-white mb-0 text-center theme-font-bold section-title' style={{paddingBottom:60}}>
                                Want to take Your Customer Outreach to the Next Level?
                            </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                      className="footer-sign-up-button">
                                        Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                <Link to={{
                                    pathname: '/contact-us',
                                    state: 'FROM_FREEDEMO'
                                }}>
                                    <button className="footer-free-demo-button">
                                            Get A Free Demo
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </section>

                </>
            )
        }

        return (
            <div>

                <section id={'education-banner-desktop'} className="section-padding-bottom">
                    <div className={isProdEnvironment ? "d-flex education-banner-bg-prod":"d-flex education-banner-bg"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container " style={{width: "65%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Student Outreach for <span className="theme-font-bolder"
                                                                style={{color: 'var(--tertiary)'}}> Education Institutes</span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales Teams
                                </h3>
                                <a href={"/contact-us"}>
                                    <button className="sign-up-button">
                                        Contact Us
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'education-brand-awareness-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/brandAwarness.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  '>
                                    Brand Awareness before Admissions
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    During the admission process, communicate your institution's brand awareness by highlighting its unique value proposition.
                                </h3>
                                <h3 className="section-sub-header  pt-2">
                                    Student Success Rates
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Celebrate achievements of your current and past students. Ignite the Dreams of Future Scholars.
                                </h4>
                                <h3 className="section-sub-header">
                                    Attract Talent with Innovation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Highlight distinctive innovative programs that set your institution apart.
                                </h4>
                                <h3 className="section-sub-header">
                                    Strengths Showcase
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Highlight your brand's strengths, including faculty expertise, facilities, and accreditations, to instil confidence.
                                </h4>
                                <h3 className="section-sub-header">
                                    Reputation Reinforcement
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Leverage your institution's strong reputation as a key selling point in your admissions communication strategy.
                                </h4>

                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Years of dedication have forged your institution's unique identity -
                                    <span className="px-1 theme-text-tertiary"> Flaunt It! </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'education-admission-journey-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Foster an Exceptional Admission Journey
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Effective communication during the admission process involves a multi-stage approach.
                                </h3>
                                <h3 className="section-sub-header">
                                    Welcome and Guidance
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Start with a warm welcome email and an informative FAQ sheet to assist applicants from the beginning.
                                </h4>
                                <h3 className="section-sub-header">
                                    Personalized Support
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Keep applicants engaged with tailored updates and timely reminders throughout the admission journey.
                                </h4>
                                <h3 className="section-sub-header">
                                    Acceptance Appreciation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Upon acceptance, extend a personalized letter or email to leave a lasting, positive impression.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Delight applicants with a best-in-class admission experience!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/fosterAnExceptional.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'education-attract-top-talent-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/attractTopTalent.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className=' section-title '>
                                    Attract Top Talent
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal pt-2">
                                    How would you attract talented students to your institution? Run precision campaigns to engage with them.
                                </h4>
                                <h3 className="section-sub-header">
                                    Innovative Programs
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Highlight unique courses and supportive learning environments that attract high-achieving students.
                                </h4>
                                <h3 className="section-sub-header">
                                    Financial Support
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Inform eligible students about competitive scholarships, financial aid, and valuable career development services.
                                </h4>
                                <h3 className="section-sub-header">
                                    Showcase Past Triumphs
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2">
                                    Promote your institution's achievements and positive reputation to draw talented students.                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'education-extracurricular-engagement-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    Extracurricular Engagement Redefined
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Promote all-round student development in sports, arts, music, debates, and community service.
                                </h4>
                                <h3 className="section-sub-header">
                                    Campus Events Communication
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Effectively manage and target communication for campus events.
                                </h4>
                                <h3 className="section-sub-header">
                                    Club and Initiative Support
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 paragraph-padding">
                                    Facilitate effective communication for various student clubs and initiatives.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Become the top choice for <span className="px-1 theme-text-tertiary">dynamic student life!</span>
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/extracurricular.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'education-organized-academic-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/organisedAcademic.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Organized Academic Life
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Unlock your academic potential with a seamless calendar of events: We've got the hard work covered!
                                </h4>
                                <h3 className="section-sub-header">
                                    Streamlined Communication
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Effortlessly share class schedules, lab details, assignments, tests, and deadlines with students.
                                </h4>
                                <h3 className="section-sub-header">
                                    Stress-Free Re-Scheduling
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Eliminate last-minute changes that inconvenience students.
                                </h4>
                                <h3 className="section-sub-header">
                                    Announcement Hub
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Efficiently manage updates on research projects, study groups, and competitions.
                                </h4>
                                <h3 className="section-sub-header">
                                    Celebratory Newsletters
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Recognize and celebrate academic achievements through engaging newsletters.
                                </h4>

                            </div>
                        </div>
                    </div>
                </section>

                <section id={'education-career-development-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Supercharge Career Development
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    Maximize the potential of your career development program with proactive and timely communication.                               </h4>
                                <h3 className="section-sub-header">
                                    Networking Excellence
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Organize engaging networking events and job fairs.
                                </h4>
                                <h3 className="section-sub-header">
                                    On-Campus Opportunities
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Facilitate on-campus interviews for students.
                                </h4>
                                <h3 className="section-sub-header">
                                    Internship Pathways
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Provide valuable internship programs.
                                </h4>
                                <h3 className="section-sub-header">
                                    Corporate Collaborations
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Forge strategic partnerships with corporate entities.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/superchargeCareer.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'education-money-matters-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/education/moneyMatters.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black paragraph-padding'>
                                    Money Matters
                                </h2>
                                <h3 className="section-sub-header">
                                    Fee Payments
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Communicate effectively for student fee payments and send timely reminders for late payments.
                                </h4>
                                <h3 className="section-sub-header">
                                    Scholarships & Financial Aid
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Keep students informed about scholarships and financial aid opportunities.
                                </h4>
                                <h3 className="section-sub-header">
                                    Alumni Fundraising
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Manage communication for fundraising efforts with alumni.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"education-secondary-footer-desktop"}>
                        <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };

    return (
        <div className="w-100">
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(Education));
