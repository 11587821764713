import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import './menuBar.css'
import {redirectTo} from "../../../utils/helper";
import Hamburger from "./hamburger";
import './header.css'
import appTheme from "../../../assets/appTheme";
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from  "../../../assets/img/SCuiDOLogo.jpg"

function Header(props) {
    const [isOpenFeature, setisOpenFeature] = useState(false);
    const [isOpenIndustries, setIsOpenIndustries] = useState(false);
    const [openSideMenuBar, setOpenSideMenuBar] = useState(false);

    const ref = useRef();
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("header").style.borderBottom = "0.50px solid #1671DD";
        } else {
            document.getElementById("header").style.borderBottom = "none";
        }
    }

    const handleItemOpenFeature = () => {
        setisOpenFeature(!isOpenFeature);
        handleItemCloseIndustries()
    };

    const handleItemCloseFeature = () => {
        setisOpenFeature(false)
    };
    const handleItemOpenIndustries = () => {
        setIsOpenIndustries(!isOpenIndustries);
        handleItemCloseFeature()
    };

    const handleItemCloseIndustries = () => {
        setIsOpenIndustries(false)
    };

    const handleHamburgerClick = () => {
        setOpenSideMenuBar(!openSideMenuBar)
    };

    const renderFeatures = () => {
        return (
            <div>
                <div className="use-cases-dropdown" >
                    <Dropdown>
                        <Dropdown.Toggle>
                            <div style={{cursor: 'pointer'}}
                                 className='theme-font-semiBold  theme-text-black label cursor'>
                                <span style={{marginBottom:10}}> Features </span>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <li style={{paddingTop: 6}}>
                                    <Link to={"/features/data-setup"}
                                          className='actionable-link theme-text-primary'
                                          onClick={() => {
                                              handleItemCloseFeature()
                                          }}>
                                        <span className={currentPath === "/features/data-setup" ? 'feature-menu-selected' : ''}>Data Setup</span>
                                    </Link>
                                </li>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/features/segmentation"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseFeature()
                                      }}>
                                    <span className={currentPath === "/features/segmentation" ? 'feature-menu-selected' : ''}>Segmentation</span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/features/campaigns"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseFeature()
                                      }}>
                                    <span className={currentPath === "/features/campaigns" ? 'feature-menu-selected' : ''}>Campaign Management</span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <li style={{paddingBottom: 6}}>
                                    <Link to={"/features/email-templates"}
                                          className='actionable-link theme-text-primary'
                                          onClick={() => {
                                              handleItemCloseFeature()
                                          }}>
                                        <span className={currentPath === "/features/email-templates" ? 'feature-menu-selected' : ''}>Email Templates</span>
                                    </Link>
                                </li>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    };
    const renderIndustries = () => {
        return (
            <div>
                <div className="use-cases-dropdown" >
                    <Dropdown>
                        <Dropdown.Toggle>
                            <div style={{cursor: 'pointer'}}
                                 className='theme-font-semiBold  theme-text-black label cursor'>
                                <span style={{marginBottom:10}}> Industries </span>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <li style={{paddingTop: 6}}>
                                    <Link to={"/industries/e-commerce"}
                                          className='actionable-link theme-text-primary'
                                          onClick={() => {
                                              handleItemCloseIndustries()
                                          }}>
                                        <span className={currentPath === "/industries/e-commerce" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>DTC Products / E-commerce</span>
                                    </Link>
                                </li>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/industries/dtc-services"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseIndustries()
                                      }}>
                                            <span
                                                className={currentPath === "/industries/dtc-services" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>DTC Services</span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/industries/education"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseIndustries()
                                      }}>
                                            <span
                                                className={currentPath === "/industries/education" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>
                                                 Education Institutes
                                            </span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/industries/financial-services"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseIndustries()
                                      }}>
                                            <span className={currentPath === "/industries/financial-services" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>
                                                Financial Services
                                            </span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to={"/industries/real-estate"}
                                      className='actionable-link theme-text-primary'
                                      onClick={() => {
                                          handleItemCloseIndustries()
                                      }}>
                                            <span className={currentPath === "/industries/real-estate" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>
                                                 Real Estate
                                            </span>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <li style={{paddingBottom: 6}}>
                                    <Link to={"/industries/home-services"}
                                          className='actionable-link theme-text-primary'
                                          onClick={() => {
                                              handleItemCloseIndustries()
                                          }}>
                                            <span className={currentPath === "/industries/home-services" ? 'usecases-menu-selected actionable-link' : 'actionable-link'}>
                                                  Home Services
                                            </span>
                                    </Link>
                                </li>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    };

    const currentPath = (props.location.pathname);
    return (
        <div id={'header'}
             className='w-100 theme-bg-white position-fixed top-0' style={{zIndex: 999}}>
            {/*header Deskstop*/}
            <div className="d-lg-block d-none">
                <div className="d-flex justify-content-between align-items-center block-container"
                     style={{height: "80px"}}>
                    <div className='cursor'>
                            <img src={Logo}
                                 style={{height: '35px'}}
                                 onClick={() => redirectTo('/')}
                                 alt={''}/>
                    </div>

                    <div id='app-header-nav' className="d-flex align-items-center" style={{gap: "50px"}} >
                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            {renderFeatures()}
                        </div>
                        <div className="d-flex flex-column align-items-center app-header-nav-menu">
                            {renderIndustries()}
                        </div>
                        <div className="d-flex flex-column align-items-center cursor app-header-nav-menu">
                            <Link to={"/pricing"}
                                  className='theme-font-semiBold theme-text-black label'>
                                Pricing
                            </Link>
                        </div>
                    </div>

                    <div className="d-flex" style={{gap: "20px"}}>
                        <div>
                            <button onClick={() => {
                                    redirectTo('/app/user/register')
                                }}
                                style={{
                                    backgroundColor: 'var(--primary)',
                                    color: 'white',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    minWidth: 130,
                                    height: 40,
                                    textTransform: 'initial',
                                    borderRadius: 10,
                                    borderColor: '#E18600',
                                    borderWidth: '2px',
                                    lineHeight: "22px",
                                    border: 'none'
                                }}>
                                <div style={{paddingRight: '10px', paddingLeft: '10px'}}>Sign Up</div>
                            </button>
                        </div>
                        <div className=" d-flex justify-content-end">
                            <button
                                onClick={() => {
                                    redirectTo('/contact-us')
                                }}
                                style={{
                                    color: 'var(--primary)',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    textTransform: 'initial',
                                    minWidth: 130,
                                    height: 40,
                                    borderRadius: 10,
                                    lineHeight: "22px",
                                    backgroundColor: 'white',
                                    border: '2px solid var(--primary)'
                                }}>
                                <div style={{paddingRight: '10px', paddingLeft: '10px'}}>Get in Touch</div>
                            </button>
                        </div>
                        <div className="d-flex align-items-center  "
                             style={{marginTop: -5}}>
                            <a className='theme-font-content sign-in-hover theme-font-semiBold'
                               style={{color:'var(--primary)'}}
                               href={'/app/user/login'}>Sign In </a>
                        </div>
                    </div>
                </div>
            </div>

            {/*header Mobile*/}
            <div className="d-flex d-lg-none w-100" >
                <div className='w-100'>
                    <div className="d-flex justify-content-between align-items-center block-container"
                         style={{height: "50px"}}>

                        <div className="px-lg-3 px-0 scuido-label-mobile cursor">
                            <img src={Logo}
                                 style={{height: '35px'}}
                                 onClick={() => props.history.push('/')}
                                 className='img-fluid  py-1 cursor'
                                 alt={''}/>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{
                                textDecoration: "underline",
                                color: 'var(--tertiary)'
                            }}>
                                <button
                                    onClick={() => {
                                        redirectTo('/app/user/register')
                                    }}
                                    style={{
                                        backgroundColor: 'var(--primary)',
                                        color: 'var(--white)',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        height: 36,
                                        textTransform: 'initial',
                                        borderRadius: 10,
                                        borderColor: '#E18600',
                                        borderWidth: '2px',
                                        lineHeight: "22px",
                                        border: 'none'
                                    }}>
                                    <div style={{paddingRight: '14px', paddingLeft: '14px'}}>Sign Up</div>
                                </button>

                            </div>
                            <div className='ms-3'>
                                <i className="fa-solid fa-bars theme-text-primary" style={{fontSize: 22}}
                                   onClick={() => handleHamburgerClick()}/>
                            </div>
                        </div>
                    </div>

                    {openSideMenuBar &&
                    <div className=' w-100 d-flex justify-content-end'>
                        <Hamburger {...props}
                                   handleHamburgerClick={handleHamburgerClick}
                                   useOnClickOutSide={useOnClickOutSide}
                        />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withRouter(Header));
