import React, {useEffect} from "react";
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {redirectTo} from "../../utils/helper";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";

function DtcSubscriptionServices(props) {
    const {isMobileView,isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'dtc-banner-mobile'} className="section-padding-bottom">
                        <div className={isProdEnvironment ? "d-flex dtc-saas-service-banner-bg-prod banner-bg-height py-5" : "d-flex dtc-saas-service-banner-bg banner-bg-height py-5"}>
                            <div className="d-flex align-items-center block-container ">
                                <div>
                                    <h1 className='page-title theme-font-semiBold '>
                                        Customer Outreach for
                                        <br/>
                                        <span className="theme-font-bolder px-1"
                                              style={{color: 'var(--tertiary)',}}>
                                            Direct-To-Consumer Services
                                        </span>
                                    </h1>
                                    <h3 className="py-4 section-header theme-font-normal"
                                        style={{width: (isXSView ? "" : "60%")}}>
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and
                                        Sales Teams
                                    </h3>
                                    <button className="sign-up-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                        Contact Us
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-free-users-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Convert Free Users to Paying Customers
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                        For <b> Freemium model </b>, converting free users to paying customers is tough! They're used to the free
                                        product.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Understand User Behavior
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Analyze usage patterns to gain insights into free users' preferences and
                                        habits.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Run Drip Campaigns
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Implement targeted drip campaigns to gradually introduce paid features and
                                        benefits, creating allure.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Offer Irresistible Deals
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Offer time-limited promotions and discounts to make the transition to paid
                                        subscriptions irresistible.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Tap into your biggest revenue source!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/convertFree.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-distinctive-service-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className=' section-title theme-font-bold'>
                                        Cultivate a Distinctive Service Identity
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        Businesses need to differentiate their product to stand out in the crowded marketplace and attract
                                        and retain users.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Differentiate Your Product
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Set your business apart by offering unique features and value.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Tailored Engagement
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Engage users based on their subscription plan, usage patterns, and
                                        familiarity with your services.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Educate Novice Users
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Run educational content to guide newcomers and seamlessly
                                        integrate them into your platform.
                                    </h4>

                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Rise above your competitors!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/cultivateA.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-guidance-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center w-100" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Offer Personalized Guidance from Day One!
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        Don't leave success to chance. While your features may seem intuitive, let's face it - users may need
                                        a helping hand.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Empower from Day One
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Help users conquer challenges and embrace success right from the start.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Tailored Email Series
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Craft expertly tailored emails to elevate the user experience.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Unlock Potential
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Provide step-by-step guidance and insider tips to maximize SaaS product benefits.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" >
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/offerPersonalized.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-loyal-customer-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-40 '>
                                        Early Access to Loyal Customers
                                    </h2>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Customer Activity Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Harness the power of user interactions to understand your most
                                        engaged customers.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Exclusive Early Access
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Privilege your top users with invite-only access to new features,
                                        making them feel special.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Valuable Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Engage with these customers to gain valuable feedback and insights for
                                        product improvement.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Long-Term Champions
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Transform engaged users into long-term advocates and champions
                                        of your product.
                                    </h4>

                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/earlyAccess.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-usage-drops-mobile'}
                             className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-40 theme-font-bold'>
                                        Usage Drops? Act Fast!
                                    </h2>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Early Warning Signs
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Reduction in usage can be a warning sign of SaaS troubles. It may indicate
                                        dissatisfaction, a shift in needs, or the presence of a competing solution.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Proactive Measures
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Take action promptly by identifying users showing reduced or stopped usage of
                                        your services.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Feedback Collection
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Reach out with surveys to understand their concerns and gather valuable
                                        feedback.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        By proactive, prevent customer churn!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/usuageDrops.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dtc-upselling-mobile'} className="section-padding-bottom">
                        <div className='d-flex flex-column'>
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold pb-40'>
                                        Uncover Upselling Opportunities
                                    </h2>
                                    <h3 className="section-sub-header pt-2">
                                        Identify Top Performers
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Discover users exceeding a specific threshold in performance.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Irresistible Upgrade Options
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Offer enticing upgrades to longer duration or higher usage plans.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Cost Reduction
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Allow valuable customers to reduce costs on existing features.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Unlock Enhanced Features
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Offer access to enhanced features and extra benefits for a small
                                        incremental fee.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Savor the Excitement of Upselling Triumph!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" style={{flex: 1}}>
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/dtc/uncoverUpselling.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' padding-section-vertical secondary-banner-bg'
                             id={"dtc-secondary-footer-mobile"}>
                        <h4 className='theme-text-white mb-0 text-center theme-font-bold section-title' style={{paddingBottom:60}}>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                      className="footer-sign-up-button">
                                        Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                    <button  onClick={() => redirectTo('/contact-us')}
                                     className="footer-free-demo-button">
                                            Get A Free Demo
                                    </button>
                            </div>
                        </div>
                    </section>

                </>
            )
        }

        return (
            <div>

                <section id={'dtc-banner-desktop'} className="section-padding-bottom">
                    <div className={isProdEnvironment ?"d-flex dtc-saas-service-banner-bg-prod":"d-flex dtc-saas-service-banner-bg"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container " style={{width: "75%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer Outreach for
                                    <span className="theme-font-bolder px-1"
                                          style={{color: 'var(--tertiary)', whiteSpace:'nowrap'}}>
                                     Direct-To-Consumer Services
                                    </span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales
                                    Teams
                                </h3>
                                <button className="sign-up-button"
                                        onClick={() => redirectTo('/contact-us')}>
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'dtc-free-user-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/convertFree.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Convert Free Users to Paying Customers
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    For <b>Freemium model</b>, converting free users to paying customers is tough! They're used to the free
                                    product.
                                </h3>
                                <h3 className="section-sub-header  pt-2">
                                    Understand User Behavior
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Analyze usage patterns to gain insights into free users' preferences and
                                    habits.
                                </h4>
                                <h3 className="section-sub-header">
                                    Run Drip Campaigns
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Implement targeted drip campaigns to gradually introduce paid features and
                                    benefits, creating allure.
                                </h4>
                                <h3 className="section-sub-header">
                                    Offer Irresistible Deals
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer time-limited promotions and discounts to make the transition to paid
                                    subscriptions irresistible.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Tap into your biggest revenue source!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'dtc-distinctive-service-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className=' section-title '>
                                    Cultivate a Distinctive Service Identity
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Businesses need to differentiate their product to stand out in the crowded marketplace and attract
                                    and retain users.
                                </h3>
                                <h3 className="section-sub-header">
                                    Differentiate Your Product
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Set your business apart by offering unique features and value.
                                </h4>
                                <h3 className="section-sub-header">
                                    Tailored Engagement
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Engage users based on their subscription plan, usage patterns, and
                                    familiarity with your services.
                                </h4>
                                <h3 className="section-sub-header">
                                    Educate Novice Users
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Run educational content to guide newcomers and seamlessly
                                    integrate them into your platform.
                                </h4>

                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Rise above your competitors!
                                </h4>
                            </div>
                        </div>

                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/cultivateA.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'dtc-guidance-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/offerPersonalized.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Offer Personalized Guidance from Day One!
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Don't leave success to chance. While your features may seem intuitive, let's face it - users may need
                                    a helping hand.
                                </h3>
                                <h3 className="section-sub-header">
                                    Empower from Day One
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Help users conquer challenges and embrace success right from the start.
                                </h4>
                                <h3 className="section-sub-header">
                                    Tailored Email Series
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Craft expertly tailored emails to elevate the user experience.
                                </h4>

                                <h3 className="section-sub-header">
                                    Unlock Potential
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Provide step-by-step guidance and insider tips to maximize SaaS product benefits.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'dtc-early-access-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  pb-40'>
                                    Early Access to Loyal Customers
                                </h2>

                                <h3 className="section-sub-header">
                                    Customer Activity Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Harness the power of user interactions to understand your most
                                    engaged customers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Exclusive Early Access
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Privilege your top users with invite-only access to new features,
                                    making them feel special.
                                </h4>
                                <h3 className="section-sub-header">
                                    Valuable Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Engage with these customers to gain valuable feedback and insights for
                                    product improvement.
                                </h4>
                                <h3 className="section-sub-header">
                                    Long-Term Champions
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Transform engaged users into long-term advocates and champions
                                    of your product.
                                </h4>

                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/earlyAccess.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'dtc-usage-drops-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/usuageDrops.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  pb-40'>
                                    Usage Drops? Act Fast!
                                </h2>

                                <h3 className="section-sub-header">
                                    Early Warning Signs
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Reduction in usage can be a warning sign of SaaS troubles. It may indicate
                                    dissatisfaction, a shift in needs, or the presence of a competing solution.
                                </h4>
                                <h3 className="section-sub-header">
                                    Proactive Measures
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                  Take action promptly by identifying users showing reduced or stopped usage of
                                    your services.
                                </h4>
                                <h3 className="section-sub-header">
                                    Feedback Collection
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Reach out with surveys to understand their concerns and gather valuable
                                    feedback.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    By proactive, prevent customer churn!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'dtc-uncover-upselling-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  pb-40'>
                                    Uncover Upselling Opportunities
                                </h2>
                                <h3 className="section-sub-header">
                                    Identify Top Performers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Discover users exceeding a specific threshold in performance.
                                </h4>
                                <h3 className="section-sub-header">
                                    Irresistible Upgrade Options
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer enticing upgrades to longer duration or higher usage plans.
                                </h4>
                                <h3 className="section-sub-header">
                                    Cost Reduction
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Allow valuable customers to reduce costs on existing features.
                                </h4>
                                <h3 className="section-sub-header">
                                    Unlock Enhanced Features
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Offer access to enhanced features and extra benefits for a small
                                    incremental fee.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Savor the Excitement of Upselling Triumph!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/dtc/uncoverUpselling.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"dtc-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>

        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(DtcSubscriptionServices));

