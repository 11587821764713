import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import "./menuBar.css"

function Hamburger(props) {
    const {handleHamburgerClick} = props;
    const [isFeatureOpen, setIsFeatureOpen] = useState(false);
    const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);
    const ref = useRef();
    const currentPath = (props.location.pathname);

    const handleFeatureClick = () => {
        setIsFeatureOpen(!isFeatureOpen)
    };

    const handleIndustriesClick = () => {
        setIsIndustriesOpen(!isIndustriesOpen)
    };

    useOnClickOutSide(ref, () => {
        handleHamburgerClick()
    });

    return (
        <div className='theme-bg-white position-fixed h-100 position-fixed top-0'
             style={{
                 width: 300,
                 height: 500
             }}
             ref={ref}>
            <div className='pt-3'>
                <div style={{borderBottom: '1px solid #978C8C'}}>
                    <div className='d-flex align-items-center pb-3 '
                         onClick={() => handleFeatureClick(!isFeatureOpen)}>
                        {isFeatureOpen ?
                            <i className="fa-solid fa-angle-up mx-3 theme-text-primary"
                               style={{fontSize: 'var(--font-size-md)'}}/>
                            :
                            <i className="fa-solid fa-angle-down mx-3 theme-text-primary"
                               style={{fontSize: 'var(--font-size-md)'}}/>
                        }
                        <div className='accordian-title theme-text-primary theme-font-semiBold'> Features</div>
                    </div>
                    {isFeatureOpen &&
                    <div className='d-flex flex-column'
                         style={{paddingLeft: 50}}>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleFeatureClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link className={currentPath === "/features/data-setup" ? 'feature-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/features/data-setup'}>
                                    Data Setup
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleFeatureClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link className={currentPath === "/features/segmentation" ? 'feature-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/features/segmentation'}>
                                    Segmentation
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleFeatureClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link className={currentPath === "/features/campaigns" ? 'feature-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/features/campaigns'}>
                                    Campaign Management
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div onClick={() => {
                                handleFeatureClick();
                                handleHamburgerClick()
                            }}
                                 className='cursor'>
                                <Link className={currentPath === "/features/email-templates" ? 'feature-menu-selected menu-font-child-item theme-text-primary' : ' menu-font-child-item theme-text-primary'}
                                    to={'/features/email-templates'}>
                                    Email Templates
                                </Link>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                {/*Industries*/}
                <div className='mt-3'
                     style={{borderBottom: '1px solid #978C8C'}}>
                    <div className='d-flex align-items-center pb-3'
                         onClick={() => handleIndustriesClick(!isIndustriesOpen)}>
                        {isIndustriesOpen ?
                            <i className="fa-solid fa-angle-up mx-3 theme-text-primary"
                               style={{fontSize: 'var(--font-size-md)'}}/>
                            :
                            <i className="fa-solid fa-angle-down mx-3 theme-text-primary"
                               style={{fontSize: 'var(--font-size-md)'}}/>
                        }
                        <div className='accordian-title theme-text-primary theme-font-semiBold'> Industries</div>
                    </div>
                    {isIndustriesOpen &&
                    <div className='d-flex flex-column' style={{paddingLeft: 50}}>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleIndustriesClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link
                                    className={currentPath === "/industries/e-commerce" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/e-commerce'}>
                                    DTC Products / E-commerce
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleIndustriesClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link
                                    className={currentPath === "/industries/dtc-services" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/dtc-services'}>
                                    DTC Services
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div onClick={() => {
                                handleIndustriesClick();
                                handleHamburgerClick()
                            }}
                                 className='cursor'>
                                <Link
                                    className={currentPath === "/industries/education" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/education'}>
                                    Education Institutes
                                </Link>
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div className='cursor'
                                 onClick={() => {
                                     handleIndustriesClick();
                                     handleHamburgerClick()
                                 }}>
                                <Link
                                    className={currentPath === "/industries/financial-services" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/financial-services'}>
                                    Financial Services
                                </Link>
                            </div>
                        </div>


                        <div className='pb-3'>
                            <div onClick={() => {
                                handleIndustriesClick();
                                handleHamburgerClick()
                            }}
                                 className='cursor'>
                                <Link className={currentPath === "/industries/real-estate" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/real-estate'}>
                                    Real Estate
                                </Link>
                            </div>
                        </div>

                        <div className='pb-3'>
                            <div onClick={() => {
                                handleIndustriesClick();
                                handleHamburgerClick()
                            }}
                                 className='cursor'>
                                <Link className={currentPath === "/industries/home-services" ? 'usecases-menu-selected menu-font-child-item theme-text-primary' : 'menu-font-child-item theme-text-primary'}
                                    to={'/industries/home-services'}>
                                    Home Services
                                </Link>
                            </div>
                        </div>

                    </div>
                    }
                </div>
                <div>
                    <div className='py-3'
                         style={{
                             paddingLeft: 47,
                             paddingRight: 34,
                             borderBottom: '1px solid #978C8C'
                         }}
                         onClick={() => {handleHamburgerClick()}}>
                        <Link className='actionable-link label theme-text-primary theme-font-semiBold' to={'/pricing'}>
                            Pricing
                        </Link>
                    </div>
                    <div className='py-3'
                         style={{
                             paddingLeft: 47,
                             paddingRight: 34,
                             borderBottom: '1px solid #978C8C'
                         }}
                         onClick={() => {
                             handleHamburgerClick()
                         }}>
                        <a className='actionable-link label theme-text-primary theme-font-semiBold'
                           href={'/app/user/login'}>
                            Sign In
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default (Hamburger);
