import React, {useEffect, useState} from 'react';
import PricingView from "./PricingView";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {refreshWindowScrollElement} from "../../utils/helper";
import {getServiceDetailsForPreLogin} from "../userActions";
import {isEmpty} from "../../utils/validations";

function Pricing(props) {

    const [subscriptionsState, setSubscriptions] = useState({
        removePadding: true,
        UIState: '',
        services: [],
        subscriptions: [],
        code: '',
        name: '',
        description: '',
        permissions: [],
        plans: [],
        hasPaidSubscription: false,
        isYearly: true,
        isApiLoadedPRO: false,
        isApiLoadedBusiness: false,
        isApiLoadedEnterprise: false,
        isApiLoadedBasic: false,
        action: '',
        accountId: '',
        expiredAccountAction: '',
    });

    const setSubscriptionDetails = (planData) => {
        if (isEmpty(planData)) {
            return
        }

        setSubscriptions(subscriptionsState => ({
            ...subscriptionsState,
            permissions: planData?.permissions,
            plans: planData?.plans
        }));
    };

    const handleYearToMonthChange = () => {
        const {isYearly} = subscriptionsState;
        setSubscriptions(subscriptionsState => ({
            ...subscriptionsState,
            isYearly: !isYearly
        }));

    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // for setting page position to be on the top
        refreshWindowScrollElement();
        getServiceDetailsForPreLogin('SCUIDO')
            .then((res) => {
                if (res.success) {
                    if (!isEmpty(res.data)) {
                        setSubscriptionDetails(res.data);
                        setSubscriptions(subscriptionsState => ({
                            ...subscriptionsState,
                            UIState: 'SUBSCRIPTION_UI'
                        }));
                    } else {
                        setSubscriptions(subscriptionsState => ({
                            ...subscriptionsState,
                            UIState: 'CONTENT_NOT_AVAILABLE'
                        }));
                    }
                }
            })
    }, []);

    return (
        <PricingView {...props}
                     {...subscriptionsState}
                     handleYearToMonthChange={handleYearToMonthChange}/>
    );
}

Pricing.propTypes = {};

const mapStateToProps = (state) => ({
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
    isXLView: state.appState.deviceInfo.isXLView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Pricing));
