import {showSystemErrorBlock, showToastMessage} from "../../core/actions/appActions";
import store from "../../store";
import {httpGet, httpPost} from "../../utils/httpHelper";

export const BetaRequestQuestions = (obj) => {

    let url = '/service/provisioning/betaRequest/beta/question';

    return httpGet(url)
        .then(res => {
            return res;
        })
};

export const preLoginBetaRequest = (obj) => {

    let url = '/service/provisioning/betaRequest/prelogin';

    return httpPost(url, obj)
        .then(res => {
            return res;
        })
};

export const postLoginBetaRequest = (obj) => {

    let url = '/service/provisioning/subscriptionRequest/postlogin';

    return httpPost(url, obj)
        .then(res => {
            switch (res?.statusCode) {
                case 201:
                case 204:
                case 400:
                case 409:
                    return res;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
        })
        .catch((err) => {
            store.dispatch(showSystemErrorBlock());
        })
};