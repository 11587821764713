import React, {createRef, useEffect} from 'react';
import {isEmpty} from "../../utils/validations";
import {getRandomId} from "../../utils/helper";
import './betaRequest.css'
import appTheme from "../../assets/appTheme";
import Footer from "../../core/layout/footer/footer";

let scrollTo = {
    firstName: createRef(),
    email: createRef(),
    mobile: createRef(),
    website: createRef()
};

function BetaRequestView(props) {
    useEffect(() => {
        if (scrollTo[props.scrollToElement]?.current) {
            let topOffset = 120;
            let element = scrollTo[props.scrollToElement].current;

            if (element) {
                if (Math.abs(element.getBoundingClientRect().top) < topOffset + 3) {
                    // dont scroll already on top
                } else {
                    window.focus();
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - topOffset,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [props.scrollToElement]);

    const {questions, firstNameError, websiteError, onBlurInputText, handleSubmit, emailError, mobileError, linkedInError, handleChange, handleCheckBoxClick} = props;

    const getCheckedData = (questionId, answer) => {
        const {questionAnswers} = props;
        if (isEmpty(questionAnswers)) {
            return false
        }

        let main_question = questionAnswers.find((item) => {
            return item.questionId === questionId
        });

        if (isEmpty(main_question)) {
            return false
        }

        let findSol = main_question.answers.find((item) => {
            return item.label === answer
        });

        return findSol?.isChecked;

    };

    const getRadioCheckedData = (questionId, answer) => {
        const {questionAnswers} = props;
        if (isEmpty(questionAnswers)) {
            return false
        }

        let main_question = questionAnswers.find((item) => {
            return item.questionId === questionId
        });

        if (isEmpty(main_question)) {
            return false
        }

        let findSol = main_question.answers.find((item) => {
            return item.label === answer
        });

        return findSol?.label;

    };

    const renderCheckBoxUI = (questions,questionIndex) => {
        return questions.BetaAnswerOptions.map((option,) => {
            return (
                <div key={getRandomId()}>
                    <div className={'d-flex'}>
                        <div className={'theme-font-content theme-text-black theme-font-normal'}
                            style={{marginBottom: '8px'}}>
                            {questionIndex === 0 ?
                                <input type="checkbox"
                                       checked={getCheckedData(questions.id, option.answer)}
                                       onChange={() => handleCheckBoxClick(option.answer, questions.id)}
                                       className="form-check-input cursor"
                                       style={{
                                           height: 25,
                                           width: 25,
                                           border: '2px solid',
                                           backgroundColor: getCheckedData(questions.id, option.answer) && appTheme.appColor.primary
                                       }}
                                />
                                :
                                <div className='ps-1' style={{maxHeight:'24px'}}>
                                    <input type="radio"
                                           name={questionIndex}
                                           // onChange={() => handleCheckBoxClick(option.answer, questions.id)}
                                           className='mt-2 beta-input-button cursor'
                                           style={{
                                               height:15,
                                               width:15
                                           }}
                                           onClick={()=>{handleCheckBoxClick(option.answer, questions.id)}}
                                           checked={getRadioCheckedData(questions.id, option.answer)}
                                    />
                                    <br/>
                                </div>
                            }
                        </div>
                        <div className={'theme-font-content theme-text-black theme-font-normal ps-2 pt-1'}
                            htmlFor={questions.id}>
                            {option.answer}
                        </div>
                    </div>
                </div>

            )
        })
    };

    const renderQuestions = () => {
        return questions.map((questions,questionIndex) => {
            return (
                <li key={getRandomId()} className='mb-3'>
                    <div className='theme-font-content theme-text-black theme-font-normal mb-2'>
                        {questions.question}
                    </div>
                    {renderCheckBoxUI(questions,questionIndex)}
                </li>
            )

        })
    };

    const questionsUi = () => {
        if (isEmpty(questions)) {
            return <></>
        }

        return (
            <div>
                <div className='theme-font-content theme-text-black theme-font-semiBold my-2'>
                    Select all options that are applicable to you
                </div>
                <ol style={{marginBottom: '30px', paddingLeft: 14}}>
                    {renderQuestions()}
                </ol>
            </div>
        );
    };

    return (
        <div id={'formData'}>
            <div className="block-container">
                <div className="theme-font-section-title theme-text-tertiary d-flex align-items-center">
                    <i className="fa-solid fa-chevron-left me-2 cursor"
                       style={{fontSize: '16px', color: appTheme.appColor.primary, fontWeight: 'bold', paddingTop:4}}
                       onClick={() => props.history.goBack()}/>
                       SCuiDO Beta Testing Invite Request
                </div>
            </div>
            <div className="block-container mt-4">
                <div>
                    <div className='theme-font-content theme-text-black theme-font-normal'
                         style={{marginBottom: '20px'}}>
                        We're thrilled about our innovative product, and our mission is to create a solution that
                        empowers customers to manage their campaigns with unparalleled precision and control.
                        Join us in revolutionizing the way campaigns are run!
                    </div>
                    <div className='theme-font-content theme-text-black theme-font-semiBold'
                         style={{marginBottom: '10px'}}>
                        In this beta, you'll get to
                    </div>
                    <ul className='theme-font-content theme-text-black theme-font-normal'
                        style={{marginBottom: '20px'}}>
                        <li>
                            <div>
                                Experiment with advanced hyper-targeting features, utilizing your own business
                                data to optimize campaigns
                            </div>
                        </li>
                        <li>
                            <div>
                                Engage directly with our dedicated product team, providing invaluable feedback
                            </div>
                        </li>
                        <li>
                            <div>
                                Play an influential role in molding SCuiDO's future for users everywhere
                            </div>
                        </li>
                    </ul>
                    <div className="row ">
                        <div className="col-md-12 col-12 theme-font-content theme-text-black theme-font-semiBold"
                            style={{marginBottom: '25px'}}>
                            Kindly fill out this form to request access. We'll be sending out invites
                            gradually over time. Once granted access, you'll receive an email containing instructions on
                            how to begin your journey.
                        </div>
                        <div id={'firstName-label'}
                             className="col-md-5 col-12"
                             ref={scrollTo['firstName']}>
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold'
                                      style={{marginBottom: '4px',
                                      color: (firstNameError ? appTheme.appColor.danger : appTheme.appColor.black)}}>
                                      First Name <span className='theme-text-error'>*</span>
                                </span>
                                <input id={'firstName'}
                                       onChange={(value) => {handleChange('firstName', value.target.value)}}
                                       type="text"
                                       style={{
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderColor: (firstNameError ? appTheme.appColor.danger : ' rgba(0, 0, 0, 0.23)'),
                                        borderRadius: '8px',
                                        padding: '7px 14px',
                                        width: '100%',
                                        backgroundColor: '#F5F5F5',
                                        fontWeight: '500',
                                        height: '35px',
                                        color: appTheme.appColor.black}}
                                    onBlur={() => onBlurInputText('firstName')}
                                    placeholder={" "}
                                    maxLength={31}
                                />
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                      style={{paddingLeft: '10px',
                                          minHeight: '19px',}}>
                                <span>{firstNameError !== '' && firstNameError}</span></span>
                            </div>
                        </div>

                        <div className="col-md-5 offset-md-1 col-12">
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold theme-text-black'
                                      style={{marginBottom: '4px'}}>
                                    Last Name
                                </span>
                                <input id={'lastName'}
                                       onChange={(value) => {handleChange('lastName', value.target.value)}}
                                       type="text"
                                       style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderColor: ' rgba(0, 0, 0, 0.23)',
                                        borderRadius: '8px',
                                        padding: '7px 14px',
                                        height: '35px',
                                        width: '100%',
                                        backgroundColor: '#F5F5F5',
                                        fontWeight: '500',
                                        color: appTheme.appColor.black}}
                                        placeholder={" "}
                                       onBlur={() => onBlurInputText('lastName')}
                                       maxLength={31}/>
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                    style={{paddingLeft: '10px', minHeight: '19px'}}>
                                    <span/>
                                </span>
                            </div>
                        </div>
                        <div id={'email-label'}
                             className="col-md-5 col-12"
                             ref={scrollTo['email']}>
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold'
                                      style={{marginBottom: '4px',
                                              color: (emailError ? appTheme.appColor.danger : appTheme.appColor.black)}}>
                                    Email<span className='theme-text-error'>*</span>
                                </span>
                                <input onChange={(value) => {handleChange('email', value.target.value)}}
                                    id={'email'}
                                    type="text"
                                    style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderColor: (emailError ? appTheme.appColor.danger : ' rgba(0, 0, 0, 0.23)'),
                                        borderRadius: '8px',
                                        padding: '7px 14px',
                                        width: '100%',
                                        height: '35px',
                                        backgroundColor: '#F5F5F5',
                                        fontWeight: '500',
                                        color: appTheme.appColor.black}}
                                    placeholder={" "}
                                    onBlur={() => onBlurInputText('email')}
                                    maxLength={51}
                                />
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                    style={{paddingLeft: '10px',
                                        minHeight: '19px',}}>
                                    <span>{emailError !== '' && emailError}</span>
                                </span>
                            </div>
                        </div>
                        <div id={'mobile-label'}
                             className="col-md-5 offset-md-1 col-12"
                             ref={scrollTo['mobile']}>
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold'
                                      style={{marginBottom: '4px',
                                              color: (mobileError ? appTheme.appColor.danger : appTheme.appColor.black)}}>
                                       Mobile<span className='theme-text-error'>*</span>
                                </span>
                                <input onChange={(value) => {handleChange('mobile', value.target.value)}}
                                    id={'mobile'}
                                    type="text"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderColor: (mobileError ? appTheme.appColor.danger : ' rgba(0, 0, 0, 0.23)'),
                                        borderRadius: '8px',
                                        padding: '7px 14px',
                                        width: '100%',
                                        height: '35px',
                                        backgroundColor: '#F5F5F5',
                                        fontWeight: '500',
                                        color: appTheme.appColor.black
                                    }}
                                    onBlur={() => onBlurInputText('mobile')}
                                    placeholder={" "}
                                    maxLength={21}
                                />
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                    style={{paddingLeft: '1px',
                                        minHeight: '19px'}}>
                                        <span>{mobileError !== '' && mobileError}</span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-5 col-12" ref={scrollTo['website']}>
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold theme-text-black'
                                      style={{marginBottom: '4px'}}>
                                Website
                                </span>
                                <input onChange={(value) => {handleChange('website', value.target.value)}}
                                    id={'website'}
                                    type="text"
                                    style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderColor: ' rgba(0, 0, 0, 0.23)',
                                        borderRadius: '8px',
                                        padding: '7px 14px',
                                        width: '100%',
                                        height: '35px',
                                        backgroundColor: '#F5F5F5',
                                        fontWeight: '500',
                                        color: appTheme.appColor.black}}
                                    placeholder={" "}
                                    onBlur={() => onBlurInputText('website')}
                                    maxLength={51}/>
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                    style={{paddingLeft: '10px',
                                        minHeight: '19px'}}>
                                <span>{websiteError !== '' && websiteError}</span>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1 col-12">
                            <div className="py-2">
                                <span className='theme-inputBox-text-sm theme-font-semiBold theme-text-black'
                                      style={{marginBottom: '4px'}}>
                                    LinkedIn
                                </span>
                                <input onChange={(value) => {handleChange('linkedIn', value.target.value)}}
                                       id={'linkedIn'}
                                       type="text"
                                       style={{
                                           border: '1px solid rgba(0, 0, 0, 0.23)',
                                           borderColor: ' rgba(0, 0, 0, 0.23)',
                                           borderRadius: '8px',
                                           padding: '7px 14px',
                                           width: '100%',
                                           height: '35px',
                                           backgroundColor: '#F5F5F5',
                                           fontWeight: '500',
                                           color: appTheme.appColor.black
                                       }}
                                       placeholder={" "}
                                       maxLength={51}
                                       onBlur={() => onBlurInputText('linkedIn')}
                                />
                                <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                      style={{
                                          paddingLeft: '10px',
                                          minHeight: '19px'
                                      }}>
                                    <span>{linkedInError !== '' && linkedInError}</span>
                                    <span/>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-11 col-12 py-2 mt-3">
                                <span className='theme-inputBox-text-sm theme-font-semiBold theme-text-black'
                                      style={{marginBottom: '4px'}}>
                                   Is there anything particular you'd like to share about your interest in obtaining beta access?
                                </span>
                            <textarea onChange={(value) => {handleChange('reason', value.target.value)}}
                                id={'writtenQuestion'}
                                rows="4"
                                style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderColor: ' rgba(0, 0, 0, 0.23)',
                                    borderRadius: '8px',
                                    padding: '7px 14px',
                                    width: '100%',
                                    backgroundColor: '#F5F5F5',
                                    fontWeight: '500',
                                    color: appTheme.appColor.black,
                                    height: '75px'}}/>
                            <span className='d-flex justify-content-between theme-inputBox-text-xs theme-text-error'
                                style={{paddingLeft: '10px',
                                    minHeight: '19px'}}>
                                        <span/>
                            </span>
                        </div>
                    </div>
                    {questionsUi()}
                    <div className="d-flex justify-content-center justify-content-lg-start"
                         style={{paddingBottom: '30px'}}>
                        <button onClick={() => props.history.goBack()}
                                style={{
                                    backgroundColor: "white",
                                    color: appTheme.appColor.primary,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Barlow',
                                    textTransform: 'initial',
                                    borderRadius: 7,
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    height: "35px",
                                    border: '1px solid ' + appTheme.appColor.primary
                                }}>
                            Cancel
                        </button>
                        <button className="theme-bg-primary"
                                onClick={() => {handleSubmit()}}
                                style={{
                                    color: 'white',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontFamily: 'Barlow',
                                    textTransform: 'initial',
                                    borderRadius: 7,
                                    height: "35px",
                                    border: '1px solid ' + appTheme.appColor.primary,
                                    marginLeft: 20,
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                }}>
                            Request beta access
                        </button>
                    </div>
                </div>
            </div>
            <div className={"block-container"}>
                <div className="footer-border-light"/>
            </div>
            <Footer {...props}/>
        </div>
    );
}

export default BetaRequestView;
