import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../core/layout/footer/footer";
import {Link} from "react-router-dom";
import {redirectTo} from "../utils/helper";
import './home.css';
import appTheme from "../assets/appTheme";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";

const Home = (props) => {
    const {isMobileView, isLGView, isXSView, isSMView, isMDView} = props;
    const currentPath = (props.location.pathname);

    useEffect(() => {
        setTimeout(() => {
            if (isMobileView) {
                const fragment = window.location.hash.substr(1);
                if (fragment === 'industries') {
                    const targetElement = document.getElementById('industries-mob');
                    if (targetElement) {
                        targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                    }
                }

            } else {
                const fragment = window.location.hash.substr(1);
                if (fragment === 'industries') {
                    const targetElement = document.getElementById('industries-desk');
                    console.log('targetElement', targetElement);
                    if (targetElement) {
                        targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                    }
                }

            }

        }, 300)

    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'homepage-banner-mobile'}>
                        <div className={isProdEnvironment ? " BannerBg-prod d-flex align-items-center py-4" : "d-flex align-items-center BannerBg py-4"}>
                        {/*// style={{minHeight:isMDView ? resizeImage(769,470, 0 ): isSMView ? 600 : 600 }}>*/}
                            <div className="block-container">
                                <h1 className='page-title theme-font-Bold '>
                                    Next-Gen Customer <br/>Outreach for SMBs
                                </h1>
                                <h3 className="pt-3 pb-4 section-header theme-font-normal"
                                style={{width: isMobileView ? "65%" : ""}}>
                                    Create personalized messages across email, mobile push, whatsapp and SMS with
                                    <span style={{color: 'var(--tertiary)'}} className="px-1 theme-font-semiBold">your
                                        business data </span>
                                </h3>
                                <div className="d-flex ">
                                    <div>
                                        <button className="sign-up-button"
                                                onClick={() => {
                                                    redirectTo('/app/user/register')
                                                }}>
                                            Sign Up
                                        </button>
                                    </div>
                                    <div style={{marginLeft: 20}}>
                                        <button className="free-demo-button"
                                                onClick={() => {
                                                    redirectTo('/contact-us')
                                                }}>
                                            Get A Free Demo
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-brand-awareness-desktop'}
                             className="section-py">
                        <div className="block-container">
                            <h2 className='section-title theme-text-black pb-40 theme-font-bold'>
                                An End-To-End Customer Outreach Platform </h2>
                            <h3 className="sub-section-title theme-font-normal pb-40">
                                Streamline your hyper-targeted customer outreach right from data to delivery.
                                Effortlessly upload, analyze, segment, design, personalize, send, and track with ease.
                            </h3>
                            <div className="row">
                                <div className="col-md-4  pb-60 col-12">
                                    <div className="d-flex flex-column align-items-center">
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/homepage/setupData.png'}
                                             className="img-fluid"
                                             width={(isXSView || isSMView) ? "60%" : ''}
                                             alt={''}/>
                                    </div>
                                </div>
                                <div className="col-md-4  pb-60 col-12">
                                    <div className="d-flex flex-column align-items-center">
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/homepage/segmentYour.png'}
                                             width={(isXSView || isSMView) ? "60%" : ''}
                                             className="img-fluid" alt={''}/>
                                    </div>
                                </div>

                                <div className="col-md-4  pb-60 col-12">
                                    <div className="d-flex flex-column align-items-center">
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/homepage/designPersonalized.png'}
                                             width={(isXSView || isSMView) ? "60%" : ''}
                                             className="img-fluid"
                                             alt={''}/>
                                    </div>
                                </div>
                                <div className={(isSMView || isXSView) ? " pb-60  col-md-4 offset-md-2  col-12" : "col-md-4 offset-md-2  col-12"}>
                                    <div className="d-flex flex-column align-items-center">
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/homepage/reachOut.png'}
                                             className="img-fluid"
                                             width={(isXSView || isSMView) ? "60%" : ''}
                                             alt={''}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="d-flex flex-column align-items-center">
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/homepage/trackYour.png'}
                                             className="img-fluid"
                                             width={(isXSView || isSMView) ? "60%" : ''}
                                             alt={''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'home-services-email-marketing-mobile'}
                             className="row mx-0">
                        <div className='block-container section-py' style={{backgroundColor: '#F9FCFE'}}>
                            <div>
                                <h2 className='section-title theme-text-black pb-40 theme-font-bold'>
                                    Think Beyond Contact Lists!
                                </h2>
                                <h3 className="section-sub-header theme-font-normal">
                                    Your dataset, Your format
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Bring multiple datasets as are, dynamically add and link them without any
                                    restructuring or transformation.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Understand your customer better
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    SCuiDO allows you to consolidate and link data from multiple sources to provide you
                                    with a unified and granular view of your customer.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Limitless segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    With the ability to split the linked data into as many segments as you need, you can
                                    improve the scope and reach of your marketing initiatives.
                                </h4>
                                <div className="row mx-0">
                                    <div className="col-md-6 col-12 pe-0">
                                        <img src={s3BaseURL + '/images/homepage/dontLimitYour.jpg'}
                                             className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-attract-top-talent-desktop'}
                             className="block-container section-py  w-100 theme-font-bold">
                        <h2 className='section-title pb-40 theme-font-bold'>
                            Engage Better With Hyper-Targeting
                        </h2>
                        <h3 className="pb-40 section-header theme-font-normal">
                            Send messages your customers actually want to receive, directly to their preferred channel
                        </h3>
                        <div className="row mx-0">
                            <div className={(isSMView || isXSView) ? "col-md-3 col-sm-6 col-12 pb-60 " : "col-md-3 col-sm-6 col-12 "}>
                                <div className="d-flex justify-content-center ">
                                    <img loading="lazy"
                                         src={s3BaseURL + '/images/homepage/higherClick.png'}
                                         className="img-fluid"
                                         width={isXSView || isSMView ? "60%" : ''}
                                         alt={''}/>
                                </div>

                            </div>
                            <div className={(isSMView || isXSView) ? "col-md-3 col-sm-6 col-12 pb-60" : "col-md-3 col-sm-6 col-12"}>
                                <div className="d-flex justify-content-center">
                                    <img loading="lazy"
                                         src={s3BaseURL + '/images/homepage/improveMessage.png'}
                                         className="img-fluid"
                                         width={isXSView || isSMView ? "60%" : ''}
                                         alt={''}/>
                                </div>

                            </div>
                            <div className={(isSMView || isXSView) ? "col-md-3 col-sm-6 col-12 pb-60 " : "col-md-3 col-sm-6 col-12 "}>
                                <div className=" d-flex justify-content-center">
                                    <img loading="lazy"
                                         src={s3BaseURL + '/images/homepage/reduceUnsubscribes.png'}
                                         className="img-fluid"
                                         width={(isXSView || isSMView) ? "60%" : ''}
                                         alt={''}/>
                                </div>

                            </div>
                            <div className={"col-md-3 col-sm-6 col-12 "}>
                                <div className="d-flex justify-content-center">
                                    <img loading="lazy"
                                         src={s3BaseURL + '/images/homepage/avoidAbuse.png'}
                                         className="img-fluid"
                                         width={(isXSView || isSMView) ? "60%" : ''}
                                         alt={''}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'education-extracurricular-engagement-desktop'}
                             className="section-py "
                             style={{backgroundColor: '#F9FCFE'}}>
                        <div className="block-container">
                            <h2 className='section-title theme-text-black theme-font-bold pb-40'>
                                Elegant Message Templates
                            </h2>
                            <h3 className="pb-40 section-header theme-font-normal">
                                Send messages your customers actually want to receive, directly to their preferred
                                channel
                            </h3>

                            <h3 className="section-sub-header">
                                Expert Templates
                            </h3>
                            <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                Choose from professionally designed templates created by specialists.
                            </h4>
                            <h3 className="section-sub-header">
                                Customization
                            </h3>
                            <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                Personalize templates with your branding elements, such as colors, logos, fonts, and
                                images.
                            </h4>
                            <h3 className="section-sub-header">
                                Effortless Creation
                            </h3>
                            <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                Easily build new templates using the platform's intuitive drag-and-drop tools.
                            </h4>
                            <h3 className="section-sub-header">
                                Time-Efficient
                            </h3>
                            <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                Save time by customizing or creating templates in just minutes.
                            </h4>
                            <h4 className="section-footer-text pb-40">
                                Wow your audience!
                            </h4>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 pe-0">
                                <img src={s3BaseURL + '/images/homepage/elegantEmailTemplate.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'email-campaign-mobile'}
                             className="block-container section-py">
                        <h2 className="theme-font-section-title pb-40 theme-font-bold">
                            Enhance your Messaging ROI by
                            <span style={{color: 'var(--tertiary)'}} className="px-1">3x</span>
                        </h2>
                        <div className="row">
                            <div className=" col-12 pb-40">
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className="green-strip"/>
                                    <div className="px-3">
                                        <h2 className="section-sub-header theme-font-semiBold py-4">
                                            Time-saving workflow
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            With inbuilt capabilities that cover all steps of data integration,
                                            segmentation, and campaign management, SCuiDO accelerates the entire Message
                                            marketing process.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-12 pb-40">
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" brown-strip"/>
                                    <div className="px-3">
                                        <h2 className="section-sub-header theme-font-semiBold py-4">
                                            Reduce costs
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Pay only for the resources you need with subscription-based plans and
                                            scalable features.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pb-40">
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" purple-strip"/>
                                    <div className="px-3">
                                        <h2 className="section-sub-header theme-font-semiBold py-4">
                                            Seamless collaboration
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Enables teamwork between marketers, designers, analysts, and others.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pb-40">
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" pink-strip"/>
                                    <div className="px-3">
                                        <h2 className="section-sub-header theme-font-semiBold py-4">
                                            Easy to operate
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Intuitive tools, smart features and simple workflows.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" fresh-green-strip"/>
                                    <div className="px-3">
                                        <h2 className="section-sub-header theme-font-semiBold py-4">
                                            Data privacy
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Strict storage, access, and authentication controls with the option to
                                            deploy on-premise for large enterprises.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'home-services-house-transitions-mobile'}
                             style={{backgroundColor: '#F9FCFE'}}>
                        <div className='block-container section-py'>
                            <h2 className='section-title theme-text-black pb-40 theme-font-bold'>
                                Your Go-To Customer Messaging Tool For all Purposes
                            </h2>
                            <h4 className="font-size-md theme-text-black theme-font-normal pb-40 ">
                                Cultivate leads, convert prospects, increase brand awareness, improve customer
                                engagement, boost revenue, and achieve other desired outcomes by delivering tailored
                                Outreach with SCuiDO.
                            </h4>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 pe-0">
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/homepage/yourGoTo.jpg'}
                                         className="img-fluid"
                                         alt={'A graphic showing breakdown of services used after shifting to a new house from different cities.'}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'industries-mob'}
                             className="section-py block-container ">
                        <h2 className="section-title  theme-font-bold pb-40">
                            Customer Outreach Efficiency
                        </h2>
                        <h3 className="sub-section-title  pb-40">
                            For Every Business, At Every Level
                        </h3>
                        <div className="d-flex flex-wrap"
                             style={{gap: 60}}>
                            <div className=' email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/dtcProducts.png'}
                                     className="cursor"
                                     width={"53px"}
                                     height={"57px"}
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/e-commerce'}>
                                        <span className="theme-font-semiBold"
                                              style={{color: appTheme.linkStyle.color}}>
                                             DTC Products /
                                            <span style={{whiteSpace: 'nowrap'}}>  E-commerce </span>
                                        </span>
                                    </Link>
                                </h4>
                            </div>
                            <div className=' email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/dtcServices.png'}
                                     className=""
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     width={"53px"}
                                     height={"57px"}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/dtc-services'}>
                                    <span className="theme-font-semiBold"
                                          style={{color: appTheme.linkStyle.color}}>
                                       DTC Services
                                    </span>
                                    </Link>
                                </h4>
                            </div>

                            <div className='email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/educationInstitutes.png'}
                                     className=""
                                     width={"53px"}
                                     height={"57px"}
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/education'}>
                                    <span className="theme-font-semiBold"
                                          style={{color: appTheme.linkStyle.color}}>
                                        Education
                                        Institutes
                                    </span>
                                    </Link>
                                </h4>
                            </div>
                            <div className=' email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/financialServices.png'}
                                     className="cursor"
                                     width={"53px"}
                                     height={"57px"}
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/financial-services'}>
                                    <span className="theme-font-semiBold"
                                          style={{color: appTheme.linkStyle.color}}>
                                        Financial Services
                                    </span>
                                    </Link>
                                </h4>
                            </div>

                            <div className='email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/realEstate.png'}
                                     className=""
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     width={"53px"}
                                     height={"57px"}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/real-estate'}>
                                    <span className="theme-font-semiBold"
                                          style={{color: appTheme.linkStyle.color}}>
                                        Real Estate
                                    </span>
                                    </Link>
                                </h4>
                            </div>
                            <div className='email-marketing-block text-center'>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/homeServices.png'}
                                     style={{marginBottom: (isMobileView || isLGView) ? '10px' : '30px'}}
                                     width={"53px"}
                                     height={"57px"}
                                     alt={''}/>
                                <h4 className="font-size-md cursor">
                                    <Link to={'/industries/home-services'}>
                                    <span className="theme-font-semiBold"
                                          style={{color: appTheme.linkStyle.color}}>
                                        Home Services
                                    </span>
                                    </Link>
                                </h4>
                            </div>

                        </div>
                    </section>

                    <section id={'home-services-secondary-footer-mobile'}>
                        <div className='block-container padding-section-vertical secondary-banner-bg'>
                            <div className='text-center px-2'>
                                <h4 className='section-title theme-text-white theme-font-bold pb-60'>
                                    Want to take Your Customer Outreach to the Next Level?
                                </h4>
                            </div>
                            <div className='d-flex justify-content-center '>
                                <button onClick={() => redirectTo('/app/user/register')}
                                        className="footer-sign-up-button">
                                    Sign Up
                                </button>
                                <div style={{marginLeft: 20}}>
                                    <button
                                        className="footer-free-demo-button"
                                        onClick={() => redirectTo('/contact-us')}>
                                        Get A Free Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        }

        return (

            <div>
                <section id={'homepage-banner-desktop'}>
                    <div className={isProdEnvironment ? " BannerBg-prod d-flex align-items-center" : "d-flex align-items-center BannerBg "}
                        style={{paddingTop: 70, paddingBottom: 70}}>
                        <div className="padding-block-left" style={{flex: 1}}>
                            <h1 className='theme-font-semiBold' style={{fontSize: 48}}>
                                Next-Gen Customer <br/> Outreach for SMBs
                            </h1>
                            <h3 className="py-5 section-header theme-font-normal" style={{width: "70%"}}>
                                Create personalized messages across email
                                mobile push, whatsapp and SMS with
                                <span style={{color: 'var(--tertiary)'}} className="px-1 theme-font-semiBold">your business data</span>
                            </h3>
                            <div className="d-flex">
                                <div>
                                    <button className="sign-up-button"
                                            onClick={() => {
                                                redirectTo('/app/user/register')
                                            }}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            Sign Up
                                        </div>
                                    </button>

                                </div>
                                <div style={{marginLeft: "20px"}}>
                                    <button className="free-demo-button" onClick={() => {
                                        redirectTo('/contact-us')
                                    }}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            Get A Free Demo
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1}}/>
                    </div>
                </section>

                <section id={'customer-outreach-desktop'}
                         className="section-py">
                    <div className="block-container">
                        <h2 className='section-title theme-text-black text-center'>
                            An End-To-End Customer Outreach Platform
                        </h2>
                        <h3 className="sub-section-title theme-font-normal text-center pt-30 pb-60 mx-5 px-5">
                            Streamline your <span style={{color: 'var(--tertiary)'}}
                                                  className="px-1 theme-font-semiBold"> hyper-targeted </span> customer
                            outreach right from data to delivery. Effortlessly
                            upload, analyze, segment, design, personalize, send, and track with ease
                        </h3>
                        <div className="d-flex justify-content-center mx-5 px-3"
                             style={{gap: "30px"}}>
                            <div style={{flex: 1}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/setupData.png'}
                                     width={"80%"}
                                     className="img-fluid"
                                     alt={''}/>
                            </div>
                            <div style={{flex: 1}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/segmentYour.png'}
                                     className="img-fluid"
                                     width={"80%"}
                                     alt={''}/>

                            </div>
                            <div style={{flex: 1}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/designPersonalized.png'}
                                     width={"80%"}
                                     className="img-fluid"

                                     alt={''}/>

                            </div>
                        </div>
                        <div className="d-flex justify-content-center" style={{gap: "100px", paddingTop: "80px"}}>
                            <div style={{flex: 0.3}}/>
                            <div style={{flex: 1}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/reachOut.png'}
                                     width={"80%"}

                                     alt={''}/>
                            </div>
                            <div style={{flex: 1}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/trackYour.png'}
                                     width={"80%"}

                                     alt={''}/>

                            </div>
                            <div style={{flex: 0.3}}/>

                        </div>
                    </div>
                </section>

                <section id={'think-beyond-desktop'}
                         style={{backgroundColor: '#F9FCFE'}}>
                    <div className='d-flex section-py'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/homepage/dontLimitYour.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-60'>
                                    Think Beyond Contact Lists!
                                </h2>
                                <h3 className="section-sub-header">
                                    Your dataset, Your format
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Bring multiple datasets as are, dynamically add and link them without any
                                    restructuring or transformation.
                                </h4>
                                <h3 className="section-sub-header">
                                    Understand your customer better
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    SCuiDO allows you to consolidate and link data from multiple sources to provide you
                                    with a unified and granular view of your customer.
                                </h4>
                                <h3 className="section-sub-header">
                                    Limitless segmentation
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal  pt-2">
                                    With the ability to split the linked data into as many segments as you need, you can
                                    improve the scope and reach of your marketing initiatives.
                                </h4>

                            </div>
                        </div>
                    </div>
                </section>

                <section id={'hyper-targeting-desktop'}
                         className="section-py text-center w-100">
                    <h2 className='section-title'>
                        Engage Better With <span style={{color: 'var(--tertiary)'}}
                                                 className="px-1 theme-font-semiBold">Hyper-Targeting</span>
                    </h2>
                    <h3 className="sub-section-title theme-font-normal text-center pt-30 pb-60">
                        Send messages your customers actually want to receive, directly to their preferred channel
                    </h3>
                    <div className="d-flex block-container" style={{gap: 60}}>
                        <div className="d-flex justify-content-center"
                             style={{flex: 1}}>
                            <img loading="lazy"
                                 src={s3BaseURL + '/images/homepage/higherClick.png'}
                                 className="img-fluid"
                                 alt={''}/>
                        </div>
                        <div className="d-flex justify-content-center"
                             style={{flex: 1}}>

                            <img loading="lazy"
                                 src={s3BaseURL + '/images/homepage/improveMessage.png'}
                                 className="img-fluid"
                                 alt={''}/>
                        </div>
                        <div className=" d-flex justify-content-center"
                             style={{flex: 1}}>
                            <img loading="lazy"
                                 className="img-fluid"
                                 src={s3BaseURL + '/images/homepage/reduceUnsubscribes.png'}
                                 alt={''}/>
                        </div>
                        <div className="d-flex justify-content-center"
                             style={{flex: 1}}>
                            <img loading="lazy"
                                 className="img-fluid"
                                 src={s3BaseURL + '/images/homepage/avoidAbuse.png'}
                                 alt={''}/>
                        </div>
                    </div>

                </section>

                <section id={'templates-desktop'} style={{backgroundColor: '#F9FCFE'}}>
                    <div className='d-flex section-py'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Elegant Message Templates
                                </h2>
                                <h3 className="sub-section-title theme-font-normal  pt-30 pb-60">
                                    Send messages your customers actually want to receive, directly to their preferred
                                    channel
                                </h3>
                                <h3 className="section-sub-header">
                                    Expert Templates
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal pb-40 pt-2">
                                    Choose from professionally designed templates created by specialists.
                                </h4>
                                <h3 className="section-sub-header">
                                    Customization
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal pt-2 pb-40">
                                    Personalize templates with your branding elements, such as colors, logos, fonts, and
                                    images.
                                </h4>
                                <h3 className="section-sub-header">
                                    Effortless Creation
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal pt-2 pb-40">
                                    Easily build new templates using the platform's intuitive drag-and-drop tools.
                                </h4>
                                <h3 className="section-sub-header">
                                    Time-Efficient
                                </h3>
                                <h4 className="content theme-text-black theme-font-normal pt-2 pb-40">
                                    Save time by customizing or creating templates in just minutes.
                                </h4>
                                <h4 className="section-footer-text">
                                    Wow your audience!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/homepage/elegantEmailTemplate.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'enhance-messaging-desktop'}
                         className="section-py block-container">
                    <h2 className="theme-font-section-title text-center pb-60">
                        Enhance your Messaging ROI by
                        <span style={{color: 'var(--tertiary)'}} className="px-1 theme-font-semiBold"> 3x </span>
                    </h2>
                    <div className=" d-flex" style={{gap: 24}}>
                        <div className="panel" style={{flex: 1, minHeight: 220}}>
                            <div>
                                <div className="green-strip"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-sub-header-lg theme-font-semiBold py-4">
                                        Time-saving workflow
                                    </h2>
                                    <h4 className="content theme-font-normal theme-content-line-height pb-2">
                                        With inbuilt capabilities that cover all steps of data integration,
                                        segmentation, and campaign management, SCuiDO accelerates the entire Message
                                        marketing process.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel" style={{flex: 1, minHeight: 220}}>
                            <div>
                                <div className=" brown-strip"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-sub-header-lg theme-font-semiBold py-4">
                                        Reduce costs
                                    </h2>
                                    <h4 className="content theme-font-normal theme-content-line-height pb-2">
                                        Pay only for the resources you need with subscription-based plans and scalable
                                        features.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel" style={{flex: 1, minHeight: 220}}>
                            <div>
                                <div className="purple-strip"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-sub-header-lg theme-font-semiBold py-4">
                                        Seamless collaboration
                                    </h2>
                                    <h4 className="content theme-font-normal theme-content-line-height pb-2">
                                        Enables teamwork between marketers, designers, analysts, and others.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="offset-md-2 col-md-4 col-12">
                            <div className="panel"
                                 style={{minHeight: 220}}>
                                <div className="pink-strip"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-sub-header-lg theme-font-semiBold py-4">
                                        Easy to operate
                                    </h2>
                                    <h4 className="content theme-font-normal theme-content-line-height pb-2">
                                        Intuitive tools, smart features and simple workflows.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="panel"
                                 style={{minHeight: 220}}>
                                <div className="fresh-green-strip"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-sub-header-lg theme-font-semiBold py-4">
                                        Data privacy
                                    </h2>
                                    <h4 className="content theme-font-normal theme-content-line-height pb-2">
                                        Strict storage, access, and authentication controls with the option to deploy
                                        on-premise for large enterprises.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'your-go-to-desktop'}
                         style={{backgroundColor: '#F9FCFE'}}>
                    <div className='d-flex py-4'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/homepage/yourGoTo.jpg'}
                                 className="img-fluid"
                                 height={"80%"}
                                 width={"auto"}
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Your Go-To Customer Messaging Tool For all Purposes
                                </h2>
                                <h4 className="sub-section-title theme-text-black theme-font-normal pt-30">
                                    Cultivate leads, convert prospects, increase brand awareness, improve customer
                                    engagement, boost revenue, and achieve other desired outcomes by delivering tailored
                                    Outreach with SCuiDO.
                                </h4>
                            </div>
                        </div>

                    </div>
                </section>

                <section id={'customer-outreach-desk'} className="section-py ">
                    <h2 className="theme-font-section-title text-center ">
                        Customer Outreach Efficiency
                    </h2>
                    <h3 className="font-size-md pt-30 text-center pb-60">
                        For Every Business, At Every Level
                    </h3>
                    <div className=" ">
                        <div className="d-flex justify-content-center flex-wrap px-3"
                             style={{gap: 20}}>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/dtcProducts.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/e-commerce'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            DTC Products /
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            E-commerce
                                        </div>

                                    </Link>
                                </h4>
                            </div>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/dtcServices.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/dtc-services'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            DTC
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Services
                                        </div>
                                    </Link>
                                </h4>
                            </div>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/educationInstitutes.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/education'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Education
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Institutes
                                        </div>
                                    </Link>
                                </h4>
                            </div>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/financialServices.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/financial-services'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Financial
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>Services
                                        </div>
                                    </Link>
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center px-3 pt-60"
                             style={{gap: 20}}>
                            <div style={{flex: 1}}/>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/realEstate.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/real-estate'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Real
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Estate
                                        </div>
                                    </Link>
                                </h4>
                            </div>
                            <div className='text-center'
                                 style={{flex: 1, minWidth: 150, marginBottom: (isXSView || isSMView) && 35}}>
                                <img loading="lazy"
                                     src={s3BaseURL + '/images/homepage/homeServices.png'}
                                     className="cursor mb-2"
                                     width={"81px"}
                                     height={"74px"}
                                     alt={''}/>
                                <h4 className="theme-font-content-lg cursor">
                                    <Link to={'/industries/home-services'}>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Home
                                        </div>
                                        <div className="theme-font-semiBold sub-section-title"
                                             style={{color: appTheme.linkStyle.color}}>
                                            Services
                                        </div>

                                    </Link>
                                </h4>
                            </div>
                            <div style={{flex: 1}}/>
                        </div>
                    </div>
                </section>

                <section
                    className='block-container padding-section-vertical secondary-banner-bg'
                    id={"education-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to
                        take
                        Your
                        Customer
                        Outreach
                        to
                        the
                        Next
                        Level ?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };


    return (
        <div className="w-100">
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

Home.propTypes = {};

Home.defaultProps = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Home));
