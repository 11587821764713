import React, {Suspense, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {getMetaTagUi} from "./utils/httpHelper";
import {setCurrentPathname, setPrevLocation, getSeoData} from "./core/actions/appActions";
import PrePostLoginRoute from "./core/webRouteWrapper/PrePostLoginRoute";
import HomePage from "./home/home";
import pageNotFound from "./core/components/PageNotFound/pageNotFound";
import DLLoader from "./core/components/Loader/loader";
import termCondition from "./legal/tnc/tnc";
import acceptableUsePolicy from "./legal/acceptableUsePolicy/acceptableUsePolicy";
import refundPolicy from "./legal/refundPolicy/refundPolicy";

import emailTemplate from "./features/emailTemplate/emailTemplate";
import CampaignManagement from "./features/campaignManagement/management";
import privacyPolicy from "./legal/privacyPolicy/privacyPolicy";
import Segmentation from "./features/segmentation/segmentation";
import DataSetup from "./features/dataSetup/dataSetup";
import ECommerce from "./useCases/eCommerce/eCommerce";
import Education from "./useCases/education/education";
import FinancialServices from "./useCases/financialServices/financialServices";
import RealEstate from "./useCases/realEstate/realEstate";
import HomeServices from "./useCases/homeServices/homeServices";
import SaasDTCSubscriptionServices from "./useCases/DTCSubscriptionServices/DTCSubscriptionServices";
import AboutUs from "./legal/aboutUs/aboutUs";
import CookiePolicy from "./legal/cookiePolicy/cookiePolicy";
import ContactUs from "./user/support/contactUs";
import Pricing from "./user/pricing/Pricing";
import Header from "./core/layout/header/header";
import ToastMessage from "./core/components/toastMessage/toastMessage";
import betaRequest from "./user/beta/betaRequest";
import BetaAcceptRequestView from "./user/beta/betaAcceptRequestView";


const AppNavigator = (props) => {

    const [seoMetaDataState, setSeoMetaDataState] = useState({
        seoMetaData: null,
        isMetaDataCallFired: false,
    });

    useEffect(() => {
        setTimeout(() => {
            windowLoadEvent();
        }, 1500); // with every page navigation we are scrolling page to top. to avoid those scroll events implemented the delay of 1500ms
        setCurrentPathname(props.location.pathname);
            getSeoData().then((res) => {
                if(res.success){
                    setSeoMetaDataState((prevState) => ({
                        ...prevState,
                        seoMetaData: res.data?.SEO,
                        isMetaDataCallFired: true
                    }))
                }
            })
    }, [props.location.pathname]);

    const windowLoadEvent = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'gtm.load'
        });
    };

    const {isMobileView} = props;

    const renderContent = () => {
        const {seoMetaData} = seoMetaDataState;

        return (
            <div className="w-100  bg-white" id=""
                 style={{
                     height: '100%',
                 }}>
                {getMetaTagUi(props.location.pathname, seoMetaData, false)}

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PrePostLoginRoute exact path="/" component={HomePage}/>
                        <PrePostLoginRoute exact path="/legal/tnc" component={termCondition}/>
                        <PrePostLoginRoute exact path="/legal/acceptable-use-policy" component={acceptableUsePolicy}/>
                        <PrePostLoginRoute exact path="/legal/refund-policy" component={refundPolicy}/>
                        <PrePostLoginRoute exact path="/features/email-templates" component={emailTemplate}/>
                        <PrePostLoginRoute exact path="/features/campaigns" component={CampaignManagement}/>
                        <PrePostLoginRoute exact path="/legal/privacy-policy" component={privacyPolicy}/>
                        <PrePostLoginRoute exact path="/features/segmentation" component={Segmentation}/>
                        <PrePostLoginRoute exact path="/features/data-setup" component={DataSetup}/>
                        <PrePostLoginRoute exact path="/industries/e-commerce" component={ECommerce}/>
                        <PrePostLoginRoute exact path="/industries/education" component={Education}/>
                        <PrePostLoginRoute exact path="/industries/financial-services" component={FinancialServices}/>
                        <PrePostLoginRoute exact path="/industries/real-estate" component={RealEstate}/>
                        <PrePostLoginRoute exact path="/industries/home-services" component={HomeServices}/>
                        <PrePostLoginRoute exact path="/industries/dtc-services" component={SaasDTCSubscriptionServices}/>
                        <PrePostLoginRoute exact path="/about-us" component={AboutUs}/>
                        <PrePostLoginRoute exact path="/legal/cookie-policy" component={CookiePolicy}/>
                        <PrePostLoginRoute exact path="/contact-us" component={ContactUs}/>
                        <PrePostLoginRoute exact path="/pricing" component={Pricing}/>
                        <PrePostLoginRoute exact path="/subscription/beta-request" component={betaRequest}/>
                        <PrePostLoginRoute exact path="/beta-request/submit" component={BetaAcceptRequestView}/>
                        <Route path="*" component={pageNotFound}/>
                    </Switch>
                </Suspense>
                <ToastMessage/>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <Header {...props}/>
        );
    };

    return (
        <div className={props.isMobileDevice ? 'innerWindowHeight' : 'vh-100'}>
            {renderHeader()}
            <div className='d-flex' style={{
                paddingTop: isMobileView ? '50px' : '80px',
                height: '100%'
            }}>
                {renderContent()}
            </div>

        </div>

    );
};


AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isDrawerOpen: state.appState.isDrawerOpen,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AppNavigator));

