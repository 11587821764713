import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

function AcceptableUsePolicy(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <div className="w-100">
                <div className="px-3">
                    <div className="row">
                        <div className="offset-md-2 col-md-8 col-12 ">
                            <div className="panel p-3 my-2">
                                <div className="d-flex justify-content-center mb-4 mt-2">
                                    <h1 className="theme-font-page-title py-4">
                                        Acceptable Use Policy
                                    </h1>
                                </div>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        Last revised June 2024, effective as of July 2024
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        Scuido.com’s Acceptable Use Policy (“AUP”) forms a part of the Scuido.com terms
                                        of service entered into between you, the Customer and SCuiDO, and describes the
                                        acceptable and unacceptable use of the Services. Terms that are not defined in
                                        this AUP are defined in the agreement or terms of service entered into between
                                        Scuido and Customer (“Customer” or “you” or “your”). The prohibited conduct in
                                        this AUP is not exhaustive. Customer is responsible for its authorized users’
                                        compliance with this AUP. If Customer or any authorized user violates this AUP,
                                        Scuido.com may suspend Customer’s use of the Services.
                                    </h6>
                                </div>

                                <h4 className="theme-font-content-lg pt-3 pb-2">ANTI-SPAM POLICY</h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Scuido.com has a no tolerance spam policy. “Spam” means unsolicited
                                        communication to persons with whom our customers do not have a business
                                        relationship or who have not specifically requested (opted-in to) our customers’
                                        mailings.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        We monitor the Services for any large increases in sending emails. A user’s
                                        account will be terminated if the user sends unsolicited email messages. Please
                                        report any suspected abuse to
                                        <a className="px-1" href={"mailto:"}>support@scuido.com</a>. ISPs and Blacklist
                                        administrators may contact us at
                                        <a className="px-1" href={"mailto:"}>support@scuido.com</a>. Please forward the
                                        complete
                                        email in question, including headers. If applicable, please also unsubscribe
                                        from the newsletter using the link at the bottom of the email if you no longer
                                        wish to receive emails from the sender. We will take the appropriate action
                                        against the sender of the email in question.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        To use our Services, you must agree to send only permission-based email. This
                                        means all recipients must have opted-in to receive communications from the
                                        sending entity.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        An opt-in can occur either via a sign-up form on a web site, at a point-of-sale
                                        sign-up form, or on a physical sign-up sheet. Purchased lists may not be used
                                        with the Services, regardless of the source or permission status.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        You may not use Scuido.com’s services to send messages to recipients that:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>Were harvested or scraped from websites</li>
                                            <li>Have been acquired through purchased lists (whether they are opt-in or
                                                not)
                                            </li>
                                            <li>Have not engaged with or been sent any email in two years or longer.
                                            </li>
                                        </ul>
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        Additionally, the following sending practices are prohibited for all email sent
                                        from Scuido.com:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>Subscription forms that subscribe users to an unrelated list</li>
                                            <li>Sending unrelated offers or unrelated content to your recipient list
                                                outside of
                                                brand that they originally opted in to receive messages from.
                                            </li>
                                        </ul>
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        You may:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>Send out regular broadcasts and triggered emails to recipients who have
                                                opted-in
                                                to receive them
                                            </li>
                                            <li>Send out information and content to recipients who have requested to
                                                receive
                                                content on that topic from your organization
                                            </li>
                                            <li>Send out user-triggered emails that result from actions carried out on
                                                your
                                                website
                                            </li>
                                        </ul>
                                    </h6>
                                </div>


                                <h4 className="theme-font-content-lg pt-3 pb-2">Procedure for Handling High
                                    User-Generated Spam Complaint Rates</h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Your account may be subject to termination, temporary suspension, or the
                                        requirement that you utilize your own SMTP if your user generated spam complaint
                                        rates meet or exceed 0.1% for a 30 day period.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        If your spam rates meet or exceed this threshold, our team will:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>
                                                Provide notice to the admin email address(es) in your account.
                                            </li>
                                            <li>
                                                Provide reasonable guidance on spam rate reduction.
                                            </li>
                                        </ul>
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        We may allow a sender 30-60 days to reduce average spam rates depending on
                                        individual circumstances.
                                    </h6>
                                </div>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Procedure for Handling Recipient Complaints
                                </h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Your account will be immediately terminated if we determine in our sole
                                        discretion that you send spam. If you use the Services to send out emails to
                                        addresses obtained in any way other than a subscriber opting-in to your list,
                                        you may incur a $100 (US) charge per substantiated incident (i.e. per email) in
                                        accordance with regulations.
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        To determine whether you have sent spam we will:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>
                                                review the content of the message in question
                                            </li>
                                            <li>
                                                review your subscriber list for patterns common to harvested lists
                                            </li>
                                            <li>
                                                review the spam complaint
                                            </li>
                                            <li>
                                                view the records to see when the subscriber was subscribed and their IP
                                                address
                                            </li>
                                        </ul>
                                    </h6>
                                </div>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Text-Message & Mobile Communications
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    Aside from two exceptions noted later in this section, you need to meet each of
                                    the consent requirements listed below.
                                </h6>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Consent Requirements
                                </h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Customer must comply with the following consent requirements or the Alternative
                                        Consent Requirements prior to sending any Email, Push Message, Whatsapp Message
                                        or other messaging:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>
                                                Prior to sending the first message, Customer must obtain consent from
                                                the
                                                message recipient to communicate with them. Customer must make clear to
                                                the
                                                individual they are agreeing to receive messages of the type that
                                                Customer will
                                                send (er.g., consent must be specific to the type of messaging, like
                                                marketing
                                                messages).
                                            </li>
                                            <li>
                                                Customer must keep a record of the consent, such as a copy of the
                                                document or
                                                form that the message recipient signed, or a timestamp of when the
                                                recipient
                                                completed a sign-up flow.
                                            </li>
                                            <li>
                                                If Customer does not send an initial message to that recipient within a
                                                reasonable period after receiving consent (or as set forth by applicable
                                                law),
                                                then Customer will need to reconfirm consent in the first message sent
                                                to that
                                                recipient.
                                            </li>
                                            <li>
                                                The consent applies only to the Customer entity that received the
                                                consent from
                                                the recipient, and to the specific use or campaign that the recipient
                                                has
                                                consented to. Customers are prohibited from sending messages from other
                                                brands
                                                or companies to a recipient unless the recipient has consented to each
                                                brand/company individually and specifically with respect to the type of
                                                message.
                                            </li>
                                            <li>
                                                Customer must retain proof of opt-in consent as set forth by applicable
                                                law.
                                            </li>
                                        </ul>
                                    </h6>
                                </div>

                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Alternative Consent Requirements
                                </h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Express consent prior to sending a recipient a message is not necessary where:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>
                                                Contact is initiated by the recipient: If an individual sends a message
                                                to you,
                                                you are free to respond in an exchange with that individual.
                                            </li>
                                            <li>
                                                Informational content based on prior relationship: You may send a
                                                message to an
                                                individual where you have a prior relationship, provided that individual
                                                provided their phone number or email to you, and has taken some action
                                                to
                                                trigger the potential communication, and has not expressed a preference
                                                to not
                                                receive messages from you. Actions can include a button press, alert
                                                setup,
                                                appointments, or order placements. Examples of acceptable messages in
                                                these
                                                scenarios include appointment reminders, receipts, one-time passwords,
                                                order/shipping/reservation confirmations, drivers coordinating pick up
                                                locations
                                                with riders, and repair persons confirming service call times.The
                                                message can’t
                                                attempt to promote a product, convince someone to buy something, or
                                                advocate for
                                                a social cause.
                                            </li>
                                        </ul>
                                    </h6>
                                </div>

                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Periodic Messages and Ongoing Consent
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    If you intend to send messages to a recipient on an ongoing basis, you should
                                    confirm the recipient’s consent by offering them a clear reminder of how to
                                    unsubscribe from those messages using standard opt-out language (defined below). You
                                    must also respect the message recipient’s preferences in terms of frequency of
                                    contact. If applicable law requires that you reconfirm the recipient’s consent
                                    periodically, you are required to reconfirm the recipient’s consent.
                                </h6>

                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Identifying the Sender
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    Every message you send must clearly identify you (the party that obtained the opt-in
                                    from the recipient) as the sender, except in follow-up messages of an ongoing
                                    conversation.
                                </h6>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Opt-out
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    The initial message that you send to an individual needs to include the following
                                    language: “Reply STOP to unsubscribe,” or the equivalent using another standard
                                    opt-out keyword, such as STOPALL, UNSUBSCRIBE, CANCEL, END, and QUIT.
                                    Individuals must have the ability to revoke consent at any time by replying with a
                                    standard opt-out keyword. When an individual opts out, you may deliver one final
                                    message to confirm that the opt-out has been processed, but any subsequent messages
                                    are not allowed (We have not given any provision to do this). An individual must
                                    once again provide consent before you can send any additional messages.
                                </h6>

                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Country-Specific Rules
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    All messages should comply with the rules applicable to the country in which the
                                    message recipient lives. You are responsible for understanding the laws applicable
                                    to the locations where your recipients are located.
                                </h6>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Age and Geographic Gating
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    If you are sending messages in any way related to alcohol, firearms, gambling,
                                    tobacco, or other adult content, then more restrictions apply. In addition to
                                    obtaining consent from every message recipient, you must ensure that no message
                                    recipient is younger than the legal age of consent based on where the recipient is
                                    located. You also must ensure that the message content complies with all applicable
                                    laws of the jurisdiction in which the message recipient is located or applicable
                                    communications industry guidelines or standards.
                                </h6>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    You need to be able to provide proof that you have in place measures to ensure
                                    compliance with these restrictions.
                                </h6>


                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Messaging Policy Violation Detection and Prevention Evasion
                                </h4>
                                <h6 className="theme-font-content theme-font-normal py-2 ">
                                    Customers may not evade any unwanted messaging detection and prevention mechanisms
                                    (whether such detection and prevention mechanisms are ours, our messaging platform’s
                                    or a telecommunication provider’s mechanisms). We or our third-party messaging
                                    platform may collect and monitor the content of text messages that are transmitted
                                    via the Services to certain countries in order to detect spam, fraudulent activity,
                                    and violations of the AUP. Examples of prohibited practices include content that is
                                    specifically designed to evade detection, snowshoeing, intentionally misspelled
                                    words, or nonstandard opt-out phrases that are designed to evade detection
                                    mechanisms.
                                </h6>

                                <h4 className="theme-font-content-lg pt-3 pb-2">
                                    Prohibited Content
                                </h4>
                                <div>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Scuido.com prohibits the use of the Services to send content which:
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2">
                                        <ul>
                                            <li>
                                                Provides, sells or offers to sell (or services related to): pornography;
                                                escort
                                                services; illegal goods; illegal drugs; illegal drug contraband; pirated
                                                computer programs; instructions on how to assemble or otherwise make
                                                bombs,
                                                grenades or other weapons
                                            </li>
                                            <li>
                                                Provides, sells, or offers to sell or rent any mailing list
                                            </li>
                                            <li>
                                                Displays material that exploits children, or otherwise exploits children
                                                under
                                                18 years of age
                                            </li>
                                            <li>
                                                Posts or discloses any personally identifying information or private
                                                information
                                                about children
                                            </li>
                                            <li>
                                                Provides material that is abusive, bigoted, prejudiced, racist, hateful,
                                                profane, obscene, violent, harassing, fraudulent, deceptive, misleading
                                                or
                                                otherwise illegal content
                                            </li>
                                            <li>
                                                Violates the copyright, trademark, patent, trade secret, intellectual
                                                property
                                                or other rights of another
                                            </li>
                                            <li>
                                                Sells or promotes any products or services that are unlawful in the
                                                location at
                                                which the content is posted or received
                                            </li>
                                            <li>
                                                Introduces viruses, worms, harmful code and/or Trojan horses on the
                                                Internet
                                            </li>
                                            <li>
                                                Promotes, solicits or participates in pyramid schemes
                                            </li>
                                            <li>
                                                Engages in any libelous, defamatory, scandalous, threatening, harassing
                                                activity
                                            </li>
                                            <li>
                                                Posts any content that advocates, promotes or otherwise encourages
                                                violence
                                                against any governments, organizations, groups or individuals or which
                                                provides
                                                instruction, information or assistance in causing or carrying out such
                                                violence
                                            </li>
                                            <li>
                                                Is fraudulent
                                            </li>
                                            <li>
                                                Relates to the marketing or sale of cannabis or CBD
                                            </li>
                                            <li>
                                                Offers for prescription medication that cannot legally be sold
                                                over-the-counter
                                                are prohibited in the United States
                                            </li>
                                            <li>
                                                Any other message or content that is illegal in the jurisdiction in
                                                which the
                                                recipient lives
                                            </li>
                                            <li>
                                                Fraudulent messages
                                            </li>
                                            <li>
                                                Malicious content, such as malware or viruses
                                            </li>
                                            <li>
                                                Any content that is intentionally designed to evade detection.
                                            </li>
                                        </ul>
                                    </h6>
                                    <h6 className="theme-font-content theme-font-normal py-2 ">
                                        Scuido.com reserves the right to prohibit the use of the Services by any entity
                                        at its sole discretion.
                                    </h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"block-container"}>
                    <div className="footer-border-light"/>
                </div>
                <Footer {...props}/>
            </div>
        </div>
    );
}

export default AcceptableUsePolicy;
