import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../../core/layout/footer/footer";
import {showToastMessage} from "../../core/actions/appActions";
import {getTicketFor, raisePreLoginTicket} from "./contactUsActions";
import {APP_CONST} from "../../core/AppConstants";
import {isEmpty} from "../../utils/validations";
import {getRandomId, isValidEmail, redirectTo} from "../../utils/helper";
import store from './../../store';
import appTheme from "../../assets/appTheme";
import {Link} from "react-router-dom";

const ContactUs = (props) => {
    const {isMobileView, isMDView, isXSView, isSMView} = props;

    const [raiseTicketState, setRaiseTicketState] = useState({
        questionRelatedTo: '',
        questionRelatedToError: '',
        email: '',
        emailError: '',
        message: '',
        messageError: '',
        lastName: '',
        lastNameError: '',
        firstName: '',
        firstNameError: '',
        questionOption: [],
        selectedDropDownValue: ''
    });

    const getAction = () => {
        return props?.location?.state
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        let action = getAction();

        getTicketFor().then((res) => {
            if (res.success) {
                setRaiseTicketState((prevState) => ({
                    ...prevState,
                    questionOption: getTicket(res.data),
                    selectedDropDownValue: (action === 'FROM_FREEDEMO') ? 'Free Demo' : '',
                    questionRelatedTo: (action === 'FROM_FREEDEMO') ? 'FREE_DEMO' : '',
                }))
            }
        })
    }, []);

    const getTicket = (ticketOptions) => {
        let data = [];
        if (isEmpty(ticketOptions)) {
            return data;
        }

        ticketOptions.forEach((item) => {
            data.push({
                label: item?.value,
                value: item?.key
            })
        });

        return data
    };

    const renderHeader = () => {
        return (
            <div className='bg-white'>
                <div className='block-container d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                        <i className="fa-solid fa-chevron-left mb-0 me-2 cursor theme-font-bold theme-text-primary"
                           style={{
                               fontSize: 16,
                               marginTop: isMobileView ? 0 : 8
                           }}
                           onClick={() => props.history.goBack()}/>
                        <div className='theme-text-tertiary theme-font-section-sub-header-lg theme-font-semiBold'>
                            Contact Us
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const handleChange = (name, value) => {

        setRaiseTicketState((prev) => ({
            ...prev,
            [name]: value,
            [name + 'Error']: ''
        }))
    };

    const validateChanges = (name) => {

        const {questionRelatedTo, message, email, firstName, lastName} = raiseTicketState;
        const {t} = props;

        if (name === 'firstName' || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(firstName)) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    firstNameError: 'Enter first name.'
                }));
                return false;
            }

            if (!isEmpty(firstName) && firstName.length > 50) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    firstNameError: 'Maximum 50 characters allowed.'
                }));
                return false;
            }
        }

        if (name === 'lastName' || name === APP_CONST.FORM_SUBMISSION) {

            if (!isEmpty(lastName) && lastName.length > 50) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    lastNameError: 'Maximum 50 characters allowed.'
                }));
                return false;
            }
        }

        if (name === 'email' || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(email)) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    emailError: 'Enter email.'
                }));
                return false;
            }

            if (!isValidEmail(email, true)) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    emailError: t('LABEL_ERROR_EMAIL_VALIDATION')
                }));
                return false;
            }

            if (!isEmpty(email) && email.length > 50) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    emailError: 'Maximum 50 characters allowed.'
                }));
                return false;
            }
        }

        if (name === 'questionRelatedTo' || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(questionRelatedTo)) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    questionRelatedToError: 'Select question related to field.'
                }));
                return false;
            }
        }

        if (name === 'message' || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(message)) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    messageError: 'Enter message.'
                }));
                return false;
            }

            if (!isEmpty(message) && message.length > 500) {
                setRaiseTicketState(previousState => ({
                    ...previousState,
                    messageError: 'Maximum 500 characters allowed.'
                }));
                return false;
            }
        }

        return true;
    };

    const resetState = () => {
        setRaiseTicketState((prevState) => ({
            ...prevState,
            questionRelatedTo: '',
            email: '',
            message: '',
            lastName: '',
            firstName: '',
            selectedDropDownValue: ''
        }));
    };

    const onSubmit = () => {
        const {questionRelatedTo, message, email, lastName, firstName} = raiseTicketState;
        const {account} = props;

        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return
        }

        let data = {
            ticketFor: questionRelatedTo,
            description: message,
            firstName: firstName,
            lastName: lastName,
            email: email
        };

        raisePreLoginTicket(data, account?.id).then((res) => {
            let ticketId = res.data?.ticketId;
            if (res?.success) {
                resetState();
                store.dispatch(showToastMessage('success', 'Ticket has been raised. Your ticket id is ' + ticketId + '.'))
            }
        })
    };

    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    const getSetSelectedValue = (selectedValue, selectedLabel) => {

        setRaiseTicketState((prevState) => ({
            ...prevState,
            questionRelatedTo: selectedValue,
            selectedDropDownValue: selectedLabel

        }));
    };

    const getMargin = () => {
        if (isMDView) {
            return '16px'
        }
        if (isXSView || isSMView) {
            return '0px'
        }
        return '20px'
    };

    const renderRaiseTicket = (props) => {

        const {
            selectedDropDownValue, message, email, lastName, firstName, firstNameError, lastNameError,
            questionOption, emailError, questionRelatedToError, messageError,
        } = raiseTicketState;

        return (
            <div className='py-3 ps-0'
                 style={{marginRight: getMargin()}}>
                <div className={"row"}>
                    <div className='col-md-6 col-12'>
                        <div className={" col-12"}>
                            <span className='theme-font-normal theme-font-content'
                                  style={{
                                      marginBottom: '4px',
                                      color: (questionRelatedToError ? 'var(--error)' : 'var(--black-text)')
                                  }}>
                                Type of inquiry<span style={{color: 'var(--error)'}}>*</span></span>
                        </div>
                        <div className={"col-12"}>
                            <div className={'d-flex justify-content-end'}
                                 style={{
                                     border: '1px solid rgba(0, 0, 0, 0.23)',
                                     borderColor: (questionRelatedToError ? 'var(--error)' : 'rgba(0, 0, 0, 0.23)'),
                                     borderRadius: '8px',
                                     width: '100%',
                                     backgroundColor: '#F5F5F5',
                                     fontWeight: '500',
                                     color: 'var(--black-text)',
                                 }}
                            >
                                <div className="dropdown w-100 h-100">
                                    <button
                                        className="btn btn-secondary dropdown-toggle d-flex justify-content-between justify-content-center align-items-center"
                                        type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                        style={{
                                            borderColor: (questionRelatedToError ? 'var(--error)' : 'rgba(0, 0, 0, 0.23)'),
                                            borderRadius: '8px',
                                            padding: '7px 14px',
                                            width: '100%',
                                            backgroundColor: '#F5F5F5',
                                            fontWeight: '500',
                                            color: 'var(--black-text)',
                                            border: 'none',
                                            height: '36px'
                                        }}>
                                        <span style={{
                                            marginBottom: '4px',
                                            color: 'var(--black-text)',
                                            fontWeight: 500,
                                            fontSize: '14px'
                                        }}>{selectedDropDownValue}</span></button>
                                    <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1"
                                        style={{
                                            minHeight: 32 * 3 + 'px',
                                            maxHeight: 32 * 9 + 'px',
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {
                                            questionOption.map((value, index) => {
                                                return (
                                                    <li key={getRandomId() + index}>
                                                        <span className="dropdown-item"
                                                              style={{
                                                                  paddingLeft: '16px',
                                                                  paddingRight: '6px'
                                                              }}
                                                              onClick={() => {
                                                                  getSetSelectedValue(value.value, value.label)
                                                              }}>
                                                        {value.label}
                                                    </span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>


                            <div style={{height: 20}}>
                                {questionRelatedToError !== '' &&
                                <div style={{marginLeft: 14}}>
                                    <span className='theme-text-error'
                                          style={{
                                              paddingLeft: '5px',
                                              minHeight: '19px',
                                              fontSize: '12px'
                                          }}>
                                        <span>{questionRelatedToError}</span></span>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row "}>
                    <div className={"col-md-6 col-12"}>
                            <span className='theme-font-normal theme-font-content' style={{
                                marginBottom: '4px',
                                color: (firstNameError ? 'var(--error)' : 'var(--black-text)')
                            }}>
                                First Name<span className='theme-text-error'>*</span></span>
                        <input
                            onChange={(value) => handleChange('firstName', value.target.value)}
                            type="text"
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                borderColor: (firstNameError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                borderRadius: '8px',
                                padding: '7px 14px',
                                width: '100%',
                                backgroundColor: '#F5F5F5',
                                fontWeight: '500',
                                color: 'var(--black-text)'
                            }}
                            placeholder={" "}
                            value={firstName}
                            onBlur={() => onBlurInputText('firstName')}
                            maxLength={51}
                        />

                        <span className='d-flex justify-content-between theme-text-error theme-font-content-xs'
                              style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{firstNameError}</span></span>


                    </div>
                    <div className={"col-md-6 col-12"}>
                            <span className='theme-font-normal theme-font-content' style={{
                                marginBottom: '4px',
                                color: (lastNameError ? 'var(--error)' : 'var(--black-text)')
                            }}>Last Name</span>
                        <input
                            onChange={(value) => handleChange('lastName', value.target.value)}

                            type="text"
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                borderColor: (lastNameError ? 'D72F2F' : ' rgba(0, 0, 0, 0.23)'),
                                borderRadius: '8px',
                                padding: '7px 14px',
                                width: '100%',
                                backgroundColor: '#F5F5F5',
                                fontWeight: '500',
                                color: 'var(--black-text)'
                            }}
                            placeholder={" "}
                            value={lastName}
                            onBlur={() => onBlurInputText('lastName')}
                            maxLength={51}
                        />

                        <span className='d-flex justify-content-between theme-text-error theme-font-content-xs'
                              style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{lastNameError}</span></span>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                            <span className='theme-font-normal theme-font-content' style={{
                                marginBottom: '4px',
                                color: (emailError ? 'var(--error)' : 'var(--black-text)')
                            }}>
                                Email<span style={{color: 'var(--error)'}}>*</span></span>
                        <input
                            onChange={(value) => handleChange('email', value.target.value)}

                            type="text"
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                borderColor: (emailError ? 'var(--error)' : 'rgba(0, 0, 0, 0.23)'),
                                borderRadius: '8px',
                                padding: '7px 14px',
                                width: '100%',
                                backgroundColor: '#F5F5F5',
                                fontWeight: '500',
                                color: 'var(--black-text)'
                            }}
                            placeholder={" "}
                            value={email}
                            onBlur={() => onBlurInputText('email')}
                            maxLength={51}
                        />

                        <span className='d-flex justify-content-between theme-text-error theme-font-content-xs'
                              style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{emailError}</span></span>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                            <span className='theme-font-normal theme-font-content' style={{
                                marginBottom: '4px',
                                color: (messageError ? 'var(--error)' : 'var(--black-text)')
                            }}>
                                Description<span style={{color: 'var(--error)'}}>*</span></span>
                        <textarea rows="5"
                                  onChange={(value) => handleChange('message', value.target.value)}
                                  style={{
                                      border: '1px solid rgba(0, 0, 0, 0.23)',
                                      borderColor: (messageError ? 'red' : ' rgba(0, 0, 0, 0.23)'),
                                      borderRadius: '8px',
                                      padding: '7px 14px',
                                      width: '100%',
                                      backgroundColor: '#F5F5F5',
                                      fontWeight: '500',
                                      color: 'var(--black-text)',
                                      maxHeight: isMobileView ? '130px' : '91px',
                                      resize: 'none'
                                  }}
                                  value={message}
                                  onBlur={() => onBlurInputText('message')}
                                  maxLength={501}>
                        </textarea>

                        <span className='d-flex justify-content-between theme-text-error theme-font-content-xs mb-1 '
                              style={{paddingLeft: 5, minHeight: '19px', marginTop: -5}}>
                                        <span>{messageError}</span>
                        </span>

                    </div>
                </div>

                <div style={{marginTop: 2, marginBottom: 8}}>
                    <button
                        onClick={() => onSubmit()}
                        style={{
                            backgroundColor: 'var(--primary)',
                            lineHeight: "24px",
                            borderRadius: "10px",
                            font: 'normal normal medium Barlow',
                            color: "var(--white)",
                            fontWeight: 500,
                            border: 'none',
                            height: 40,
                            width: 165,
                            fontSize:16
                        }}>
                        <div className=''>Submit inquiry</div>
                    </button>
                </div>

                <div className='d-md-none d-block my-4'>
                    <div className='border-bottom'/>
                </div>

            </div>
        );
    };

    const renderContactInfo = () => {
        return (
            <div className='d-flex flex-column justify-content-evenly theme-font-content-lg h-100 p-md-3 p-0'>
                <div className='mb-5 ms-md-2'>
                    <div className='theme-font-section-sub-header-lg theme-text-tertiary'>Write to us</div>
                    <a className="theme-font-content"
                       style={{color: appTheme.linkStyle.color}}
                       href={"mailto:" + 'info@deccanlogic.com'}>info@deccanlogic.com </a>
                </div>
                <div className='mb-5 ms-md-2'>
                    <div className='theme-font-section-sub-header-lg theme-text-tertiary'>Call us</div>
                    <a className="theme-font-content"
                       style={{color: appTheme.linkStyle.color}}
                       href={"tel:" + 919322213960}>+919322213960</a>
                </div>
                <div className='mb-5 ms-md-2'>
                    <div className='theme-font-section-sub-header-lg theme-text-tertiary'>Address</div>
                    <div className="theme-font-content">DeccanLogic Private Limited<br/>
                        Office # 05, 4th Floor, City Vista - B,
                        Fountain Road, Kharadi, Pune-411014, Maharashtra
                    </div>
                </div>
            </div>
        );
    }

    const renderRaiseTicker = () => {
        return (
            <>
                <div className="d-md-block d-none">
                    <div style={{marginTop: 40}}>
                        <div className='theme-font-semiBold theme-text-black theme-font-page-title text-md-center'
                             style={{marginBottom: 40}}>
                            How can we help?
                        </div>

                        <div className={'d-md-flex theme-font-section-sub-header'}
                             style={{marginBottom: 90}}>
                            <div className='text-start flex-grow-1'
                                 style={{flex: 1, borderRight: '1px solid #A99A9A'}}>
                                <div className='theme-text-tertiary theme-font-section-sub-header-lg mb-3'>
                                    Frequently asked questions
                                </div>
                                <div className="theme-font-content theme-font-normal theme-text-black"
                                     style={{maxWidth: isMobileView ? 300 : ''}}>
                                    Check out our
                                    <span className='theme-text-tertiary px-1 theme-font-semiBold'>FAQ</span>
                                    section for a quick
                                    answer to your questions.
                                </div>
                            </div>
                            {/*<div style={{flex:0.1}}>*/}
                            {/*    <div style={{borderRight: '1px solid #A99A9A'}}/>*/}
                            {/*</div>*/}
                            <div className='d-flex ps-3 flex-grow-1'
                                 style={{flex: 1}}>
                                <div className="text-start">
                                    <div className='theme-text-tertiary theme-font-section-sub-header-lg mb-3'
                                         style={{marginBottom: 40}}>Have question about sales?
                                    </div>
                                    <a href={"/pricing"}>
                                        <div className='theme-font-content mt-0 pt-0'
                                             style={{color: appTheme.linkStyle.color}}>
                                            View Features & Plans
                                        </div>
                                    </a>
                                    <div className='d-md-none d-block my-4'>
                                        <div className='border-bottom'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <div className='theme-font-section-sub-header-lg theme-text-tertiary mb-3'>
                                General inquiries and support requests
                            </div>
                            <div className='theme-font-content theme-text-black'>
                                If you did not find the answer you are looking for please fill out the inquiries
                                and support form.
                            </div>
                        </div>

                        <div className='row'>
                            <div className='flex-grow-1 border-end col-8'>
                                {renderRaiseTicket()}
                            </div>

                            <div className="col-4">
                                {renderContactInfo()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-md-none d-block">
                    <div style={{marginTop: 40}}>
                        <div className='theme-font-semiBold theme-text-black theme-font-page-title text-md-center'
                             style={{marginBottom: 40}}>
                            How can we help?
                        </div>

                        <div className={'d-md-flex theme-font-section-sub-header'}>
                            <div className='text-start'>
                                <div className='theme-text-tertiary theme-font-section-title pb-2'>
                                    Frequently asked questions
                                </div>
                                <div className="theme-font-content" style={{maxWidth: isMobileView ? 300 : 390}}>
                                    Check out our
                                    <span className='theme-text-tertiary px-1 theme-font-semiBold'>FAQ</span>
                                    section for a quick
                                    answer to your questions.
                                </div>
                                <div className='border-bottom my-3'/>
                            </div>
                            <div className='text-start'>
                                <div className='theme-text-tertiary theme-font-section-title pb-2'>
                                    Have question about sales?
                                </div>
                                <a href={"/pricing"}>
                                    <div className='theme-font-content'
                                         style={{color: appTheme.linkStyle.color, marginBottom: 5}}>
                                        View Features & Plans
                                    </div>
                                </a>
                                <div className='d-md-none d-block my-4'>
                                    <div className='border-bottom'/>
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <div className='theme-font-section-title theme-text-tertiary pb-2'>
                                General inquiries and support requests
                            </div>
                            <div className='theme-font-content theme-text-black'>
                                If you did not find the the answer you are looking for please fill out the inquiries
                                and
                                support form.
                            </div>
                        </div>

                        <div className='d-md-flex justify-content-between'>
                            <div className='flex-grow-1'>
                                {renderRaiseTicket()}
                            </div>
                            <div className='border-end'/>
                            {renderContactInfo()}
                        </div>
                    </div>
                </div>
            </>
        )
    };

    return (
        <div className='w-100 bg-white'>
            <div className="block-container">
                {renderRaiseTicker()}
                <div className="footer-border-light"/>
            </div>

            <Footer {...props}/>
        </div>
    );
};

ContactUs.propTypes = {};

const mapStateToProps = state => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(ContactUs));
