import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setPrevLocation} from "../../core/actions/appActions";
import Footer from "../../core/layout/footer/footer";
import "./aboutUs.css";
import "../../assets/css/appTheme.css";
import "../../assets/css/contentApp.css"

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";

function AboutUs(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {isMobileView} = props;
    const renderUI = () => {
        if (isMobileView) {
            return (
                <div>
                    <section id={'about-scuido-mobile'}>
                        <div className="block-container padding-section-vertical">
                            <h4 className=" theme-font-normal theme-font-content-lg theme-font-normal">
                                SCuiDO encapsulates customer data platform principles to deliver a very powerful
                                Multichannel Marketing and Outreach solution. The platform was conceptualized with the
                                aim to enable a wide range of users — be it marketers, startups, businesses, or global
                                enterprises — to leverage hyper-targeting for their marketing initiatives adeptly. With
                                scalable features that align with the individualized needs, objectives, and budget of
                                its users, SCuiDO enables companies across industries and geographies to harness
                                advanced segmentation to power their marketing and outreach campaigns.
                            </h4>
                            <h4 className=" theme-font-normal theme-font-content-lg mb-0 mt-3">
                                SCuiDO is an offering by DeccanLogic, a company dedicated to providing businesses of all
                                sizes with top-notch technology solutions to help them reach their full potential and
                                stay ahead of the curve.
                            </h4>
                        </div>
                    </section>
                    <section id={'customer-centricity-trust-mobile'}>
                        <div className="block-container theme-bg-primary padding-section-vertical">
                            <h2 className="theme-font-section-title theme-text-secondary padding-section-header-bottom mb-0">
                                Core values & guiding principles
                            </h2>
                            <div className="padding-section-header-bottom">
                                <h3 className="theme-text-on-primary-bg theme-font-section-sub-header theme-font-normal">
                                    1. Customer-centricity
                                </h3>
                                <h4 className=" theme-font-normal theme-font-content-lg theme-text-on-primary-bg mb-0 theme-font-normal">
                                    We prioritize our customers preferences and expectations and are always seeking
                                    new ways to improve the platform to meet their evolving needs.
                                </h4>
                            </div>
                            <h3 className="theme-text-on-primary-bg theme-font-section-sub-header theme-font-normal">
                                2. Trust
                            </h3>
                            <h4 className=" theme-font-normal theme-font-content-lg theme-text-on-primary-bg mb-0 theme-font-normal">
                                Safeguarding our customers data is a critical component of our technology services.
                                We implement strong security measures to protect data from threats, breaches, and
                                unauthorized access. This includes transparency about how data is collected, stored, and used.
                            </h4>
                        </div>
                        <div>
                            <img loading="lazy"
                                 src={s3BaseURL + '/images/general/coreValueSide.png'}
                                 style={{
                                     height: '300px',
                                     width: '100%',
                                     aspectRatio: (829 / 300)
                                 }}
                                 className="img-fluid"
                                 alt={'An image showing user is giving highest rating for customer statisfaction.'}/>
                        </div>
                    </section>
                    <section id={'team-mobile'}>
                        <div className='block-container padding-section-vertical'>
                            <div className="mt-1" style={{border: "1px solid #000000"}}/>
                            <div className="padding-section-vertical">
                                <h2 className="theme-font-section-title padding-section-header-bottom mb-0 theme-text-primary">
                                    Founding team
                                </h2>
                                <div className="d-flex padding-section-header-bottom">
                                    <div style={{flex: "0.8"}}>
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/general/CEOProfile.png'}
                                             height={124}
                                             width={128}
                                             alt={'An image of founder and CEO.'}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-center px-2"
                                        style={{flex: "3"}}>
                                        <h4 className="theme-font-section-sub-header theme-text-primary mb-0">
                                            Sandeep Tendulkar
                                        </h4>
                                        <h4 className="theme-font-section-sub-header theme-text-tertiary mb-0">
                                            Founder & CEO.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className=" theme-font-normal theme-font-content-lg padding-section-header-bottom mb-0 theme-font-normal">
                                    With 20 years in the corporate sector and 5+ years in startups, Sandeep has a
                                    wealth of knowledge in the technology industry. He has led global teams that
                                    have been instrumental in developing and implementing cutting-edge technologies
                                    for internationally esteemed financial institutions. At DeccanLogic, he has conceived
                                    and deployed technology products and services that help businesses streamline
                                    operations, tackle complex challenges, and drive growth. He is committed to making
                                    technology solutions accessible to everyone, everywhere, with intelligent platforms like SCuiDO.
                                </h4>

                                <div style={{border: "1px solid #000000"}}/>
                                <div className="padding-section-header-bottom"/>
                                <div className="d-flex padding-section-header-bottom">
                                    <div style={{flex: "0.8"}}>
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/general/CTOProfile.png'}
                                             height={124}
                                             width={128}
                                             alt={'An image of Partner & CTO.'}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-center px-2"
                                        style={{flex: "3"}}>
                                        <h4 className="theme-font-section-sub-header theme-text-primary mb-0">
                                            Abhay Parab
                                        </h4>
                                        <h4 className="theme-font-section-sub-header theme-text-tertiary mb-0">
                                            Partner & CTO.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className=" theme-font-normal theme-font-content-lg mb-0 theme-font-normal">
                                    Abhay has been at the forefront of the data engineering, AI (Artificial
                                    Intelligence), big data, and cloud computing niche for over 20 years. He has
                                    served as the Head of Data Engineering at Tata Digital and as the Chief Architect
                                    for Cuddle.ai (Crux Intelligence), helming pioneering solutions like Golden Consumer View, a
                                    data platform for Tata Super App, and Concordia, a big data harmonization and
                                    integration platform for Fractal Analytics. He is passionate about building
                                    state-of-the-art yet affordable tech solutions that enable businesses to improve
                                    overall efficiency, productivity, and profitability in today's data-driven landscape.
                                </h4>
                            </div>
                            <div style={{border: "1px solid #000000"}}/>
                        </div>
                    </section>
                </div>
            )
        }
        return (
            <div>
                <section id={'about-scuido'}>
                    <div className="padding-section-vertical padding-block-right padding-block-left">
                        <h4 className="theme-font-content-lg theme-font-normal">
                            SCuiDO encapsulates customer data platform principles to deliver a very powerful
                            Multichannel Marketing and Outreach solution. The platform was conceptualized with the aim
                            to enable a wide range of users — be it marketers, startups, businesses, or global
                            enterprises — to leverage hyper-targeting for their marketing initiatives adeptly. With
                            scalable features that align with the individualized needs, objectives, and budget of its
                            users, SCuiDO enables companies across industries and geographies to harness advanced
                            segmentation to power their marketing and outreach campaigns.
                        </h4>
                        <h4 className="theme-font-content-lg mb-0 mt-3 theme-font-normal">
                            SCuiDO is an offering by DeccanLogic, a company dedicated to providing businesses of all
                            sizes with top-notch technology solutions to help them reach their full potential and stay
                            ahead of the curve.
                        </h4>
                    </div>
                </section>

                <section id={'customer-centricity-trust'}>
                    <div className=" d-flex ">
                        <div className="d-flex align-items-center theme-bg-primary padding-section-vertical"
                             style={{flex: "1.2"}}>
                            <div className="pe-5 padding-block-left">
                                <h2 className="theme-font-section-title theme-text-white padding-section-header-bottom mb-0">
                                    Core values & guiding principles
                                </h2>
                                <div className="padding-section-header-bottom">
                                    <h3 className="theme-font-content-lg theme-text-on-primary-bg mb-0 theme-font-normal">
                                        1. Customer-centricity
                                    </h3>
                                    <h4 className="theme-font-content-lg theme-text-on-primary-bg theme-font-normal  mb-0 theme-font-normal">
                                        We prioritize our customers preferences and expectations and are always seeking new
                                        ways to improve the platform to meet their evolving needs.
                                    </h4>
                                </div>
                                <div>
                                    <h3 className="theme-font-content-lg theme-text-on-primary-bg mb-0 theme-font-normal">
                                        2. Trust
                                    </h3>
                                    <h4 className="theme-font-content-lg theme-text-on-primary-bg  mb-0 theme-font-normal">
                                        Safeguarding our customers data is a critical component of our technology
                                        services. We implement strong security measures to protect data from threats, breaches, and
                                        unauthorized access. This includes transparency about how data is collected, stored, and used.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: "1"}}>
                            <img loading="lazy"
                                 src={s3BaseURL + '/images/general/coreValueSide.png'}
                                 style={{
                                     height: '100%',
                                     width: '100%',
                                     aspectRatio: (500 / 556)
                                 }}
                                 className="img-fluid"
                                 alt={'An image showing user is giving highest rating for customer statisfaction.'}/>
                        </div>
                    </div>
                </section>

                <section id={'team'}>
                    <div className='block-container padding-section-vertical'>
                        <h2 className="theme-font-section-title theme-text-primary padding-section-header-bottom mb-0 theme-text-primary">
                            Founding team
                        </h2>
                        <div className="d-flex ">
                            <div className="pe-5 " style={{flex: "1"}}>
                                <div className="d-flex padding-section-header-bottom">
                                    <div style={{flex: "0.8"}}>
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/general/CEOProfile.png'}
                                             height={124}
                                             width={128}
                                             alt={'An image of founder and CEO.'}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-center"
                                         style={{flex: "3"}}>
                                        <h4 className="theme-font-section-sub-header theme-text-primary mb-0">
                                            Sandeep Tendulkar
                                        </h4>
                                        <h4 className="theme-font-section-sub-header theme-text-tertiary mb-0">
                                            Founder & CEO.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className="theme-font-content-lg  mb-0 theme-font-normal">
                                    With 20 years in the corporate sector and 5+ years in startups, Sandeep has a wealth
                                    of knowledge in the technology industry. He has led global teams that have been
                                    instrumental in developing and implementing cutting-edge technologies for
                                    internationally esteemed financial institutions. At DeccanLogic, he has conceived
                                    and deployed technology products and services that help businesses streamline
                                    operations, tackle complex challenges, and drive growth. He is committed to making
                                    technology solutions accessible to everyone, everywhere, with intelligent platforms
                                    like SCuiDO.
                                </h4>
                            </div>
                            <div style={{flex: "1"}}>
                                <div className="d-flex padding-section-header-bottom">
                                    <div style={{flex: "0.8"}}>
                                        <img loading="lazy"
                                             src={s3BaseURL + '/images/general/CTOProfile.png'}
                                             height={124}
                                             width={128}
                                             alt={'An image of Partner & CTO.'}/>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-center"
                                         style={{flex: "3"}}>
                                        <h4 className="theme-font-section-sub-header theme-text-primary mb-0">
                                            Abhay Parab
                                        </h4>
                                        <h4 className="theme-font-section-sub-header theme-text-tertiary mb-0">
                                            Partner & CTO.
                                        </h4>
                                    </div>
                                </div>
                                <h4 className="theme-font-content-lg  mb-0 theme-font-normal">
                                    Abhay has been at the forefront of the data engineering, AI (Artificial
                                    Intelligence), big data, and cloud computing niche for over 20 years.
                                    He has served as the Head of Data Engineering at Tata Digital and as the Chief Architect
                                    for Cuddle.ai (Crux Intelligence), helming pioneering solutions like Golden Consumer View, a data
                                    platform for Tata Super App, and Concordia, a big data harmonization and integration
                                    platform for Fractal Analytics. He is passionate about building state-of-the-art yet
                                    affordable tech solutions that enable businesses to improve overall efficiency,
                                    productivity, and profitability in today's data-driven landscape.
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div style={{border: "1px solid #000000", marginBottom: "50px"}}/>
                </section>

            </div>
        )
    };

    return (
        <>
            <div className="w-100">
                <section id={'about-us-banner'}>
                    <div className= {isProdEnvironment ? " about-us-banner-bg-prod d-flex align-items-center justify-content-center " :
                        " about-us-banner-bg d-flex align-items-center justify-content-center"}>

                        <div className="text-center">
                            <h1 className="theme-font-page-title theme-text-on-primary-bg">
                                About Us
                            </h1>
                        </div>
                    </div>
                </section>
                {renderUI()}
                <Footer {...props}/>
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AboutUs));
