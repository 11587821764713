import React, {useEffect} from 'react';
import "./dataSetup.css"
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Footer from "../../core/layout/footer/footer";
import {redirectTo} from "../../utils/helper";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function DataSetup(props) {
    const {isMobileView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <div>
                    <section id={'data-expertise-mobileView'}>
                        <div className='padding-section-vertical'>
                            <h2 className='section-title theme-text-black text-center padding-section-header-bottom block-container'>
                                Your data, our expertise
                            </h2>
                            <div className='padding-section-header-bottom block-container'>
                                <h4 className='theme-font-content-lg theme-font-normal'>
                                    Utilize your data to supercharge your campaigns. By automating the import,
                                    enabling the straightforward configuration and effortless linking of your
                                    vital databases, SCuiDO helps you prepare your business information for
                                    analyzing and segmenting.
                                </h4>
                            </div>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/dataImport/secondaryBackground_mobile.png'}
                                 height={"200px"}
                                 width={"992px"}
                                 className="img-fluid"
                                 alt={'An illustration showing a person working on a computer with data flowing into it, representing the concept of utilizing data to supercharge campaigns.'}/>
                        </div>
                        <div className='block-container'>
                            <div className='theme-border'/>
                        </div>
                    </section>

                    <section id={'data-import-mobileView'}>
                        <div className='block-container padding-section-vertical'>
                            <div className='d-flex flex-column justify-content-center'>
                                <div className='text-center padding-section-header-bottom'>
                                    <h2 className='section-title'>
                                        Convenient and efficient data import and compilation
                                    </h2>
                                </div>
                                <div className="padding-section-header-bottom">
                                    <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                        SCuiDO allows the quick and hassle-free transfer of data from your business systems,
                                        such as CRM, marketing applications, data warehouse, and others. You can schedule
                                        automated imports using FTP or AWS S3 servers or — upload or drag and drop your data
                                        in CSV and XLS/XLSX formats.
                                    </h4>
                                </div>
                            </div>
                            <div className='padding-section-header-bottom text-center'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/dataImport_mobile.png'}
                                     width={'350px'}
                                     height={'490px'}
                                     className="img-fluid"
                                     alt={'An illustration demonstrating SCuiDO\'s versatility in data acceptance, including CSV, XLS, and XLSX formats.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <div className="padding-section-header-bottom">
                                    <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                        Don't waste hours structuring your data, bring your data as is. SCuiDO recognizes
                                        and accepts all data formats, so you can directly import your databases as are —
                                        irrespective of the complexity, volume, and format — without changing a single data field.
                                    </h4>
                                </div>
                            </div>
                            <div className='padding-section-header-bottom text-center'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/uploadData.png'}
                                     width={'437px'}
                                     height={'520px'}
                                     className="img-fluid"
                                     alt={'An illustration showcasing SCuiDO\'s advanced feature of automatically identifying fields and data types from uploaded files.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <div className="padding-section-header-bottom">
                                    <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                        SCuiDO's most impressive feature is that you can import databases from multiple
                                        sources, such as your sales data, website visitors, transactional data, and other
                                        essential files.
                                    </h4>
                                </div>
                            </div>
                            <div className='padding-section-header-bottom'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/multipleSource.png'}
                                     width={'874px'}
                                     height={'426px'}
                                     className="img-fluid"
                                     alt={'An image showing SCuiDO\'s capability to import databases from various sources, including sales data, website visitors, transactional data, and other crucial files.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                    <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                        Business data is dynamic, it changes and evolves with time. Therefore, SCuiDO allows
                                        you to continuously add new data and change your data structure as often as needed.
                                    </h4>
                            </div>
                        </div>
                    </section>

                    <section id={'optimize-data-mobileView'}>
                        <div className='block-container theme-bg-secondary padding-section-vertical'>
                            <div className='d-flex flex-column justify-content-center'>
                                <div className='text-center padding-section-header-bottom'>
                                    <h2 className='section-title'>
                                        Optimize data by establishing completeness and accuracy
                                    </h2>
                                </div>
                                <div className='padding-section-header-bottom'>
                                    <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                        SCuiDO inspects your files to provide you with an overview of your data. But that's
                                        not all. You can view and modify data fields, spot and correct errors in the format,
                                        edit each column's data type (text, date, or number), and exclude irrelevant data fields.
                                    </h4>
                                </div>
                            </div>
                            <div className='padding-section-header-bottom text-center'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/progressOverview.png'}
                                     width={'400px'}
                                     height={'250px'}
                                     className="img-fluid"
                                     alt={'An illustration displaying SCuiDO inspecting a file, ensuring data quality and integrity.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center padding-section-header-bottom'>
                                <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                    Once you format your content, you can also view a sample of your data file, enabling
                                    you to fine-tune and structure it further.
                                </h4>
                            </div>
                            <div className='padding-section-header-bottom'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/sampleRecord.png'}
                                     width={'854px'}
                                     height={'374px'}
                                     className="img-fluid"
                                     alt={'An image displaying sample records from the uploaded data.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center padding-section-header-bottom'>
                                <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                    What's more, SCuiDO can detect and delete duplicate records. Simply specify the
                                    unique attributes of a record, such as customer id, phone number, or email, using
                                    the 'unique combination' feature — with the option to automate the elimination of
                                    duplicate records when new data is imported.
                                </h4>
                            </div>
                            <div className='padding-section-header-bottom text-center'>
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/defineUniqueCombination.png'}
                                     width={'450px'}
                                     height={'326px'}
                                     className="img-fluid"
                                     alt={'An image illustrating the process of setting unique combinations.'}/>
                            </div>
                            <div className='d-flex flex-column justify-content-center'
                                 style={{paddingBottom: 30}}>
                                <h4 className='theme-font-content-lg theme-text-justify theme-font-normal'>
                                    With these easy steps, you can add, inspect, and structure as many databases as you
                                    need to create customer, prospect, lead, event, and master content files for your
                                    campaigns.
                                </h4>
                            </div>
                        </div>
                    </section>

                    <section id={'home-services-secondary-footer-mobile'}>
                        <div className='block-container padding-section-vertical secondary-banner-bg'>
                            <div className='text-center px-2'>
                                <h4 className='section-title theme-text-white theme-font-bold pb-60'>
                                    Want to take Your Customer Outreach to the Next Level?
                                </h4>
                            </div>
                            <div className='d-flex justify-content-center '>
                                <button onClick={() => redirectTo('/app/user/register')}
                                        className="footer-sign-up-button">
                                        Sign Up
                                </button>
                                <div style={{marginLeft: 20}}>
                                    <button className="footer-free-demo-button"
                                            onClick={() => redirectTo('/contact-us')}>
                                            Get A Free Demo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }

        return (
            <div>
                <section id={'data-expertise-desktopView'}>
                    <div className="d-flex align-items-center">
                        <div className='w-50 theme-bg-white padding-section-vertical'
                             style={{flex:1}}>
                            <div className="padding-block-left padding-block-right">
                                <h2 className='theme-font-section-title theme-text-black'
                                    style={{paddingBottom: 24}}>
                                    Your data, our expertise
                                </h2>
                                <h4 className='theme-font-content-lg theme-font-normal text-start'>
                                    Utilize your data to supercharge your campaigns. By automating the import, enabling
                                    the straightforward configuration and effortless linking of your vital databases, SCuiDO
                                    helps you prepare your business information for analyzing and segmenting.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end"
                             style={{flex:1}}>
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/dataImport/secondaryBackground.jpg'}
                                 className="img-fluid"
                                 alt={'An illustration showing a person working on a computer with data flowing into it, representing the concept of utilizing data to supercharge campaigns.'}/>
                        </div>
                    </div>
                </section>

                <section id={'data-import-desktopView'}>
                    <div className='block-container padding-section-vertical'>
                        <div className='text-center padding-section-header-bottom'>
                            <h2 className='theme-font-section-title '>
                                Convenient and efficient data import and compilation
                            </h2>
                        </div>
                        <div className="padding-section-header-bottom">
                            <h4 className='theme-font-content-lg theme-font-normal'>
                                SCuiDO allows the quick and hassle-free transfer of data from your business systems, such as
                                CRM, marketing applications, data warehouse, and others. You can schedule automated imports
                                using FTP or AWS S3 servers or — upload or drag and drop your data in CSV and XLS/XLSX formats.
                            </h4>
                        </div>
                        <div className="d-flex padding-section-header-bottom justify-content-center">
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/dataImport/dataImport.png'}
                                 width={'855px'}
                                 height={'444px'}
                                 className="img-fluid"
                                 alt={'An illustration demonstrating SCuiDO\'s versatility in data acceptance, including CSV, XLS, and XLSX formats.'}/>
                        </div>
                        <div className="padding-section-header-bottom">
                            <h4 className='theme-font-content-lg theme-font-normal'>
                                Don't waste hours structuring your data, bring your data as is. SCuiDO recognizes and
                                accepts all data formats, so you can directly import your databases as are — irrespective of
                                the complexity, volume, and format — without changing a single data field.
                            </h4>
                        </div>
                        <div className="padding-section-header-bottom d-flex justify-content-center">
                            <img loading={'lazy'}
                                 src={s3BaseURL + '/images/dataImport/uploadData.png'}
                                 width={'534px'}
                                 height={'560px'}
                                 className="img-fluid"
                                 alt={'An illustration showcasing SCuiDO\'s advanced feature of automatically identifying fields and data types from uploaded files.'}/>
                        </div>
                        <div className="row padding-section-header-bottom">
                            <div className="col-7">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <h4 className='theme-font-content-lg theme-font-normal'>
                                        SCuiDO's most impressive feature is that you can import databases from multiple sources,
                                        such as your sales data, website visitors, transactional data, and other essential files.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-5 ">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/multipleSource.png'}
                                     width={'545px'}
                                     height={'242px'}
                                     className="img-fluid"
                                     alt={'An image showing SCuiDO\'s capability to import databases from various sources, including sales data, website visitors, transactional data, and other crucial files.'}/>
                            </div>
                        </div>
                        <div>
                            <h4 className='theme-font-content-lg theme-font-normal'>
                                Business data is dynamic, it changes and evolves with time. Therefore, SCuiDO allows you to
                                continuously add new data and change your data structure as often as needed.
                            </h4>
                        </div>
                    </div>
                </section>

                <section id={'optimize-data-desktopView'}>
                    <div className="padding-section-vertical block-container"
                         style={{backgroundColor: "#D2E7FF80"}}>
                        <div className='text-center padding-section-header-bottom'>
                            <h2 className='theme-font-section-title'>
                                Optimize data by establishing completeness and accuracy
                            </h2>
                        </div>
                        <div className="row padding-section-header-bottom">
                            <div className="col-9  d-flex align-items-center">
                                <h4 className='theme-font-content-lg theme-font-normal'>
                                    SCuiDO inspects your files to provide you with an overview of your data. But that's not
                                    all. You can view and modify data fields, spot and correct errors in the format, edit
                                    each column's data type (text, date, or number), and exclude irrelevant data fields.
                                </h4>
                            </div>
                            <div className="col-3">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <img loading={'lazy'}
                                         src={s3BaseURL + '/images/dataImport/progressOverview.png'}
                                         width={'276px'}
                                         height={'167px'}
                                         className="img-fluid"
                                         alt={'An illustration displaying SCuiDO inspecting a file, ensuring data quality and integrity.'}/>
                                </div>
                            </div>
                        </div>
                        <div className="row padding-section-header-bottom">
                            <div className="col-5">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/sampleRecord.png'}
                                     width={'526px'}
                                     height={'230px'}
                                     className="img-fluid"
                                     alt={'An image displaying sample records from the uploaded data.'}/>
                            </div>
                            <div className="col-7  d-flex align-items-center">
                                <h4 className='theme-font-content-lg theme-font-normal'>
                                    Once you format your content, you can also view a sample of your data file, enabling you
                                    to fine-tune and structure it further.
                                </h4>
                            </div>
                        </div>
                        <div className="row padding-section-header-bottom">
                            <div className="col-8 d-flex align-items-center">
                                <h4 className='theme-font-content-lg theme-font-normal'>
                                    What's more, SCuiDO can detect and delete duplicate records. Simply specify the unique
                                    attributes of a record, such as customer id, phone number, or email, using the 'unique
                                    combination' feature — with the option to automate the elimination of duplicate records
                                    when new data is imported.
                                </h4>
                            </div>
                            <div className="col-4 ">
                                <img loading={'lazy'}
                                     src={s3BaseURL + '/images/dataImport/defineUniqueCombination.png'}
                                     width={'276px'}
                                     height={'200px'}
                                     className="img-fluid"
                                     alt={'An image illustrating the process of setting unique combinations.'}/>
                            </div>
                        </div>
                        <div className='text-center'>
                            <h4 className='theme-font-content-lg theme-font-normal'>
                                With these easy steps, you can add, inspect, and structure as many databases as you need to
                                create customer, prospect, lead, event, and master content files for your campaigns.
                            </h4>
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"education-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                               className="footer-sign-up-button">
                                Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                    Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>)
    };

    return (
        <div className="">
            <section id={'data-setup-banner'}>
                <div className="banner-background d-flex align-items-center justify-content-center px-5">
                    <div className="text-center">
                        {!isMobileView && <div>
                            <h1 className="theme-font-page-title theme-text-primary"
                                style={{marginBottom: '48px'}}>
                                Data Setup
                            </h1>
                            <h4 className="theme-font-section-sub-header theme-font-normal">
                                Capitalize fully on your business's most valuable asset
                            </h4>
                        </div>}
                        {isMobileView && <div>
                            <h1 className="theme-font-page-title theme-text-primary"
                                style={{marginBottom: '48px'}}>
                                Data Setup
                            </h1>
                            <h4 className="theme-font-section-sub-header theme-font-normal">
                                Capitalize fully on your business's most valuable asset
                            </h4>
                        </div>}
                    </div>
                </div>
            </section>
            {renderUI()}
            <Footer {...props}/>
        </div>);
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(DataSetup));

