import React from 'react';
import Tick from "../../assets/img/success.PNG";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";


function BetaAcceptRequestView(props) {
    const{isXSView}=props;
    return (
        <div className='d-flex align-items-center justify-content-center h-100'>
        <div className="d-flex justify-content-center align-items-center w-100">
            <div className={"w-100 block-container"}>
                <div className={"bg-white panel p-3 d-flex flex-column justify-content-center"}
                     style={{height: 'calc(100vh - 150px)'}}>
                    <div className={"pt-3"}>
                        <div className={'d-flex justify-content-center'}
                             style={{color: '#E18600',
                                 font: 'normal normal medium 26px/31px Barlow',
                                 opacity: 1,
                                 fontWeight: 600,
                                 fontSize: isXSView ? '24px' : '30px',
                                 marginBottom: '8px',
                                 paddingBottom: '16px'}}>
                            Thank You
                        </div>
                        <div className={"pb-3 justify-content-center d-flex align-item-center"}>
                            <img id={'PurchaseSuccessful-icon'}
                                 src={Tick}
                                 alt={'icon'}
                                 className='img-fluid '
                                 style={{maxHeight: '150px'}}/>
                        </div>
                        <div className={'d-flex justify-content-center'}
                             style={{color: '#8B8B8B',
                            font: 'normal normal medium 26px/31px Barlow',
                            opacity: 1,
                            fontWeight: 500,
                            fontSize: isXSView? '16px': '18px'}}>
                            Thank you for submitting your request.
                        </div>
                        <div className={'d-flex justify-content-center'}
                             style={{color: '#8B8B8B',
                            font: 'normal normal medium 26px/31px Barlow',
                            opacity: 1,
                            fontWeight: 500,
                            fontSize: isXSView? '16px': '18px',
                            marginBottom:'8px',
                            paddingBottom:'16px'}}>
                            Our team will contact you shortly.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})
(withTranslation('translations')(BetaAcceptRequestView));

