import React, {useEffect} from "react";
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {redirectTo} from "../../utils/helper";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";


function FinancialServices(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'financial-banner-mobile'}
                             className="section-padding-bottom ">
                        <div className={isProdEnvironment ? "d-flex financial-banner-bg-prod banner-bg-height py-5":"d-flex financial-banner-bg banner-bg-height py-5"}>
                            <div className="d-flex align-items-center block-container ">
                                <div>
                                    <h1 className='page-title theme-font-semiBold '>
                                        Customer Outreach for
                                        <br/>
                                        <span className="theme-font-bolder px-1"
                                              style={{color: 'var(--tertiary)'}}>
                                             Financial Institutions
                                        </span>
                                    </h1>
                                    <h3 className="py-4 section-header theme-font-normal"
                                        style={{width: (isXSView ? "" : "60%")}}>
                                        Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and
                                        Sales Teams
                                    </h3>
                                    <a href={"/contact-us"}>
                                        <button className="sign-up-button">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'financial-service-leading-strategy-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center" >
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Optimize Your Lending Strategy
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                        Matchmaking made easy – perfect audience for your loans!
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Hyper-Segmentation
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Utilize SCuiDO's precise segmentation to match ideal customer profiles for
                                        various loans (e.g., mortgages, personal loans, vehicle loans).
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Risk Profiling
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Combine account history and risk profiling to gain a competitive edge.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Identify Your Best Customers
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Use this powerful tool to pinpoint your organization's ideal customers
                                        and foster long-term relationships.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Create High-Quality Assets
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Craft top-notch assets for your organization while minimizing NPAs.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        See your new product soar in sales!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/optimizeYour.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section id={'financial-service-reward-program-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center w-100">
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black theme-font-bold'>
                                        Tailor-Made Rewards Programs!
                                    </h2>
                                    <h3 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        Explore the ability to roll out truly unique rewards programs by blending.
                                    </h3>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Account Activity Rewards
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Craft rewards programs customized to account balance and frequency of use.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Demographic Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Personalize rewards based on demographic data like age, income, and
                                        location.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Survey-Powered Perks
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Utilize survey data to offer rewards aligned with customers' preferences and
                                        interests.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Delight Your Customers with a Sense of Reward!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/tailormadeRewards.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section id={'financial-service-cross-sell-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center" >
                                <div className="block-container">
                                    <h3 className="section-header pb-2 theme-font-normal">
                                        Cross-sell
                                    </h3>
                                    <h2 className='section-title theme-font-bold'>
                                        Tailored Investment Solutions
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                        Investments don't have to be one-size-fits-all. Elevate your customers'
                                        portfolios with our
                                        customized investment options.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Salaried Professional Prosperity
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Tailored mutual fund accounts and retirement plans for salaried
                                        professionals.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        High Net-Worth Wealth Care
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Tailored investment solutions for entrepreneurs and business owners, focusing on
                                        growth and tax-efficiency.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Entrepreneurial Empowerment
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Create portfolios that align with environmentally conscious investors.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Education Savings Plans
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Specialized investment plans for parents and families, earmarked for
                                        education expenses like college tuition and school fees.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Offer irresistible choices to precisely matched customer segments!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/tailoredInvestment.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section id={'financial-service-newsletter-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center">
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-2 '>
                                        One Newsletter Fits All? Rethink It!
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal">
                                        Imagine the impact - Custom newsletters for your perfectly tailored customer
                                        segments!
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Precise Targeting
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Create specific target segments based on customer preferences or activities.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Personalized Newsletters
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Start delivering newsletters customized for each segment.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-6 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/oneNewsletter.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section id={'financial-service-supercharged-promotions-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center">
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-2'>
                                        Supercharged Promotions for Education Events!
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal">
                                        Your organization will run many seminars, workshops, financial literacy
                                        programs.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Perfect Audience
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Effortlessly select the ideal attendees for every event.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Cutting-Edge Promotion
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Implement a promotional strategy that sets your events apart from the
                                        rest.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Catapult your event to success!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/superchargedPromotions.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section id={'financial-service-just-in-time-outreach-mobile'}
                             className="section-padding-bottom">
                            <div className="d-flex align-items-center"
                                 style={{flex: 1}}>
                                <div className="block-container">
                                    <h2 className='section-title theme-text-black pb-2'>
                                        Just-In-Time Outreach
                                    </h2>
                                    <h4 className="sub-section-title pb-40 theme-font-normal">
                                        The market is always in flux! Navigate the ever-changing financial landscape,
                                        from crises to
                                        innovations.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Uncertainty-Proof Strategy
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Rapid Response MarketingSeize control of your marketing approach and adapt
                                        seamlessly to shifting circumstances.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Rapid Response Marketing
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Empower your teams to swiftly and effectively promote products to the
                                        right audience.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Stay ahead of your competition!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0" >
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/financial/justIn.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                    </section>

                    <section className='padding-section-vertical secondary-banner-bg'
                             id={"financial-service-secondary-footer-mobile"}>
                        <h4 className='theme-text-white mb-0 text-center theme-font-bold section-title'
                            style={{paddingBottom: 60}}>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                   className="footer-sign-up-button">
                                    Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                <button onClick={() => redirectTo('/contact-us')}
                                      className="footer-free-demo-button">
                                        Get A Free Demo
                                </button>
                            </div>
                        </div>
                    </section>

                </>
            )
        }

        return (
            <div>
                <section id={'financial-service-banner-desktop'}
                         className="section-padding-bottom">
                    <div className={isProdEnvironment ? "d-flex financial-banner-bg-prod":"d-flex financial-banner-bg"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container "
                                 style={{width: "75%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer Outreach for
                                    <span className="theme-font-bolder px-1"
                                          style={{color: 'var(--tertiary)'}}>
                                       Financial Institutions
                                    </span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    Uncover Simple Yet Game-Changing Outreach Strategies for Your Marketing and Sales
                                    Teams
                                </h3>
                                <a href={"/contact-us"}>
                                    <button className="sign-up-button">
                                        Contact Us
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'financial-service-lending-strategy'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/optimizeYour.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Optimize Your Lending Strategy
                                </h2>
                                <h3 className="sub-section-title theme-font-normal pt-2 pb-40">
                                    Matchmaking made easy – perfect audience for your loans!
                                </h3>
                                <h3 className="section-sub-header  pt-2">
                                    Hyper-Segmentation
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Utilize SCuiDO's precise segmentation to match ideal customer profiles for
                                    various loans (e.g., mortgages, personal loans, vehicle loans).
                                </h4>
                                <h3 className="section-sub-header">
                                    Risk Profiling
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Combine account history and risk profiling to gain a competitive edge.
                                </h4>
                                <h3 className="section-sub-header">
                                    Identify Your Best Customers
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Use this powerful tool to pinpoint your organization's ideal customers
                                    and foster long-term relationships.
                                </h4>
                                <h3 className="section-sub-header">
                                    Create High-Quality Assets
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Craft top-notch assets for your organization while minimizing NPAs.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    See your new product soar in sales!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'financial-service-reward-program-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black '>
                                    Tailor-Made Rewards Programs!
                                </h2>
                                <h3 className="sub-section-title theme-font-normal paragraph-padding pt-2">
                                    Explore the ability to roll out truly unique rewards programs by blending.
                                </h3>
                                <h3 className="section-sub-header">
                                    Account Activity Rewards
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Craft rewards programs customized to account balance and frequency of use.
                                </h4>
                                <h3 className="section-sub-header">
                                    Demographic Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Personalize rewards based on demographic data like age, income, and
                                    location.
                                </h4>
                                <h3 className="section-sub-header">
                                    Survey-Powered Perks
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Utilize survey data to offer rewards aligned with customers' preferences and
                                    interests.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Delight Your Customers with a Sense of Reward!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/tailormadeRewards.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'financial-service-cross-sell-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/tailoredInvestment.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h3 className="section-header pb-2">
                                    Cross-Sell
                                </h3>
                                <h2 className=' section-title '>
                                    Tailored Investment Solutions
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal pt-2">
                                    Investments don't have to be one-size-fits-all. Elevate your customers' portfolios
                                    with our
                                    customized investment options.
                                </h4>
                                <h3 className="section-sub-header">
                                    Salaried Professional Prosperity
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Tailored mutual fund accounts and retirement plans for salaried
                                    professionals.
                                </h4>
                                <h3 className="section-sub-header">
                                    High Net-Worth Wealth Care
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Tailored investment solutions for entrepreneurs and business owners, focusing on
                                    growth and tax-efficiency.
                                </h4>
                                <h3 className="section-sub-header">
                                    Entrepreneurial Empowerment
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Create portfolios that align with environmentally conscious investors.
                                </h4>
                                <h3 className="section-sub-header">
                                    Education Savings Plans
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Specialized investment plans for parents and families, earmarked for
                                    education expenses like college tuition and school fees.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Offer irresistible choices to precisely matched customer segments!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'financial-service-newsletter-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    One Newsletter Fits All? Rethink It!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Imagine the impact - Custom newsletters for your perfectly tailored customer
                                    segments!
                                </h4>
                                <h3 className="section-sub-header">
                                    Precise Targeting
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Create specific target segments based on customer preferences or activities.
                                </h4>
                                <h3 className="section-sub-header">
                                    Personalized Newsletters
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                    Start delivering newsletters customized for each segment.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/oneNewsletter.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'financial-service-supercharge-promotion-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/superchargedPromotions.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Supercharged <span style={{color: 'var(--tertiary)'}}> Promotions </span> for
                                    Education Events!
                                </h2>
                                <h4 className="sub-section-title pb-40 theme-font-normal">
                                    Your organization will run many seminars, workshops, financial literacy programs.
                                </h4>
                                <h3 className="section-sub-header">
                                    Perfect Audience
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Effortlessly select the ideal attendees for every event.
                                </h4>
                                <h3 className="section-sub-header">
                                    Cutting-Edge Promotion
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Implement a promotional strategy that sets your events apart from the
                                    rest.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Catapult your event to success!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'financial-service-just-in-time-outreach-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2'>
                                    Just-In-Time Outreach
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    The market is always in flux! Navigate the ever-changing financial landscape, from
                                    crises to
                                    innovations.
                                </h4>
                                <h3 className="section-sub-header">
                                    Uncertainty-Proof Strategy
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Rapid Response MarketingSeize control of your marketing approach and adapt
                                    seamlessly to shifting circumstances.
                                </h4>
                                <h3 className="section-sub-header">
                                    Rapid Response Marketing
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Empower your teams to swiftly and effectively promote products to the
                                    right audience.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Stay ahead of your competition!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/financial/justIn.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"financial-service-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center padding-block-bottom'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>

                    <div className='d-flex justify-content-center '>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button onClick={() => redirectTo('/contact-us')}
                                    className="footer-free-demo-button">
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(FinancialServices));
