import React, {useEffect} from "react";
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {redirectTo} from "../../utils/helper";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;
const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PROD";


function RealEstate(props) {
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <div className="w-100 ">
                    <section id={'real-estate-banner-mobile'} className="section-padding-bottom">
                        <div className={isProdEnvironment ? "real-estate-banner-bg-prod d-flex align-items-center banner-bg-height py-5":
                            "d-flex align-items-center real-estate-banner-bg banner-bg-height py-5"}>
                            <div className="block-container ">
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer Outreach for
                                    <br/>
                                    <span className="theme-font-bolder"
                                                                style={{color: 'var(--tertiary)'}}>  Real Estate Agencies </span>
                                </h1>
                                <h3 className="py-4 section-header theme-font-normal"
                                    style={{width: (isXSView ? "" : "60%")}}>
                                    We're a tech company partnering with startups and businesses to turn
                                    forward-thinking ideas into hyper-growth solutions
                                </h3>
                                <a href={"/contact-us"}>
                                    <button className="sign-up-button">
                                        Contact Us
                                    </button>
                                </a>
                            </div>
                        </div>

                    </section>

                    <section id={'real-estate-attract-more-buyers-mobile'}
                             className="section-padding-bottom">
                        <div>
                            <div className="block-container">
                                <h3 className="section-header theme-font-normal pb-2">
                                    Attract More Buyers
                                </h3>
                                <h2 className='section-title theme-font-bold pb-40'>
                                    Tailored buying journeys for diverse buyer segments
                                </h2>
                                <h3 className="section-sub-header theme-font-normal">
                                    Profile Buyer Personas
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Buyers come from diverse backgrounds and have different needs
                                    and preferences. Prepare buyer personas that resonate with each segment.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Customized Buying Journeys
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2 ">
                                    Utilize SCuiDO to deliver highly effective and personalized
                                    buying experiences to different buyer segments.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                    Build a reputation about creating a positive and enjoyable buying experience!
                                </h4>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/realEstate/tailoredJourneyMobile.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'real-estate-selling-approach-mobile'}
                             className="section-padding-bottom">
                        <div>
                            <div className="block-container">
                                <h2 className='section-title theme-font-bold pb-40'>
                                    Differentiated approach for Selling Properties
                                </h2>
                                <h3 className="section-sub-header theme-font-normal">
                                    Personalized Matchmaking
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Define segments of buyers that match your ideal customer profile for a property to
                                    be sold.
                                    You can do this in few clicks.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Exclusive Invitations
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Send personalized listing presentations to potential buyers, inviting them
                                    exclusively to view
                                    your properties.
                                </h4>
                                <h3 className="section-sub-header theme-font-normal">
                                    Qualified Connections
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                    Help sellers connect only with a select group of qualified potential buyers, which
                                    is precisely
                                    what they desire!
                                </h4>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 col-12 px-0">
                                <img src={s3BaseURL + '/images/useCases/realEstate/differentiatedMobile.jpg'}
                                     className="img-fluid"
                                />
                            </div>
                        </div>
                    </section>

                    <section id={'real-estate-fire-sale-mobile'}
                             className="section-padding-bottom">
                        <div>
                            <div>
                                <div className="block-container">
                                    <h2 className='section-title theme-font-bold '>
                                        Make the most of <span className="px-1" style={{color: 'var(--tertiary)'}}> Fire Sale</span> or
                                        <span className="px-1" style={{color: 'var(--tertiary)'}}>Hot Property </span>
                                        deals
                                    </h2>
                                    <h4 className="sub-section-title theme-font-normal pb-40 pt-2">
                                        There are times when you need to move quickly to capture the most sought-after
                                        properties.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Instant Deal Alerts
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Send alerts for attractive discounts and highly sought-after properties,
                                        allowing
                                        buyers to act quickly.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Trusted Buyer Outreach
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Reach out to your trusted buyer base, enabling them to make swift, informed
                                        decisions. Make them feel privileged.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Matchless Competitive Advantage
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2"
                                        style={{paddingTop: 20}}>
                                        Envision the competitive advantage as your customers seize profitable
                                        opportunities.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/realEstate/makeTheMostMobile.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'real-estate-neighbourhood-centric-mobile'}>
                        <div className="section-padding-bottom">
                            <div>
                                <div className="block-container">
                                    <h2 className='section-title theme-font-bold '>
                                        Neighbourhood-Centric Buyer Engagement
                                    </h2>
                                    <h4 className="sub-section-title  theme-font-normal pt-2 pb-40">
                                        In the world of real estate, locality or neighbourhood holds significant sway
                                        for
                                        buyers.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Neighbourhood Segments
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Leverage SCuiDO to define segments of buyers interested in specific
                                        neighbourhoods.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Customized Communication
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Deliver tailored messages to neighbourhood-focused buyers, enhancing engagement.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Buyer Mind-Share Mastery
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pb-40 pt-2">
                                        Maintain unprecedented buyer engagement and secure a top spot in their buying
                                        decisions.
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/realEstate/neighbourhoodCentricMobile.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'real-estate-unsold-properties'}
                             className="section-padding-bottom">
                        <div>
                            <div>
                                <div className="block-container">
                                    <h3 className="section-header theme-font-normal">
                                        Unsold Properties
                                    </h3>
                                    <h2 className='section-title theme-text-black pt-2 theme-font-bold'>
                                        Don't lose sellers to your competition!
                                    </h2>
                                    <h4 className="sub-section-title  theme-font-normal pt-2 pb-40">
                                        In the competitive world of real estate, it is crucial to retain your sellers
                                        with unsold
                                        properties. Losing a seller to your competition not only affects your revenue
                                        but also
                                        tarnishes your reputation.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Competitive Insights
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Provide sellers with valuable data on property price comparisons and
                                        neighbourhood
                                        price trends, ensuring they stay informed.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Expert Advice
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Offer tips and guidance on home staging and decluttering techniques to help
                                        sellers
                                        make their properties more appealing to buyers.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Focused Seller Outreach
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Utilize SCuiDO to streamline and enhance your seller
                                        engagement, ensuring you retain clients in the competitive real estate market.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Make your clients feel valued and heard. Create a loyal client base that will
                                        return to you for
                                        all their real estate needs!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/realEstate/unsoldPropertiesMobile.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'real-estate-targeted-renter'}>
                        <div className="section-padding-bottom">
                            <div>
                                <div className="block-container">
                                    <h2 className='section-title theme-font-bold pb-40'>
                                        Targeted Renter Engagement
                                    </h2>

                                    <h3 className="section-sub-header theme-font-normal">
                                        Countless Customer Segments
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Create segments for various renter groups, including families, college students,
                                        pet
                                        owners, artists, young professionals, retirees, and more, tailoring your
                                        messaging
                                        to their unique needs.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Personalized Outreach
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Deliver targeted messages to each renter segment leveraging the power of SCuiDO.
                                    </h4>
                                    <h3 className="section-sub-header theme-font-normal">
                                        Location-Specific Appeal
                                    </h3>
                                    <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 pb-40">
                                        Enhance your outreach by adding location-specific segments, highlighting area
                                        features like amenities, walkability scores, shopping centers, restaurants, and
                                        more.
                                    </h4>
                                    <h4 className="section-footer-text theme-text-black theme-font-semiBold pb-40">
                                        Be the top pick for renters!
                                    </h4>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-md-6 col-12 px-0">
                                    <img src={s3BaseURL + '/images/useCases/realEstate/targetedRenterEngagementMobile.jpg'}
                                         className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' padding-section-vertical secondary-banner-bg'
                             id={"real-estate-secondary-footer-mobile"}>
                        <h4 className='theme-text-white mb-0 text-center block-container section-title theme-font-bold'
                            style={{paddingBottom: 60}}>
                            Want to take Your Customer Outreach to the Next Level?
                        </h4>
                        <div className='d-flex justify-content-center '>
                            <div>
                                <button onClick={() => redirectTo('/app/user/register')}
                                      className="footer-sign-up-button">
                                        Sign Up
                                </button>
                            </div>
                            <div style={{marginLeft: 20}}>
                                <Link to={{
                                    pathname: '/contact-us',
                                    state: 'FROM_FREEDEMO'}}>
                                    <button className="footer-free-demo-button">
                                            Get A Free Demo
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }

        return (
            <div>

                <section id={'real-estate-banner-desktop'} className="section-padding-bottom">
                    <div className={isProdEnvironment ? "real-estate-banner-bg-prod d-flex":"d-flex real-estate-banner-bg"}>
                        <div className="d-flex align-items-center ">
                            <div className="block-container " style={{width: "65%"}}>
                                <h1 className='page-title theme-font-semiBold '>
                                    Customer Outreach for <span className="theme-font-bolder"
                                                                style={{color: 'var(--tertiary)'}}>  Real Estate Agencies </span>
                                </h1>
                                <h3 className="py-5 section-header theme-font-normal">
                                    We're a tech company partnering with startups and businesses to turn
                                    forward-thinking ideas into hyper-growth solutions
                                </h3>
                                <a href={"/contact-us"}>
                                    <button className="sign-up-button">
                                        Contact Us
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'real-estate-attract-more-buyers-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/attractMoreBuyers.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h3 className="section-header pb-2">
                                    Attract More Buyers
                                </h3>
                                <h2 className='section-title theme-text-black  paragraph-padding'>
                                    Tailored buying journeys for diverse buyer segments
                                </h2>
                                <h3 className="section-sub-header">
                                    Profile Buyer Personas
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Buyers come from diverse backgrounds and have different needs
                                    and preferences. Prepare buyer personas that resonate with each segment.
                                </h4>
                                <h3 className="section-sub-header">
                                    Customized Buying Journeys
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Utilize SCuiDO to deliver highly effective and personalized
                                    buying experiences to different buyer segments.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Build a reputation about creating a positive and enjoyable buying experience!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'real-estate-selling-properties'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  paragraph-padding'>
                                    Differentiated approach for Selling Properties
                                </h2>
                                <h3 className="section-sub-header">
                                    Personalized Matchmaking
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Define segments of buyers that match your ideal customer profile for a property to
                                    be sold.
                                    You can do this in few clicks.
                                </h4>
                                <h3 className="section-sub-header">
                                    Exclusive Invitations
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Send personalized listing presentations to potential buyers, inviting them
                                    exclusively to view
                                    your properties.
                                </h4>
                                <h3 className="section-sub-header">
                                    Qualified Connections
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2">
                                    Help sellers connect only with a select group of qualified potential buyers, which
                                    is precisely
                                    what they desire!
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/differentiatedApproach.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'real-estate-fire-sale-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/makeTheMost.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className=' section-title '>
                                    Make the most of <span className="px-1" style={{color: 'var(--tertiary)'}}> Fire Sale</span> or
                                    <span className="px-1" style={{color: 'var(--tertiary)'}}>Hot Property </span>
                                    deals
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal pt-2">
                                    There are times when you need to move quickly to capture the most sought-after
                                    properties.
                                </h4>
                                <h3 className="section-sub-header">
                                    Instant Deal Alerts
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Send alerts for attractive discounts and highly sought-after properties, allowing
                                    buyers to act quickly.
                                </h4>
                                <h3 className="section-sub-header">
                                    Trusted Buyer Outreach
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Reach out to your trusted buyer base, enabling them to make swift, informed
                                    decisions. Make them feel privileged.
                                </h4>
                                <h3 className="section-sub-header">
                                    Matchless Competitive Advantage
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2">
                                    Envision the competitive advantage as your customers seize profitable opportunities.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'real-estate-neighbourhood-centric-desktop'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black pb-2 '>
                                    Neighbourhood-Centric Buyer Engagement
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    In the world of real estate, locality or neighbourhood holds significant sway for
                                    buyers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Neighbourhood Segments
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Leverage SCuiDO to define segments of buyers interested in specific neighbourhoods.
                                </h4>
                                <h3 className="section-sub-header">
                                    Customized Communication
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2 paragraph-padding">
                                    Deliver tailored messages to neighbourhood-focused buyers, enhancing engagement.
                                </h4>
                                <h3 className="section-sub-header">
                                    Buyer Mind-Share Mastery
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal pt-2">
                                    Maintain unprecedented buyer engagement and secure a top spot in their buying
                                    decisions.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/neighbourhoodCentric.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section id={'real-estate-unsold-properties-desktop'}
                         className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/unsoldProperties.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h3 className="section-header pb-2 ">
                                    Unsold Properties
                                </h3>
                                <h2 className='section-title theme-text-black pb-2'>
                                    Don't lose sellers to your competition!
                                </h2>
                                <h4 className="sub-section-title paragraph-padding theme-font-normal">
                                    In the competitive world of real estate, it is crucial to retain your sellers with
                                    unsold
                                    properties. Losing a seller to your competition not only affects your revenue but
                                    also
                                    tarnishes your reputation.
                                </h4>
                                <h3 className="section-sub-header">
                                    Competitive Insights
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Provide sellers with valuable data on property price comparisons and neighbourhood
                                    price trends, ensuring they stay informed.
                                </h4>
                                <h3 className="section-sub-header">
                                    Expert Advice
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Offer tips and guidance on home staging and decluttering techniques to help sellers
                                    make their properties more appealing to buyers.
                                </h4>
                                <h3 className="section-sub-header">
                                    Focused Seller Outreach
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Utilize SCuiDO to streamline and enhance your seller
                                    engagement, ensuring you retain clients in the competitive real estate market.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold ">
                                    Make your clients feel valued and heard. Create a loyal client base that will return
                                    to you for
                                    all their real estate needs!
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'real-estate-targeted-renter'} className="section-padding-bottom">
                    <div className='d-flex'>
                        <div className="d-flex align-items-center"
                             style={{flex: 1}}>
                            <div className="block-container">
                                <h2 className='section-title theme-text-black  paragraph-padding'>
                                    Targeted Renter Engagement
                                </h2>

                                <h3 className="section-sub-header">
                                    Countless Customer Segments
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Create segments for various renter groups, including families, college students, pet
                                    owners, artists, young professionals, retirees, and more, tailoring your messaging
                                    to their unique needs.
                                </h4>
                                <h3 className="section-sub-header">
                                    Personalized Outreach
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Deliver targeted messages to each renter segment leveraging the power of SCuiDO.
                                </h4>
                                <h3 className="section-sub-header">
                                    Location-Specific Appeal
                                </h3>
                                <h4 className="theme-font-content theme-text-black theme-font-normal paragraph-padding pt-2">
                                    Enhance your outreach by adding location-specific segments, highlighting area
                                    features like amenities, walkability scores, shopping centers, restaurants, and
                                    more.
                                </h4>
                                <h4 className="section-footer-text theme-text-black theme-font-semiBold">
                                    Be the top pick for renters !
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{flex: 1}}>
                            <img src={s3BaseURL + '/images/useCases/realEstate/targetedRenterEngagement.jpg'}
                                 className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className='block-container padding-section-vertical secondary-banner-bg'
                         id={"real-estate-secondary-footer-desktop"}>
                    <h4 className='section-title theme-text-white mb-0 text-center'>
                        Want to take Your Customer Outreach to the Next Level?
                    </h4>
                    <div className='d-flex justify-content-center padding-block-top'>
                        <button onClick={() => redirectTo('/app/user/register')}
                                className="footer-sign-up-button">
                            Sign Up
                        </button>
                        <div style={{marginLeft: 20}}>
                            <button className="footer-free-demo-button"
                                    onClick={() => redirectTo('/contact-us')}>
                                Get A Free Demo
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(RealEstate));
