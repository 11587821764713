import React, {useState} from 'react';
import {isEmpty} from "../../../utils/validations";
import {isValidEmail, redirectTo} from "../../../utils/helper";
import {subscribeNewsLetter} from "../actions";
import {showToastMessage} from "../../../core/actions/appActions";
import {withTranslation} from "react-i18next";
import store from "../../../store";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import NewsLetter from "../../../user/newsLetter/newsLetter";
import appTheme from "../../../assets/appTheme";
import Logo from "../../../assets/img/SCuiDOLogo.jpg"

function Footer(props) {
    const [homePageState, setHomepageState] = useState({
        emailId: '',
        emailIdError: ''
    });

    const handleChange = (name, value) => {
        setHomepageState((previewState) => ({
            ...previewState,
            [name]: value,
            [name + 'Error']: ""
        }))
    };

    const validateChanges = (field) => {
        const {emailId} = homePageState;
        const {t} = props;
        if (field === 'emailId') {
            if (isEmpty(emailId)) {
                setHomepageState((previewState) => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_EMAIL')
                }));

                return false
            }

            if (emailId.length > 50) {
                setHomepageState(previewState => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_LIMIT_50')

                }));
                return false;
            }

            if (!isValidEmail(emailId, true)) {
                setHomepageState(previewState => ({
                    ...previewState,
                    [field + 'Error']: t('LABEL_ERROR_EMAIL_VALIDATION')

                }));
                return false;
            }
        }
        return true
    };

    const onSubmit = () => {
        const {emailId} = homePageState;

        if (!validateChanges('emailId')) {
            return
        }

        let data = {
            emailId: emailId
        };

        subscribeNewsLetter(data).then((res) => {
            if (res.success) {
                store.dispatch(showToastMessage('success', 'Thank you for subscribing. You will hear from us shortly.'));
                setHomepageState(previewState => ({
                    ...previewState,
                    emailId: ''
                }))
            }
        })

    };

    const onBlurInputText = () => {
        validateChanges('emailId')
    };

    return (
        <div>
            <div className="d-lg-block d-none">
                <section id={'homepage-footer-desktop'}>
                    <div className="block-container">
                        <div className="cursor pb-3" style={{marginTop: "35px"}}>
                            <img src={Logo}
                                 style={{height: '35px', marginLeft: -6}}
                                 onClick={() => redirectTo('/')}
                                 alt={''}/>
                        </div>

                        <div className="row">
                            <div className="col-lg-10 col-12">
                                <div className=" theme-font-normal theme-font-content-md">
                                    A next-gen SaaS platform for end-to-end campaign management!
                                </div>
                                <div className="d-flex align-items-center justify-content-between w-100 h-100">
                                    <div className="d-flex"
                                         style={{flex: 4, gap: "40px"}}>
                                        <div>
                                            <i className="fa-brands fa-linkedin-in theme-text-primary"
                                               style={{fontSize: 24}}/>
                                        </div>
                                        <div>
                                            <i className="fa-brands fa-youtube theme-text-primary"
                                               style={{fontSize: 24}}/>
                                        </div>
                                    </div>
                                    <div className="pe-5 w-100" style={{flex: 8}}>
                                        <NewsLetter {...props}
                                                    homePageState={homePageState}
                                                    onBlurInputText={onBlurInputText}
                                                    handleChange={handleChange}
                                                    onSubmit={onSubmit}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-12">
                                <div className="d-flex flex-column justify-content-end">
                                    <div className=" cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={"/about-us"}>
                                            About Us
                                        </Link>
                                    </div>
                                    <div className="cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/contact-us'}>
                                            Contact Us
                                        </Link>
                                    </div>
                                    <div className="cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/legal/tnc'}>
                                            Terms & Conditions
                                        </Link>
                                    </div>
                                    <div className="cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/legal/acceptable-use-policy'}>
                                            Acceptable Use Policy
                                        </Link>
                                    </div>
                                    <div className="cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/legal/privacy-policy'}>
                                            Privacy Policy
                                        </Link>
                                    </div>
                                    <div className="cursor" style={{marginBottom: 10}}>
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/legal/refund-policy'}>
                                            Refund Policy
                                        </Link>
                                    </div>
                                    <div className="cursor">
                                        <Link className='theme-font-normal theme-font-content-sm'
                                              style={{color: appTheme.linkStyle.color}}
                                              to={'/legal/cookie-policy'}>
                                            Cookie Policy
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-border-light"/>
                        <div className="d-flex justify-content-center pb-3 theme-font-content">
                            <div>© Copyright 2023 Deccanlogic</div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="d-lg-none d-block">
                <section id={'homepage-footer-mobile'}>
                    <div className='px-3'>
                        <div className="mt-4 mb-3 cursor">
                            <img src={Logo}
                                 style={{height: '35px', marginLeft: -6}}
                                 onClick={() => redirectTo('/')}
                                 alt={''}/>
                        </div>
                        <div className=" theme-font-normal section-footer-text mb-3 "
                             style={{color: 'var(--gray)'}}>
                            A next-gen SaaS platform for end-to-end campaign management!
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/about-us'}>
                                        About Us
                                    </Link>
                                </div>
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/contact-us'}>
                                        Contact Us
                                    </Link>
                                </div>
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/legal/tnc'}>
                                        Terms & Conditions
                                    </Link>
                                </div>
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/legal/acceptable-use-policy'}>
                                        Acceptable Use Policy
                                    </Link>
                                </div>
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/legal/privacy-policy'}>
                                        Privacy Policy
                                    </Link>
                                </div>
                                <div className="cursor pb-2">
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/legal/refund-policy'}>
                                        Refund Policy
                                    </Link>
                                </div>
                                <div className='theme-font-content'>
                                    <Link className='theme-font-content'
                                          style={{color: appTheme.linkStyle.color}}
                                          to={'/legal/cookie-policy'}>
                                        Cookie Policy
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-center">
                                    <NewsLetter {...props}
                                                homePageState={homePageState}
                                                onBlurInputText={onBlurInputText}
                                                handleChange={handleChange}
                                                onSubmit={onSubmit}/>
                                </div>
                            </div>
                            <div className=" col-12">
                                <div className="d-flex justify-content-center px-3 align-items-center">
                                    <div>
                                        <i className="fa-brands fa-linkedin-in theme-text-primary"
                                           style={{fontSize: 20, paddingRight: "22px"}}/>
                                    </div>
                                    <div>
                                        <i className="fa-brands fa-youtube theme-text-primary"
                                           style={{fontSize: 20, paddingRight: "22px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-border-light"
                             style={{marginTop: 24}}/>
                        <div className="d-flex justify-content-center theme-font-content"
                             style={{marginBottom: "18px", paddingTop: 2}}>
                            <div>© Copyright 2023 Deccanlogic</div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default connect()(withTranslation('translations')(Footer));
