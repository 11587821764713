import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import "../../assets/css/appTheme.css";
import "../../assets/css/contentApp.css"

function PrivacyPolicy(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <div className="w-100">
                <div className="page-container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-12 p-3">
                            <div className="panel p-3">
                                <div className="text-center mt-2 mb-4">
                                    <h1 className="theme-font-page-title py-4">Privacy Policy</h1>
                                </div>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Parent company DeccanLogic Private Limited is the sole proprietor of website
                                    <a className="px-1"
                                       target="_blank"
                                       href="https://scuido.com"> https://scuido.com</a> (“SCuiDO”), holding all rights,
                                    title, and interest in the
                                    platform. By accessing or using the website
                                    <a className="px-1"
                                       target="_blank"
                                       href="https://scuido.com">https://scuido.com</a> , users acknowledge
                                    and agree that it is owned and operated by parent company DeccanLogic Private
                                    Limited. Any references to "we," "our," “us” or similar terms in the terms and
                                    conditions pertain to parent company DeccanLogic Private Limited
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    This Privacy Policy ("Policy") explains how DeccanLogic Private Limited collects,
                                    uses, discloses, and protects personal and non-personal information in connection
                                    with the SCuiDO provided as a Software-as-a-Service (SAAS) platform. This Policy
                                    applies to users ("you" or "your") of the SCuiDO, whether you are a customer,
                                    client, or end-user. By accessing or using SCuiDO, you consent to the collection and
                                    processing of your information as described in this Policy.
                                </h4>
                                <div className="d-flex justify-content-center">
                                    <h2 className="theme-font-section-title pt-5 pb-3">Information We Collect</h2>
                                </div>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Personal Information: We may collect personal information that identifies
                                    you or
                                    can be reasonably linked to you, including but not limited to your name, email
                                    address, contact information, and payment details.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Usage and Log Data: We may collect information about your use of the
                                    SCuiDO, such
                                    as your IP address, browser type, operating system, pages viewed, links clicked, and
                                    the dates and times of your interactions with the SCuiDO. We may also collect log
                                    data and usage statistics to analyze and improve the performance and functionality
                                    of the SCuiDO.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Cookies and Similar Technologies: We may use cookies, pixel tags, and
                                    similar
                                    technologies to collect information about your interactions with the SCuiDO. These
                                    technologies help us personalize your experience, analyze usage patterns, and
                                    deliver targeted advertisements.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Uploaded Content: SCuiDO allows you to upload and store content, including
                                    documents and translations. We may collect and process this content solely for the
                                    purpose of providing the SCuiDO services to you. We will not access, use, or
                                    disclose your content except as necessary to provide and improve the SCuiDO or as
                                    required by law.
                                </h4>

                                <h3 className="pt-4 pb-2 theme-font-content-lg theme-text-justify">
                                    How We Use Your Information
                                </h3>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Providing and Improving SCuiDO: We use the collected information to provide
                                    and maintain SCuiDO, personalize your experience, and improve the functionality,
                                    features, and performance of SCuiDO.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Communication: We may use your contact information to communicate with
                                    you, respond to your inquiries, provide customer support, and send you
                                    administrative or promotional messages related to SCuiDO. You can opt-out of
                                    receiving promotional messages by following the instructions included in the
                                    communication.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Analytics and Research: We may use aggregated and anonymized information
                                    for analytical purposes, such as analyzing usage patterns, identifying trends, and
                                    conducting research to enhance SCuiDO.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Legal Compliance: We may use your information to comply with applicable
                                    laws, regulations, legal processes, or enforceable governmental requests, and to
                                    protect our rights, privacy, safety, or property, as well as that of our users and
                                    the public.
                                </h4>


                                <h3 className="theme-font-content-lg pt-4 pb-2">
                                    Data Sharing and Disclosure
                                </h3>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Service Providers: We may engage third-party service providers to perform
                                    functions and provide services on our behalf, such as hosting, data storage,
                                    analytics, customer support, and payment processing. These service providers may
                                    have access to your information only to perform these tasks on our behalf and are
                                    obligated to maintain the confidentiality and security of your information.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) To fulfil the purpose for which you provide it. For example, when you run an
                                    outreach campaign to your customers, we shall transmit the contents of your message
                                    to the recipient.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Compliance with Law: We may disclose your information if required to do so by law
                                    or in response to valid requests from public authorities, such as government
                                    agencies or law enforcement.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Business Transfers: In the event of a merger, acquisition, or sale of all or a
                                    portion of our assets, your information may be transferred or disclosed as part of
                                    the transaction. We will notify you before your information is transferred or
                                    becomes subject to a different privacy policy.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    e) Aggregated and Anonymized Information: We may share aggregated and anonymized
                                    information with third parties for research, analytics, or other purposes, provided
                                    that the information does not identify you personally.
                                </h4>


                                <h3 className="theme-font-content-lg pt-4 pb-2">
                                    Google Analytics
                                </h3>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    This Website utilizes Google Analytics, a web analytics service provided by Google,
                                    Inc. ("Google"). Google Analytics uses cookies, which are small text files placed on
                                    your computer, to help analyze how users interact with the Website. The information
                                    generated by the cookie about your use of the Website, including your IP address,
                                    will be transmitted to and stored by Google on servers located in the United States.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Google will use this information on our behalf as the operator of this Website to
                                    evaluate your use of the Website, compile reports on Website activity, and provide
                                    other services related to Website activity and internet usage.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    <div className="d-md-block d-none">
                                        Refer to
                                        <a className="px-1"
                                           target="_blank"
                                           href="https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008">
                                            https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008
                                        </a>
                                        Data Privacy and Security of Google Analytics.
                                    </div>
                                    <div className="d-md-none d-block">
                                        Refer to
                                        <a className="px-1 text-break"
                                           target="_blank"
                                           href="https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008">
                                            https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008
                                        </a>

                                        Data Privacy and Security of Google Analytics.
                                    </div>
                                </h4>

                                <div className="d-flex justify-content-center">
                                    <h2 className="theme-font-section-title pt-5 pb-3">
                                        GOOGLE CLOUD API
                                    </h2>
                                </div>
                                <h3 className="theme-font-content-lg pt-4 pb-2">
                                    Use of Google Cloud APIs
                                </h3>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    SCuiDO integrates various Google Cloud APIs to enhance functionality and provide
                                    seamless user experiences. This section outlines how we use these APIs and manage
                                    the data collected through them.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Google Login
                                    <br/>
                                    <div className="py-2">
                                        We use Google Login to authenticate users and provide secure access to our
                                        product.
                                        When you use Google Login, we collect and store the following information:
                                    </div>
                                    <ul>
                                        <li> Your Google account information, including your name, email address, and
                                            profile picture.
                                        </li>
                                        <li> Authentication tokens to maintain your session and verify your identity.
                                        </li>
                                    </ul>
                                    <div className="py-2">
                                        We use this information solely for the purpose of authenticating your identity,
                                        personalizing your experience, and improving the security of our product. We do
                                        not
                                        share this information with any third parties except as described in this
                                        Privacy
                                        Policy.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Google Vision API
                                    <br/>
                                    <div className="py-2">
                                        SCuiDO utilizes the Google Vision API to analyze and process images. This API
                                        allows
                                        us to provide features such as image recognition, text extraction, and content
                                        moderation. When you upload images for analysis, the following information may
                                        be
                                        collected:
                                    </div>
                                    <ul>
                                        <li>Image data (the images you upload)</li>
                                        <li> Metadata associated with the images (e.g., timestamps, device
                                            information)
                                        </li>
                                    </ul>
                                    <div className="py-2">
                                        We use the image data and metadata to provide the requested services and improve
                                        our
                                        image processing capabilities. The images and related data are sent to Google’s
                                        servers for analysis, and Google may process and store this data in accordance
                                        with
                                        their privacy policies. We do not use the image data for any other purposes and
                                        do
                                        not share it with third parties except as described in this Privacy Policy.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Data Security and Retention
                                    <br/>
                                    <div className="py-2">
                                        We implement stringent security measures to protect the data processed through
                                        Google Cloud APIs. This includes encryption of data in transit and at rest,
                                        access
                                        controls, and regular security audits. We retain the data collected through
                                        these
                                        APIs only for as long as necessary to fulfill the purposes outlined in this
                                        Privacy
                                        Policy, after which it is securely deleted.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Compliance with Google’s Policies
                                    <br/>
                                    <div className="py-2">
                                        We comply with Google’s API Services User Data Policy -
                                        <a className="px-1"
                                           target="_blank"
                                           href={"https://developers.google.com/terms/api-services-user-data-policy"}>
                                            https://developers.google.com/terms/api-services-user-data-policy
                                        </a> , which governs
                                        the use and handling of data collected through Google APIs. For more information
                                        on
                                        how Google collects and processes data, please refer to Google’s Privacy Policy:
                                        Google Privacy Policy.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    e) User Consent
                                    <br/>
                                    <div className="py-2">
                                        By using our product and integrating it with your Google account, you consent to
                                        the
                                        collection, use, and processing of your data as described in this section. If
                                        you do
                                        not agree with this Privacy Policy, please discontinue the use of our product.
                                    </div>
                                </h4>

                                <div className="d-flex justify-content-center">
                                    <h2 className="theme-font-section-title pt-5 pb-3">Amazon AWS Services</h2>
                                </div>
                                <h4 className="py-2 theme-font-content theme-text-justify">
                                    Our software product leverages various Amazon Web Services
                                    (AWS) to provide robust, scalable, and secure solutions. This section outlines how
                                    we use these services and manage the data collected through them.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Amazon EC2<br/>
                                    <div className="py-2">
                                        We use Amazon Elastic Compute Cloud (EC2) to host our application and manage
                                        compute
                                        resources. The information processed on EC2 instances includes:
                                    </div>
                                    <ul>
                                    <li> User data necessary for application functionality (e.g., user-generated
                                        content,
                                        session data)
                                    </li>
                                    <li> System logs and performance metrics for monitoring and optimizing application
                                        performance
                                    </li>
                                    </ul>
                                    <div className="py-2">
                                        Data processed on EC2 instances is stored and transmitted securely, and access
                                        is
                                        restricted to authorized personnel only.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Amazon S3<br/>
                                    <div className="py-2">
                                        Amazon Simple Storage Service (S3) is used to store and retrieve large amounts
                                        of
                                        data, including:
                                    </div>
                                    <ul>
                                    <li> User-uploaded files and media (e.g., images, videos, documents)</li>
                                    <li>Backups and archival data for disaster recovery</li>
                                    </ul>
                                    <div className="py-2">
                                        Data stored in S3 is encrypted both in transit and at rest to ensure its
                                        security
                                        and integrity.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Amazon SES<br/>
                                    <div className="py-2">
                                        Amazon Simple Email Service (SES) is used to send emails to users, such as:
                                    </div>
                                    <ul>
                                    <li> Outbound messages to your customers when you run email campaigns</li>
                                    <li>Account verification emails</li>
                                    <li>Password reset instructions</li>
                                    <li>Notifications and updates about our product</li>
                                    </ul>
                                    <div className="py-2">
                                        We ensure that email addresses and other related data used by SES are handled
                                        securely and in compliance with applicable regulations.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Amazon SNS<br/>
                                    <div className="py-2">
                                        Amazon Simple Notification Service (SNS) is utilized to send notifications to
                                        users
                                        and other systems, including:
                                    </div>
                                    <ul>
                                    <li> Push notifications to user devices</li>
                                    <li>Alerts and updates through various communication channels</li>
                                    </ul>
                                    <div className="py-2">
                                        Data used in SNS is managed securely and is only accessible to authorized
                                        systems
                                        and personnel.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    e) Amazon Route 53<br/>
                                    <div className="py-2">
                                        Amazon Route 53 is employed to manage our domain name system (DNS) and ensure
                                        reliable routing of user traffic. This service handles:
                                    </div>
                                    <ul>
                                    <li> DNS queries for our domain</li>
                                    <li>Traffic routing policies to optimize performance and availability</li>
                                    </ul>
                                    <div className="py-2">
                                        Data associated with DNS queries is managed securely to protect against
                                        unauthorized
                                        access and manipulation.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    f) Data Security and Retention<br/>
                                    <div className="py-2">
                                        Amazon Route 53 is employed to manage our domain name system (DNS) and ensure
                                        reliable routing of user traffic. This service handles:
                                        We implement stringent security measures to protect data processed through AWS
                                        services, including encryption, access controls, and regular security audits.
                                        Data
                                        is retained only as long as necessary to fulfill the purposes outlined in this
                                        Privacy Policy and is securely deleted thereafter.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    g) Compliance with AWS Policies<br/>
                                    <div className="py-2">
                                        We adhere to AWS’s Acceptable Use Policy and ensure our use of AWS services
                                        complies
                                        with their terms and conditions. For more information on how AWS handles data,
                                        please refer to the AWS Privacy Notice: AWS Privacy Notice.
                                    </div>
                                </h4>

                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    h) User Consent<br/>
                                    <div className="py-2">
                                        By using our product, you consent to the collection, use, and processing of your
                                        data as described in this section. If you do not agree with this Privacy Policy,
                                        please discontinue the use of our product.
                                    </div>
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    OpenAI API SERVICES
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Our software product integrates OpenAI services to provide advanced AI capabilities
                                    and enhance user experiences. This section outlines how we use these services and
                                    manage the data processed through them.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) OpenAI API<br/>
                                    <div className="py-2">
                                        We use the OpenAI API to enable features such as natural language processing,
                                        text
                                        generation, and other AI-driven functionalities. When you interact with these
                                        features, the following information may be collected and processed:
                                    </div>
                                    <ul>
                                    <li> Text input provided by users (e.g., queries, commands, and other text
                                        data)
                                    </li>
                                    <li>Generated responses and outputs from the OpenAI models</li>
                                    </ul>
                                    <div className="py-2">
                                        We use this information to provide relevant and accurate AI-driven responses and
                                        improve the performance and capabilities of our product.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Data Security and Retention<br/>
                                    <div className="py-2">
                                        We implement stringent security measures to protect the data processed through
                                        OpenAI services. This includes encryption of data in transit, secure storage
                                        practices, and access controls. Data processed by OpenAI services is retained
                                        only
                                        as long as necessary to fulfill the purposes outlined in this Privacy Policy,
                                        after
                                        which it is securely deleted.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Compliance with OpenAI’s Policies<br/>
                                    <div className="py-2">
                                        We comply with OpenAI's use case policy and ensure our use of OpenAI services
                                        aligns
                                        with their terms and conditions. For more information on how OpenAI handles
                                        data,
                                        please refer to OpenAI’s Privacy Policy: OpenAI Privacy Policy.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) User Consent<br/>
                                    <div className="py-2">
                                        By using our product and interacting with the AI features powered by OpenAI, you
                                        consent to the collection, use, and processing of your data as described in this
                                        section. If you do not agree with this Privacy Policy, please discontinue use of
                                        our
                                        product.
                                    </div>
                                </h4>


                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Social Media
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Our website may incorporate website elements from social networks such as Facebook,
                                    Twitter, LinkedIn, and YouTube. These elements, including buttons (referred to as
                                    "social plugins") and integrated content, are provided by Facebook Inc., Twitter
                                    Inc., LinkedIn Corporation, and YouTube LLC ("Providers").
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Facebook is operated by FACEBOOK INDIA ONLINE SERVICES PRIVATE LIMITED ("Facebook").
                                    For an overview of Facebook web page elements and their appearance, please visit
                                    <a className="px-1 text-break"
                                       target="_blank"
                                       href="https://developers.facebook.com/docs/plugins"> https://developers.facebook.com/docs/plugins</a> .
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    LinkedIn is operated by LinkedIn Corporation, located at 2029 Stierlin Court,
                                    Mountain View, CA 94043, USA. LinkedIn website elements are identifiable by the
                                    LinkedIn logo.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    YouTube is operated by YouTube, LLC, located at 901 Cherry Ave., San Bruno, CA
                                    94066, USA, which is a subsidiary of Google Inc., located at Amphitheatre Parkway,
                                    Mountain View, CA 94043, USA. YouTube website elements are recognizable by the
                                    YouTube logo.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    When you visit a page on our website that contains such website elements, your
                                    browser establishes a direct connection to the respective Provider's server. The
                                    content of the website element is transmitted directly from the Provider to your
                                    browser and integrated into our website. By incorporating these website elements,
                                    the Providers gain information that your browser has accessed the corresponding page
                                    of our website, even if you do not have a profile with the respective Provider or
                                    are not logged in at the time. This information, including your IP address, is
                                    transmitted from your browser directly to a server of the respective Provider in the
                                    United States and stored there.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    If you are logged into any of these services, the respective Provider can directly
                                    associate your visit to our website with your profile on their platform.</h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    We have no control over the data that the Providers collect through the website
                                    elements or the parameters they use. For details on the purpose and extent of data
                                    collection, as well as the processing and use of the data by the Providers, and your
                                    related rights and privacy settings, please refer to the privacy policy of each
                                    respective Provider:</h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Facebook:<a className="px-1 text-break"
                                                target="_blank"
                                                href="https://www.facebook.com/about/privacy/">
                                    https://www.facebook.com/about/privacy/</a>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    LinkedIn:<a className="px-1 text-break"
                                                target="_blank"
                                                href="http://www.linkedin.com/legal/privacy-policy">
                                    http://www.linkedin.com/legal/privacy-policy</a>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    YouTube:
                                    <a className="px-1 text-break"
                                       target="_blank"
                                       href="https://policies.google.com/privacy?hl=en">
                                        https://policies.google.com/privacy?hl=en</a>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    To prevent the Providers from directly associating the data collected through our
                                    website with your profile on their platform, you will need to log out of their
                                    services. Additionally, you can use browser add-ons to block the loading of these
                                    website elements entirely.
                                </h4>


                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Data Security
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    At DeccanLogic Private Limited, the security of our customers' data is our top
                                    priority. We place utmost importance on safeguarding your data and ensuring its
                                    privacy. Any data stored within SCuiDO belongs solely to you, and we are dedicated
                                    to preventing unauthorized access to your data.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Our website is hosted on world class AWS infrastructure. We take advantage of
                                    Amazon’s expertise and knowhow to ensure physical security of the data.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Visit
                                    <a className="ps-1 text-break"
                                       target="_blank"
                                       href="https://aws.amazon.com/compliance/data-center/controls/">
                                        https://aws.amazon.com/compliance/data-center/controls/ for more information.
                                    </a>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Regular backups are scheduled and stored and encrypted at rest
                                </h4>
                                <h5 className=" theme-font-content-lg theme-text-justify pt-4 pb-2">
                                    Software Security and monitoring
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    While we dedicate significant efforts to developing secure software, we recognize
                                    that no system can be entirely impervious to vulnerabilities.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    To bolster our security measures, we employ a range of automated software solutions
                                    to continuously scan and identify potential security issues and vulnerabilities in
                                    both our code and back-end systems. If any issues are detected, we promptly apply
                                    appropriate fixes to address them.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    We use standard observatory and monitoring tools offered by AWS to monitor and
                                    ensure application running smoothly and safely.
                                </h4>
                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Access to Data
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    At our organization, we adhere to the principle of least privilege, which means that
                                    we grant our employees only the necessary permissions to access production systems
                                    and data. We carefully evaluate and provide minimal privileges required for their
                                    specific roles and responsibilities. Additionally, we maintain a clear separation
                                    between our development/staging environments and the production environment to
                                    ensure enhanced security and minimize potential risks.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Please contact <a className="px-1" href={"mailto:"}>support@scuido.com</a> for more
                                    information.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Payment
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Our software product uses Razorpay to handle payment transactions securely and
                                    efficiently. This section outlines how we use Razorpay and manage the data processed
                                    through this service.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    a) Payment Information <br/>
                                    <div className="py-2">
                                        When you make a purchase or conduct a transaction through our product, Razorpay
                                        may
                                        collect and process the following payment-related information:
                                    </div>
                                    <ul>
                                    <li> Payment card details (e.g., card number, expiration date, CVV)</li>
                                    <li> Billing information (e.g., name, billing address, email address)</li>
                                    <li>Transaction details (e.g., amount, date, transaction ID)</li>
                                    </ul>
                                    <div className="py-2">
                                        We do not store your payment card details on our servers. Razorpay handles all
                                        payment card information securely and complies with applicable data protection
                                        regulations.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    b) Data Security and Compliance <br/>
                                    <div className="py-2">
                                        Razorpay implements stringent security measures to protect your payment
                                        information,
                                        including encryption and compliance with the Payment Card Industry Data Security
                                        Standard (PCI-DSS). We ensure that all transactions are processed securely and
                                        that
                                        your payment information is protected from unauthorized access or disclosure.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    c) Use of Payment Information <br/>
                                    <div className="py-2">
                                        The payment information collected by Razorpay is used solely for the purpose of
                                        processing your transactions, issuing refunds, and addressing payment-related
                                        inquiries. We do not share your payment information with any third parties
                                        except as
                                        necessary to process your payments and comply with legal requirements.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    d) Razorpay Privacy Policy <br/>
                                    <div className="py-2">
                                        We adhere to Razorpay’s policies and ensure our use of their services complies
                                        with
                                        their terms and conditions. For more information on how Razorpay handles data,
                                        please refer to Razorpay’s Privacy Policy: Razorpay Privacy Policy.
                                    </div>
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    e) User Consent <br/>
                                    <div className="py-2">
                                        By making a payment through our product, you consent to the collection, use, and
                                        processing of your payment information as described in this section. If you do
                                        not
                                        agree with this Privacy Policy, please do not proceed with any payment
                                        transactions
                                        through our product.
                                    </div>
                                </h4>


                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Third Party Websites
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Our website may include links or references to external websites that are beyond our
                                    control. Please note that this privacy policy applies solely to our website and
                                    services. We recommend that you review the privacy statements and terms and
                                    conditions of any linked or referenced websites you visit. These third-party
                                    websites may have their own privacy policies, use cookies and other tracking
                                    technologies, collect data, or request personal information. We do not have control
                                    over the actions of third parties regarding their websites or the handling of your
                                    personal data. Therefore, we advise you to exercise caution and refer to the privacy
                                    policies posted on each third-party website for more detailed information.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Data Retention
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    We retain your information for as long as necessary to fulfil the purposes outlined
                                    in this Policy, unless a longer retention period is required or permitted by law. We
                                    will securely delete or anonymize your information when it is no longer needed for
                                    the purposes specified herein.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Your Rights
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Access and Correction: You may access, correct, update, or delete your personal
                                    information by contacting us using the contact information provided at the end of
                                    this Policy. We may need to verify your identity before processing such requests.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Marketing Communications: You can opt-out of receiving marketing communications from
                                    us by following the instructions provided in the communication or by contacting us.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    Cookies and Tracking Technologies: You can manage your cookie preferences through
                                    your browser settings. Please note that blocking or disabling certain cookies may
                                    impact the functionality and performance of SCuiDO.
                                </h4>


                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    GDPR
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    We gather various types of information from users of our website, including data
                                    that may be classified as personal data according to the General Data Protection
                                    Regulation ("GDPR"). This information encompasses details such as your name, email
                                    address, phone number, country, job role, company/organization name, annual revenue,
                                    industry, current customer status, file uploads made by you, product interests, as
                                    well as information associated with inquiries and requests. Additionally, we may
                                    collect bank account information, including bank account and routing details.
                                    Furthermore, we may collect information about your internet connection and the
                                    devices you utilize to access our website. While navigating through the website,
                                    certain information, such as usage patterns, IP addresses, and data obtained using
                                    cookies, may be automatically collected.
                                </h4>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    According to GDPR, Data subjects (any information relating to an identified or
                                    identifiable natural person) have the right to access to data, right to
                                    rectification, right to erasure and the right not to be subject to individual
                                    decisions. Data subjects/You also have the right to restriction of personal data
                                    processing and to request the deletion of the collected personal data, as well as
                                    the right to data portability. To exercise these rights, you may send a written
                                    request, dated and signed, to ScuiDO at
                                    <a className="ps-1" href={"mailto:"}>support@scuido.com</a>
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Cross Border Personal Data Transfer:
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    The information we collect from you may be transferred to and stored in a location
                                    outside of the European Economic Area (EEA), including the United States. It may
                                    also be processed by our personnel or other data processors located outside the EEA
                                    who work for us or other entities acting on our behalf. These individuals may be
                                    involved in activities such as fulfilling your requests or orders and providing
                                    support services.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Children's Privacy
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    SCuiDO is not intended for use by individuals under the age of 18. We do not
                                    knowingly collect personal information from children. If you believe that we have
                                    inadvertently collected information from a child, please contact us, and we will
                                    promptly delete such information.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Changes to this Privacy Policy
                                </h5>
                                <h4 className="py-2 theme-font-content theme-text-justify theme-font-normal">
                                    We may update this Policy from time to time to reflect changes in our practices and
                                    the legal requirements. The most current version of the Policy will be posted on
                                    SCuiDO, and any significant changes will be communicated to you as required by
                                    applicable laws.
                                </h4>

                                <h5 className="theme-font-content-lg mb-0 pt-4 pb-2">
                                    Contact Us
                                </h5>
                                <h4 className="py-2 mb-3 theme-font-content theme-text-justify theme-font-normal">
                                    If you have any questions, concerns, or requests regarding this Privacy Policy or
                                    our data practices, please contact us at
                                    <a className="px-1" href={"mailto:"}>support@scuido.com</a>

                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"block-container"}>
                    <div className="footer-border-light"/>
                </div>
                <Footer {...props}/>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
